var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('backdrop',{attrs:{"clicked":_vm.closeCropModal}}),(_vm.isCropModalOpen)?_c('div',{staticClass:"crop_modal_page"},[_c('div',{staticClass:"mg-image-dialog__confirm-modal__buttons-container"},[_c('CloseModalButton',{attrs:{"click-handler":_vm.closeCropModal}}),(_vm.element.url)?_c('div',{staticClass:"content"},[_c('section',{staticClass:"cropper-area"},[_c('div',{staticClass:"img-cropper"},[_c('vue-cropper',{ref:"cropper",staticClass:"vue-cropper",attrs:{"src":_vm.element.url,"preview":".preview"}})],1)]),_vm._m(0)]):_vm._e()],1),_c('div',{staticClass:"crop_buttons"},[_c('ButtonGradientBackground',{attrs:{"text-content":"Apply","click-handler":_vm.applyCrop,"additional-basic-styles":{
          width: '250px',
          height: '40px',
          background: '#27CAFE',
          fontSize: '14px',
        }}}),_c('ButtonGradientOutline',{attrs:{"text-content":"Cancel","click-handler":_vm.closeCropModal,"gradient-outline-style":"thirdly","additional-basic-styles":{
          width: '255px',
          height: '48px',
        }}})],1)]):_c('div',{staticClass:"error"},[_c('p',[_vm._v("You should to choose an image")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"preview-area"},[_c('p',[_vm._v("Preview")]),_c('div',{staticClass:"preview"})])}]

export { render, staticRenderFns }