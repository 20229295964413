/* eslint-disable object-curly-newline */
import Vue from 'vue';
import stateMerge from 'vue-object-merge';
import _merge from 'lodash/merge';
import _set from 'lodash/set';
import _cloneDeep from 'lodash/cloneDeep';
import TestImage from '../../../assets/images/tree.jpg';
import { ELEMENT_TYPES } from '../../../common/constants';
import { moveElementInArray } from '../../../common/utils';
import { rgba } from '../../../common/colors';

const transformOptions = {
  position: {
    x: null,
    y: null,
  },
  size: {
    width: null,
    height: null,
  },
  rotation: null,
  scaleX: null,
  scaleY: null,
};

const mainImage = {
  id: 'mainImage',
  type: ELEMENT_TYPES.IMAGE,
  main: true,
  visible: true,
  url: TestImage,
  opacity: 1,
  ..._cloneDeep(transformOptions),
};

const defaultText = {
  type: ELEMENT_TYPES.TEXT,
  rotation: 0,
  text: 'This is some example text for a meme',
  font: {
    size: 28,
    family: 'OpenSans-Regular',
    color: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },

    spacing: {
      line: 1,
      letter: 0,
    },
    alignment: 'left',
    caps: 'none',
    outline: {
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      thickness: 0,
    },
  },
  ..._cloneDeep(transformOptions),
};

export default {
  namespaced: true,

  state: {
    ids: [],
    entities: {},
    isCropping: false,
    isBrushing: false,
    activeElementId: null,
    isFirstMainImageRender: true,
    isFromTemplate: false,
    initialCanvasSize: {},
    currentCanvasSize: {},
    currentDrawingAreaSizes: {},
    drawingAreaSizesFromRestoring: {},
    currentTopLeftDrawingArea: {},
    brush: {
      isEnabled: false,
      color: {
        r: 0,
        g: 0,
        b: 0,
        a: 1,
      },
      thickness: 10,
    },
  },

  getters: {
    isBrushing: state => state.isBrushing,
    getCroppingValue: state => state.isCropping,
    getCurrentTopLeftDrawingArea: state => state.currentTopLeftDrawingArea,
    getCurrentToolBarSize: state => state.currentTopLeftDrawingArea.toolbarsSpace,
    getCurrentDrawingAreaSizes: state => state.currentDrawingAreaSizes,
    getCurrentCanvasSize: state => state.currentCanvasSize,
    getInitialCanvasSize: state => state.initialCanvasSize,
    getOnlyElement: state => state.entities,
    getElements: state => ({ ids: state.ids, entities: state.entities }),
    getElementsCount: state => state.ids.length,
    getActiveElement: state => state.entities[state.activeElementId],
    getActiveElementId: state => state.activeElementId,
    getMainImageId: state => Object.values(state.entities).find(element => element.main)?.id,
    getBrushColor: state => state.brush.color,
    getBrushThickness: state => state.brush.thickness,
    isBrushEnabled: state => state.brush.isEnabled,
    hasMainImage: state =>
      // eslint-disable-next-line implicit-arrow-linebreak
      state.ids.filter(id => {
        const entity = state.entities[id];
        return entity.type === ELEMENT_TYPES.IMAGE && entity.main;
      }).length > 0,
    isFirstMainImageRender: state => state.isFirstMainImageRender,
    isFromTemplate: state => state.isFromTemplate,
  },

  mutations: {
    changeFontFamily(state, payload) {
      state.entities[payload.id].font.family = payload.family;
    },
    changeTextSize(state, payload) {
      state.entities[payload.id].font.size = payload.size;
    },
    changeText(state, payload) {
      state.entities[payload.id].text = payload.text;
      state.entities[payload.id].font = payload.font;
    },
    changeJsonBrush(state, payload) {
      state.entities[payload.id].json = payload.json;
    },
    setIsBrushing(state, payload) {
      state.isBrushing = payload;
    },
    setCropping(state, payload) {
      state.isCropping = payload;
    },
    changeAllElements(state, payload) {
      state.ids = payload.ids;
      state.entities = payload.entities;
    },
    addCurrentTopLeftDrawingArea(state, sizes) {
      state.currentTopLeftDrawingArea = {
        left: sizes.left,
        top: sizes.top,
        toolbarsSpace: sizes.toolbarsSpace,
        sidePaddings: sizes.sidePaddings,
        ratio: sizes.ratio,
        aspectRatio: sizes.aspectRatio,
        topToolbarSize: sizes.topToolbarSize,
        size: sizes.size,
      };
    },
    addDrawingAreaSizesFromRestoring(state, sizes) {
      state.drawingAreaSizesFromRestoring = sizes;
    },

    addCurrentDrawingAreaSizes(state, areaSizes) {
      state.currentDrawingAreaSizes = {
        width: areaSizes.width,
        height: areaSizes.height,
      };
    },

    addCurrentCanvasSize(state, canvasSizes) {
      state.currentCanvasSize = {
        width: canvasSizes.width,
        height: canvasSizes.height,
      };
    },

    addInitialCanvasSize(state, canvasObject) {
      state.initialCanvasSize = {
        width: canvasObject.width,
        height: canvasObject.height,
      };
    },

    selectElement(state, elementId) {
      state.activeElementId = elementId;
    },

    setElementOption(state, { elementId, path, value }) {
      _set(state.entities[elementId], path, value);
    },

    setElementOptionsGroup(state, { elementId, options }) {
      if (elementId) {
        stateMerge(state.entities[elementId], options);
      } else {
        if (options.brush.color) state.brush.color = options.brush.color;
        if (options.brush.thickness) state.brush.thickness = options.brush.thickness;
      }
    },

    setElementTransformations(state, { id, options }) {
      // We have access to current and initial canvas sizes
      stateMerge(state.entities[id], {
        position: {
          x: options.left,
          y: options.top,
        },
        size: {
          width: options.width,
          height: options.height,
        },
        rotation: options.angle,
        scaleX: options.scaleX,
        scaleY: options.scaleY,
        initialCanvasSize: options.initialCanvasSizeX,
      });
    },

    calibrateElementPosition(state, { id, options }) {
      stateMerge(state.entities[id], {
        position: {
          x: options.left,
          y: options.top,
        },
      });
    },

    addImageElement(state, { id, main, imageData, type, stickerId }) {
      const element = _merge(_cloneDeep(mainImage), {
        id,
        main,
        url: imageData,
      });

      if (type === ELEMENT_TYPES.STICKER) {
        element.type = type;
        element.sticker_id = stickerId;
      }
      if (main) {
        state.ids.unshift(id);
      } else {
        state.ids.push(id);
      }

      Vue.set(state.entities, id, element);
    },

    addTextElement(state, id) {
      const text = _cloneDeep(defaultText);
      text.id = id;

      state.ids.push(id);
      Vue.set(state.entities, id, text);
      state.activeElementId = id;
    },

    addTextElementFromHistory(state, { id, element }) {
      const text = _cloneDeep(element);
      state.ids.push(id);
      Vue.set(state.entities, id, text);
    },

    cloneElement(state, { id, newId }) {
      const element = state.entities[id];
      const newElement = _cloneDeep(element);
      newElement.id = newId;
      newElement.position.x += 20;
      newElement.position.y += 20;

      state.ids.push(newId);
      Vue.set(state.entities, newId, newElement);
    },

    deleteElement(state, elementId) {
      state.ids = state.ids.filter(id => id !== elementId);
      Vue.delete(state.entities, elementId);
    },

    clearElements(state) {
      Object.assign(state, {
        ids: [],
        entities: {},
        activeElementId: null,
      });
    },

    setBrushColor(state, color) {
      state.brush.color = color;
    },

    setBrushThickness(state, size) {
      state.brush.thickness = size;
    },

    enableFreeDrawing(state) {
      state.brush.isEnabled = true;
    },

    disableFreeDrawing(state) {
      state.brush.isEnabled = false;
    },

    addDrawingGroup(state, { id, json }) {
      state.ids.push(id);
      Vue.set(state.entities, id, {
        id,
        type: ELEMENT_TYPES.DRAWING_GROUP,
        json,
      });
    },

    mergeElementsFromLayout(state, elementSettings) {
      const { mainImage: mainImageSettings, text: textSettings } = elementSettings;

      state.ids.forEach(id => {
        const entity = state.entities[id];
        const { type } = entity;
        if (type === ELEMENT_TYPES.IMAGE && entity.main && mainImageSettings) {
          stateMerge(entity, mainImageSettings);
        } else if (type === ELEMENT_TYPES.TEXT && textSettings) {
          // stateMerge(entity, textSettings);
        }
      });
    },

    moveLayer(state, delta) {
      const activeElement = state.entities[state.activeElementId];
      if (!activeElement) return false;

      const mainImageId = state.ids.find(id => {
        const entity = state.entities[id];
        return entity.type === ELEMENT_TYPES.IMAGE && entity.main;
      });

      const startIndex = mainImageId ? state.ids.indexOf(mainImageId) + 1 : 0;

      moveElementInArray(state.ids, activeElement.id, startIndex, delta);

      return true;
    },

    loadElements(state, elements) {
      elements.forEach(element => {
        if (element.type === ELEMENT_TYPES.IMAGE && element.main === true) {
          const mainImageId = state.ids.find(elementId => {
            const entity = state.entities[elementId];
            return entity.type === ELEMENT_TYPES.IMAGE && entity.main === true;
          });

          if (mainImageId) {
            state.ids = state.ids.filter(id => id !== mainImageId);
            Vue.delete(state.entities, mainImageId);
          }

          state.ids.unshift(element.id);
          Vue.set(state.entities, element.id, element);
        } else if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
          state.ids.push(element.id);

          const elementClone = _cloneDeep(element);
          elementClone.json.objects = elementClone.json.objects.map(object => ({
            ...object,
            stroke: object.stroke ? rgba(object.stroke) : object.stroke,
            fill: object.fill ? rgba(object.fill) : object.fill,
          }));
          Vue.set(state.entities, elementClone.id, elementClone);
        } else {
          state.ids.push(element.id);
          Vue.set(state.entities, element.id, element);
        }
      });
    },

    setIsFirstMainImageRender(state, payload) {
      state.isFirstMainImageRender = payload;
    },

    setIsFromTemplate(state, payload) {
      state.isFromTemplate = payload;
    },
  },

  actions: {
    changeFontFamily({ commit }, payload) {
      commit('changeFontFamily', payload);
    },
    changeTextSize({ commit, rootState }, payload) {
      if (rootState.canvas.elements.activeElementId === payload.id) {
        commit('changeTextSize', payload);
      }
    },
    changeText({ commit }, payload) {
      commit('changeText', payload);
    },
    addTextElementFromHistory({ commit }, payload) {
      commit('addTextElementFromHistory', payload);
    },
    changeJsonBrush({ commit }, payload) {
      commit('changeJsonBrush', payload);
    },
    setIsBrushing({ commit }, payload) {
      commit('setIsBrushing', payload);
    },
    setCropping({ commit }, payload) {
      commit('setCropping', payload);
    },
    changeAllElementsAction({ commit }, payload) {
      commit('changeAllElements', payload);
    },
    addDrawingAreaSizesFromRestoring({ commit }, payload) {
      commit('addDrawingAreaSizesFromRestoring', payload);
    },
    addCurrentTopLeftDrawingArea({ commit }, payload) {
      commit('addCurrentTopLeftDrawingArea', payload);
    },
    addInitialTopLeftDrawingArea({ commit }, payload) {
      commit('addInitialTopLeftDrawingArea', payload);
    },
    addCurrentDrawingAreaSizes({ commit }, payload) {
      commit('addCurrentDrawingAreaSizes', payload);
    },
    addInitialDrawingAreaSizes({ commit }, payload) {
      commit('addInitialDrawingAreaSizes', payload);
    },
    addCurrentCanvasSize({ commit }, payload) {
      commit('addCurrentCanvasSize', payload);
    },

    addInitialCanvasSize({ commit }, payload) {
      commit('addInitialCanvasSize', payload);
    },

    selectElement({ commit }, elementId) {
      commit('selectElement', elementId);
    },

    setElementOption({ commit }, payload) {
      commit('setElementOption', payload);
    },

    setElementOptionsGroup({ commit }, payload) {
      commit('setElementOptionsGroup', payload);
    },

    setElementTransformations({ commit, dispatch, state }, payload) {
      if (
        !this.getters['canvas/elements/getCroppingValue'] &&
        !state.isFirstMainImageRender &&
        !state.isBrushing &&
        !this.getters['canvas/history/getIsHistorying'] &&
        !this.getters['canvas/frames/getIsDraggable']
      ) {
        dispatch('canvas/history/saveHistory', null, { root: true });
      }
      commit('setElementTransformations', payload);
      // use it because of rerendering of setElementTransformation to prevent adding a new history save
      setTimeout(() => {
        dispatch('canvas/history/setHistorying', false, { root: true });
      }, 100);
    },

    calibrateElementPosition({ commit }, payload) {
      commit('calibrateElementPosition', payload);
    },

    resetMainImageTransformations({ dispatch, getters }) {
      const mainImageId = getters.getMainImageId;
      if (mainImageId) {
        const options = {
          left: null,
          top: null,
          width: null,
          height: null,
          scaleX: null,
          scaleY: null,
          angle: null,
        };

        dispatch('setElementTransformations', {
          id: mainImageId,
          options,
        });
      }
    },

    addMainImage(
      { dispatch, commit, state, rootState },
      { id, imageData, type = ELEMENT_TYPES.MAIN_IMAGE },
    ) {
      const mainImageId = state.ids.find(elementId => {
        const entity = state.entities[elementId];
        return entity.type === ELEMENT_TYPES.IMAGE && entity.main === true;
      });
      if (mainImageId) {
        commit('deleteElement', mainImageId);
        commit('addImageElement', {
          id,
          main: true,
          imageData,
          type,
        });
      } else {
        commit('addImageElement', {
          id,
          main: true,
          imageData,
          type,
        });
      }
      dispatch('canvas/layouts/setActiveLayout', rootState.canvas.layouts.activeLayout, {
        root: true,
      });
      setTimeout(() => {
        dispatch('canvas/elements/setIsFirstMainImageRender', false, { root: true });
      }, 1000);
    },

    addImageElement({ commit }, { id, imageData, type = ELEMENT_TYPES.IMAGE, stickerId }) {
      commit('addImageElement', {
        id,
        main: false,
        imageData,
        type,
        stickerId,
      });
    },

    addTextElement({ commit }, id) {
      commit('addTextElement', id);
    },

    deleteElement({ commit }, elementId) {
      commit('deleteElement', elementId);
    },

    cloneElement({ commit }, payload) {
      commit('cloneElement', payload);
    },

    setBrushColor({ commit }, color) {
      commit('setBrushColor', color);
    },

    setBrushThickness({ commit }, size) {
      commit('setBrushThickness', size);
    },

    enableFreeDrawing({ commit }) {
      commit('enableFreeDrawing');
    },

    disableFreeDrawing({ commit }) {
      commit('disableFreeDrawing');
    },

    addDrawingGroup({ commit }, drawingGroupData) {
      commit('addDrawingGroup', drawingGroupData);
    },

    mergeElementsFromLayout({ commit }, elementSettings) {
      commit('mergeElementsFromLayout', elementSettings);
    },

    moveLayer({ commit, dispatch }, delta) {
      commit('moveLayer', delta);
      dispatch('canvas/history/saveHistory', null, { root: true });
    },

    clearElements({ commit }) {
      commit('clearElements');
    },

    loadElements({ commit }, elements) {
      commit('loadElements', elements);
    },

    setIsFirstMainImageRender({ commit }, payload) {
      commit('setIsFirstMainImageRender', payload);
    },

    setIsFromTemplate({ commit }, payload) {
      commit('setIsFromTemplate', payload);
    },
  },
};
