<template>
  <div class="mg-app-layout">
    <Header />

    <LoginDialog />

    <div class="mg-app-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from '../components/layout/header/Header.vue';
import LoginDialog from '../components/login/LoginDialog.vue';

export default {
  name: 'AppLayout',

  components: {
    LoginDialog,
    Header,
  },
  mounted() {
    const { layout } = this.$route.query;
    if (layout) {
      this.$store.dispatch('canvas/layouts/setQueryLayout', layout);
      this.$store.dispatch('canvas/layouts/setActiveLayout', layout);
    }
  },

  beforeCreate() {
    this.$store.dispatch('alerts/handleErrors');
    this.$store.dispatch('canvas/fonts/loadFonts');
    this.$store.dispatch('auth/getProfile');
  },
};
</script>
