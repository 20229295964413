<template>
  <div class="mg-main-toolbar">
    <MainToolbarItem
      v-for="sidebarName in sidebars"
      :key="sidebarName"
      :sidebarName="sidebarName"
      :is-desktop="isDesktop"
      @onSelect="onSelectSidebar"
    />
  </div>
</template>

<script>
import { SIDEBARS } from '../../../common/constants';
import MainToolbarItem from './MainToolbarItem.vue';

export default {
  name: 'MainToolbar',

  components: {
    MainToolbarItem
  },

  data() {
    return {
      sidebars: [SIDEBARS.LAYOUT, SIDEBARS.FRAME, SIDEBARS.WATERMARK, SIDEBARS.TEMPLATES, SIDEBARS.SAVED],
    };
  },

  computed: {
    isDesktop () {
      return this.$store.getters['ui/isDesktop'];
    }
  },

  methods: {
    onSelectSidebar(sidebarName) {
      this.$store.dispatch('ui/setActiveSidebar', sidebarName);

      if (!this.isDesktop) {
        this.$store.dispatch('ui/openMobileSidebarModal');
      }
    },
  },
};
</script>
