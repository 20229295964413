export const SIDEBARS = {
  LAYOUT: 'layout',
  FRAME: 'frame',
  WATERMARK: 'watermark',
  TEMPLATES: 'templates',
  SAVED: 'saved',
  IMAGE: 'image',
  TEXT: 'text',
  BRUSH: 'brush',
};

export const FRAME_SIDES = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right'
};

export const ELEMENT_TYPES = {
  DRAWING_AREA: 'drawingArea',
  DRAWING_GROUP: 'drawingGroup',
  DRAWING: 'drawing',
  FRAME: 'frame',
  IMAGE: 'image',
  MAIN_IMAGE: 'mainImage',
  TEXT: 'text',
  STICKER: 'sticker',
  WATERMARK: 'watermark',
};

export const FONTS = [
  { value: 'Anton-Regular', text: 'Anton' },
  { value: 'Arimo-Regular', text: 'Arimo' },
  { value: 'Bangers-Regular', text: 'Bangers' },
  { value: 'BebasNeue-Regular', text: 'BebasNeue' },
  { value: 'Caveat-Regular', text: 'Caveat' },
  { value: 'ComicNeue-Regular', text: 'ComicNeue' },
  { value: 'FredokaOne-Regular', text: 'FredokaOne' },
  { value: 'GloriaHallelujah-Regular', text: 'GloriaHallelujah' },
  { value: 'IndieFlower-Regular', text: 'IndieFlower' },
  { value: 'Lobster-Regular', text: 'Lobster' },
  { value: 'Montserrat-Regular', text: 'Montserrat' },
  { value: 'OpenSans-Regular', text: 'OpenSans' },
  { value: 'PermanentMarker-Regular', text: 'PermanentMarker' },
  { value: 'PressStart2P-Regular', text: 'PressStart2P' },
  { value: 'Roboto', text: 'Roboto' },
];

export const FONT_SIZES = [8, 9, 10, 11, 12, 14, 18, 24, 28, 32, 36, 48, 64, 72, 96, 100, 125, 150];

export const MOVE_STEP = 3;

export const SHARE_ITEMS = [
  { name: 'facebook', link: 'https://www.facebook.com/sharer/sharer.php?u=' },
  { name: 'pinterest', link: 'http://pinterest.com/pin/create/button/?url=' },
  { name: 'twitter', link: 'http://www.twitter.com/intent/tweet?url=' },
  { name: 'reddit', link: 'https://reddit.com/submit?url=' },
  { name: 'email', link: '' },
];

export const CREATE_POST_STEPS = {
  TITLE: 1,
  SECTION: 2
};

export const CATEGORIES = [
  {
    name: 'Funny',
    slug: 'funny'
  },
  {
    name: 'Dank Memes',
    slug: 'dank-memes'
  },
  {
    name: 'Animals',
    slug: 'animals'
  },
  {
    name: 'Cats',
    slug: 'cats'
  },
  {
    name: 'Dogs',
    slug: 'dogs'
  },
  {
    name: 'Cartoons',
    slug: 'cartoons'
  },
  {
    name: 'Sports',
    slug: 'sports'
  },
  {
    name: 'Relationships',
    slug: 'relationships'
  },
  {
    name: 'FailWTF',
    slug: 'failwtf'
  },
  {
    name: 'Gaming',
    slug: 'gaming'
  },
  {
    name: 'Food',
    slug: 'food'
  },
  {
    name: 'DIY',
    slug: 'diy-do-it-yourself'
  },
  {
    name: 'Wholesome',
    slug: 'wholesome'
  }
];
