<template>
  <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.05961 3.86351H9.73709V0.941891C9.44769 0.902078 8.45237 0.8125 7.29322 0.8125C4.87461 0.8125 3.2178 2.3338 3.2178 5.12986V7.70312H0.548828V10.9693H3.2178V19.1875H6.49008V10.97H9.05109L9.45764 7.70389H6.48931V5.45372C6.49008 4.5097 6.74427 3.86351 8.05961 3.86351Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon'
};
</script>
