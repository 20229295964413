<template>
  <div class="mg-image-tab__container">

    <div class="mg-image-dialog-loader" v-if="isEmojisPending && !emojisCurrentPage">
      <b-spinner variant="light" />
    </div>

    <div class="mg-emojis-list-container">

      <div class="mg-emojis-list" ref="emojisList">
        <div
          v-for="emoji in emojisData"
          :key="`emoji-${emoji.id}`"
          class="mg-emojis-list__item"
          v-on:click="selectEmoji(emoji.id)"
        >
          <img
            class="mg-stickers-pack-list__item-img"
            :src="emoji.image_url"
            :alt="emoji.name"
          >
        </div>

        <CustomInfiniteScroll
          v-if="emojisCurrentPage"
          :distance="0"
          no-more-text="No more emojis"
          @endScrolling="loadMoreEmojis"
        />
      </div>

    </div>
  </div>
</template>

<script>
import CustomInfiniteScroll from '../../../common/CustomInfiniteScroll.vue';

export default {
  name: 'EmojisTab',

  components: {
    CustomInfiniteScroll
  },

  data() {
    return {
      currentScroll: null
    };
  },

  computed: {
    isEmojisPending () {
      return this.$store.getters['images/isEmojisPending'];
    },

    emojisData () {
      return this.$store.getters['images/getEmojisData'];
    },

    emojisCurrentPage () {
      return this.$store.getters['images/getEmojisCurrentPage'];
    },

    emojisLastPage () {
      return this.$store.getters['images/getEmojisLastPage'];
    }
  },

  mounted () {
    this.$store.dispatch('images/getEmojis', 1);
  },

  beforeDestroy () {
    this.$store.dispatch('images/resetEmojis');
  },

  methods: {
    async loadMoreEmojis($state) {
      const currentScrollPosition = this.$refs.emojisList.scrollTop;

      if (this.emojisCurrentPage >= this.emojisLastPage) {
        $state.complete();
        return;
      }

      await this.$store.dispatch('images/getEmojis', this.emojisCurrentPage + 1);
      this.$refs.emojisList.scrollTop = currentScrollPosition;
      $state.loaded();
    },

    selectEmoji (id) {
      const selectedEmoji = this.emojisData.find((sticker) => sticker.id === id);
      this.$emit('emojiSelected', selectedEmoji);
    },
  }

};
</script>
