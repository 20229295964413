<template>
  <div class="breadcrumbs-container">
    <router-link to="/start" class="breadcrumbs-link">
      <span class="breadcrumbs-home--desktop">Home</span>
      <span class="breadcrumbs-home--mobile">
        <HomeIcon/>
      </span>
    </router-link>

    <span class="breadcrumbs-separator">
      <img src="../../../assets/svg-icons/layout/header/breadcrumbs-separator.svg" alt="" />
    </span>

    <router-link to="/editor" class="breadcrumbs-link">
      <span>Meme Generator</span>
    </router-link>
  </div>
</template>

<script>
import HomeIcon from '../../common/icons/HomeIcon.vue';

export default {
  name: 'Breadcrumbs',

  components: { HomeIcon },
};
</script>
