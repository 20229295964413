<template>
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2L0 16C0 17.1 0.9 18 2 18H12C13.1 18 14 17.1 14 16L14 2C14 0.9 13.1 0 12 0H2C0.9 0 0 0.9 0 2ZM12 2L12 16H2L2 2H12Z" fill="#BDBDBD"/>
  </svg>
</template>

<script>
export default {
  name: 'Icon3To4'
};
</script>
