import { createErrorHandler } from '../../api/apiConfig';

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    handleErrors() {
      createErrorHandler(error => {
        if (error.response.status === 401) {
          // console.log('User is not authenticated');
        }

        // return Promise.reject(error);
      });
    },
  },
};
