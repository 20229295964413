/* eslint-disable no-unused-expressions */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-param-reassign */
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import { ELEMENT_TYPES } from '../../../common/constants';
import { rgbaToObj } from '../../../common/colors';

export default {
  namespaced: true,
  state: {
    historyRedo: [],
    historyArray: [],
    jsonModal: false,
    isHistorying: false,
    brushHistory: false,
    isDeselectedText: false,
  },
  getters: {
    getIsDeselectedText: state => state.isDeselectedText,
    getLastHistoryElement: state => state.historyArray[state.historyArray.length - 1],

    getIsHistorying: state => state.isHistorying,
    getJsonModal: state => state.jsonModal,
    getHistoryArray: state => state.historyArray,
    getRedoArray: state => state.historyRedo,
    getBrushHistorying: state => state.brushHistory,
  },
  mutations: {
    setIsDeselectedText(state, payload) {
      state.isDeselectedText = payload;
    },

    setBrushHistorying(state, payload) {
      state.brushHistory = payload;
    },
    setHistorying(state, payload) {
      state.isHistorying = payload;
    },
    setJsonModal(state, payload) {
      state.jsonModal = payload;
    },
    resetHistory(state) {
      state.historyRedo = [];
      state.historyArray = [];
    },
    addJSONDataToStore(state, JSONdata) {
      state.JSONdata = JSONdata;
    },
    setRedo(state) {
      if (state.historyRedo.length > 0) {
        state.historyArray.push(state.historyRedo.pop());
      }
    },
    setUndo(state) {
      if (state.historyArray.length > 0) {
        state.historyRedo.push(state.historyArray.pop());
      }
    },
    setHistory(state, objectElementJSON) {
      const objectElement = JSON.parse(objectElementJSON);
      if (state.historyRedo.length > 0) state.historyRedo = [];
      if (state.historyArray.length > 19) {
        state.historyArray.shift();
        state.historyArray.push(objectElement);
      } else {
        state.historyArray.push(objectElement);
      }
    },
  },
  actions: {
    setIsDeselectedText({ commit }, payload) {
      commit('setIsDeselectedText', payload);
    },
    setBrushHistorying({ commit }, payload) {
      commit('setBrushHistorying', payload);
    },
    setHistorying({ commit }, payload) {
      commit('setHistorying', payload);
    },
    setJsonModal({ commit }, payload) {
      commit('setJsonModal', payload);
    },
    resetHistoryAction({ commit }) {
      commit('resetHistory');
    },
    setHistoryAction({ commit }, JSONdata) {
      commit('setHistory', JSONdata);
    },
    redoAction({ commit }) {
      commit('setRedo');
    },
    undoAction({ commit }) {
      commit('setUndo');
    },
    stepBackAction({ commit }) {
      commit('stepBack');
    },
    stepForwardAction({ commit }) {
      commit('stepForward');
    },
    saveHistory({ rootState, rootGetters, dispatch }) {
      const canvasState = rootState.canvas;
      const textElementsObjects = {};
      const elements = canvasState.elements.ids.map(id => {
        const element = canvasState.elements.entities[id];
        if (element.type === 'text') {
          textElementsObjects[element.id] = element;
        }
        element.initialCanvasSize = {
          width: canvasState.elements.currentCanvasSize.width,
          height: canvasState.elements.currentCanvasSize.height,
        };
        element.initialDrawingAreaData = {
          left: canvasState.elements.currentTopLeftDrawingArea.left,
          top: canvasState.elements.currentTopLeftDrawingArea.top,
          toolbarsSpace: canvasState.elements.currentTopLeftDrawingArea.toolbarsSpace,
          sidePaddings: canvasState.elements.currentTopLeftDrawingArea.sidePaddings,
          ratio: canvasState.elements.currentTopLeftDrawingArea.ratio,
          aspectRatio: canvasState.elements.currentTopLeftDrawingArea.aspectRatio,
          width: canvasState.elements.currentDrawingAreaSizes.width,
          height: canvasState.elements.currentDrawingAreaSizes.height,
          isTemplateGenerationMode: rootState.ui.isTemplateGenerationMode,
        };
        if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
          const objectsData = element.json.objects.map(drawingItem => {
            const objectData = _pick(drawingItem, [
              'path',
              'left',
              'top',
              'width',
              'height',
              'scaleX',
              'scaleY',
              'angle',
              'stroke',
              'fill',
              'strokeWidth',
              'strokeLineCap',
              'strokeLineJoin',
              'strokeMiterLimit',
            ]);
            if (objectData.fill) {
              objectData.fill = rgbaToObj(objectData.fill);
            }
            if (objectData.stroke) {
              // objectData.stroke = rgbaToObj(objectData.stroke);
            }

            return objectData;
          });

          return {
            ...element,
            json: {
              objects: objectsData,
            },
          };
        }
        return element;
      });
      const data = {
        id: null,
        aspectRatio: canvasState.common.aspectRatio.active,
        originalRatio: canvasState.common.aspectRatio.original,
        height: canvasState.common.height,
        width: canvasState.common.width,
        layout: {
          name: canvasState.layouts.activeLayout,
        },
        frame: canvasState.frames,
        elements,
        textElementsObjects,
        isTemplate: rootGetters['ui/isTemplateGenerationMode'],
        ids: canvasState.elements.ids,
      };
      const allElementsExceptBrush = data.elements.filter(
        item => item.type !== ELEMENT_TYPES.DRAWING_GROUP,
      );
      if (
        allElementsExceptBrush.length === 0 ||
        allElementsExceptBrush.every(element => element.position.x !== null)
      ) {
        const JSONdata = JSON.stringify(data);
        dispatch('checkOnExistingElements', JSONdata);
      }
    },

    checkOnExistingElements({ commit, state }, data) {
      const lastArrayElement = JSON.stringify(state.historyArray[state.historyArray.length - 1]);
      if (data !== lastArrayElement) {
        commit('setHistory', data);
      }
    },

    restoreFromHistory({ dispatch, commit, rootState }, memeData) {
      commit('setHistorying', true);
      const copiedMemeData = _cloneDeep(memeData);

      const currentIds = rootState.canvas.elements.ids;

      const toDeleleIds = currentIds.filter(id => !copiedMemeData.ids.includes(id));
      toDeleleIds &&
        toDeleleIds.forEach(id => {
          dispatch('canvas/elements/deleteElement', id, { root: true });
        });
      const toAddIds = copiedMemeData.ids.filter(id => !currentIds.includes(id));
      toAddIds &&
        toAddIds.forEach(id => {
          copiedMemeData.elements.forEach(element => {
            if (element.id === id) {
              if (element.type === ELEMENT_TYPES.IMAGE) {
                if (element.main) {
                  dispatch(
                    'canvas/elements/addMainImage',
                    {
                      id: element.id,
                      imageData: element.url,
                    },
                    { root: true },
                  );
                } else {
                  dispatch(
                    'canvas/elements/addImageElement',
                    {
                      id: element.id,
                      imageData: element.url,
                      type: ELEMENT_TYPES.IMAGE,
                    },
                    { root: true },
                  );
                }
              } else if (element.type === ELEMENT_TYPES.STICKER) {
                dispatch(
                  'canvas/elements/addImageElement',
                  {
                    id: element.id,
                    imageData: element.url,
                    type: ELEMENT_TYPES.STICKER,
                    stickerId: element.sticker_id,
                  },
                  { root: true },
                );
              } else if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
                dispatch('canvas/elements/addDrawingGroup', element, { root: true });
              } else if (element.type === ELEMENT_TYPES.TEXT) {
                dispatch(
                  'canvas/elements/addTextElementFromHistory',
                  {
                    id: element.id,
                    element,
                  },
                  { root: true },
                );
              }
            }
            if (element.aspectRatio !== rootState.canvas.common.aspectRatio.active) {
              commit('setHistorying', true);
              dispatch('canvas/common/setOriginalAspectRatio', memeData.originalRatio, {
                root: true,
              });
            }
          });
        });
      if (copiedMemeData) {
        const elementsLength = copiedMemeData.elements.length;
        copiedMemeData.elements.forEach((element, i) => {
          if (
            JSON.stringify(element) !==
            JSON.stringify(rootState.canvas.elements.entities[element.id])
          ) {
            commit('setHistorying', true);
            if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
              commit('setBrushHistorying', true);
            }
            if (element.type === ELEMENT_TYPES.TEXT) {
              if (
                element.text !== rootState.canvas.elements.entities[element.id].text ||
                JSON.stringify(element.font) !==
                  JSON.stringify(rootState.canvas.elements.entities[element.id].font)
              ) {
                dispatch(
                  'canvas/elements/changeText',
                  { id: element.id, text: element.text, font: element.font },
                  { root: true },
                );
              }
            }
            const options = {
              left: element.position.x,
              top: element.position.y,
              width: element.size.width,
              height: element.size.height,
              scaleX: element.scaleX,
              scaleY: element.scaleY,
              angle: element.rotation,
            };
            dispatch(
              'canvas/elements/setElementTransformations',
              {
                id: element.id,
                options,
              },
              { root: true },
            );
          }

          if (copiedMemeData.aspectRatio !== rootState.canvas.common.aspectRatio.active) {
            commit('setHistorying', true);
            dispatch('canvas/common/setAspectRatio', copiedMemeData.aspectRatio, { root: true });
          }
          if (JSON.stringify(copiedMemeData.frame) !== JSON.stringify(rootState.canvas.frames)) {
            commit('setHistorying', true);
            dispatch('canvas/frames/mergeFramesFromLayout', copiedMemeData.frame, { root: true });
            dispatch('canvas/frames/setFrameColor', copiedMemeData.frame.bottom.color, {
              root: true,
            });
          }
          // We do it because it helps to select images after undo/redo actions and avoid problem about selecting images
          if (elementsLength - 1 === i) {
            dispatch('canvas/elements/selectElement', element.id, { root: true });
          }
          setTimeout(() => {
            dispatch('canvas/history/setHistorying', false, { root: true });
          }, 100);
        });
      }
    },
  },
};
