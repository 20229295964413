<template>

  <b-modal
    v-model="isOpen"
    hide-footer
    hide-header
    size="lg"
    centered
    dialog-class="mg-post-meme-share-dialog"
    body-class="mg-post-meme-share-dialog__modal"
  >

    <CloseModalButton :click-handler="closeShareModal" />

    <div class="mg-post-meme-share-dialog__link-container">
      <div class="mg-custom-search-input__container">
        <form class="mg-custom-search-input__form">
          <input
            id="custom-search-input"
            type="text"
            v-model="memeUrl"
            :disabled="true"
          >
          <button
            type="button"
            v-clipboard:copy="memeUrl"
            id="mg-custom-search-input-submit-button"
            @click="changeButtonTextContent"
          >
            {{copyButtonTextContent}}
          </button>
        </form>
      </div>
    </div>

    <div class="mg-post-meme-share-dialog__share-container">
      <h2>SHARE</h2>
      <ShareButtons
        :meme-url="memeUrl"
        :meme-image-url="memeImageUrl"
      />
    </div>

    <div class="mg-post-meme-share-dialog__account_container" v-if="!isLoggedIn">
      <p>
        <span @click="onLoginClick">Login</span> or <span @click="onRegisterClick">register</span> to save this meme to your account.
      </p>
    </div>

  </b-modal>
</template>

<script>

import CloseModalButton from '../common/CloseModalButton.vue';
import ShareButtons from './ShareButtons.vue';

export default {
  name: 'ShareDialog',

  components: {
    CloseModalButton,
    ShareButtons
  },

  computed: {
    isOpen: {
      get () {
        return this.$store.getters['ui/isOpenShareModal'];
      },

      set () {
        this.$store.dispatch('ui/closeShareModal');
      }
    },

    isLoggedIn () {
      return this.$store.getters['auth/getIsLoggedIn'];
    },

    memeUrl () {
      return this.$store.getters['meme/getMemeUrl'];
    },

    memeImageUrl () {
      return this.$store.getters['meme/getMemeImageUrl'];
    }
  },

  data() {
    return {
      copyButtonTextContent: 'COPY LINK'
    };
  },

  methods: {
    closeShareModal () {
      this.isOpen = false;
    },

    onLoginClick () {
      this.$store.dispatch('ui/openLoginModal');
    },

    onRegisterClick () {
      this.$store.dispatch('ui/openRegisterModal');
    },

    changeButtonTextContent () {
      this.copyButtonTextContent = 'COPIED!';
      setTimeout(() => {
        this.copyButtonTextContent = 'COPY LINK';
      }, 500);
    }
  }
};
</script>
