<template>
  <b-modal
    v-model="isOpen"
    hide-footer
    hide-header
    size="sm"
    centered
    dialog-class="mg-login-dialog"
    body-class="mg-login-dialog__modal"
  >
    <CloseModalButton :click-handler="closeLoginModal" />

    <div class="mg-login-dialog__header">
      Hey, there!
    </div>

    <form @submit.prevent="onSubmit" class="mg-login-dialog__form">
      <div class="mg-login-dialog__form__controls">
        <div v-if="isSignIn" class="mg-login-dialog__sign-in-container">
          <div class="mg-login-dialog__form__input-container">
            <img
              class="input-prepend-icon"
              src="../../assets/svg-icons/login/user-icon.svg"
              alt=""
            />
            <input
              type="email"
              v-model="signInEmail"
              placeholder="Email"
              class="mg-login-dialog__form__input"
            />
          </div>

          <div class="mg-login-dialog__form__input-container">
            <img
              class="input-prepend-icon"
              src="../../assets/svg-icons/login/password-icon.svg"
              alt=""
            />
            <input
              :type="isSignInPasswordVisible ? 'text' : 'password'"
              v-model="signInPassword"
              autocomplete="current-password"
              placeholder="Password"
              class="mg-login-dialog__form__input"
              minlength="8"
            />
            <img
              class="input-append-icon"
              src="../../assets/svg-icons/login/password-show-icon.svg"
              alt=""
              @click="toggleSignInPasswordVisibility"
            />
          </div>
        </div>

        <div v-else class="mg-login-dialog__sign-up-container">
          <div class="mg-login-dialog__form__input-container">
            <img
              class="input-prepend-icon"
              src="../../assets/svg-icons/login/user-icon.svg"
              alt=""
            />
            <input
              type="text"
              v-model="signUpUsername"
              placeholder="Username"
              class="mg-login-dialog__form__input"
            />
          </div>

          <div class="mg-login-dialog__form__input-container">
            <img
              class="input-prepend-icon"
              src="../../assets/svg-icons/login/user-icon.svg"
              alt=""
            />
            <input
              type="email"
              v-model="signUpEmail"
              placeholder="Email"
              class="mg-login-dialog__form__input"
            />
          </div>

          <div class="mg-login-dialog__form__input-container">
            <img
              class="input-prepend-icon"
              src="../../assets/svg-icons/login/password-icon.svg"
              alt=""
            />
            <input
              :type="isSignUpPasswordVisible ? 'text' : 'password'"
              v-model="signUpPassword"
              autocomplete="new-password"
              placeholder="Password"
              minlength="8"
              class="mg-login-dialog__form__input"
            />
            <img
              class="input-append-icon"
              src="../../assets/svg-icons/login/password-show-icon.svg"
              alt=""
              @click="toggleSignUpPasswordVisibility"
            />
          </div>

          <div class="mg-login-dialog__form__input-container">
            <img
              class="input-prepend-icon"
              src="../../assets/svg-icons/login/password-icon.svg"
              alt=""
            />
            <input
              :type="isSignUpPasswordVisible ? 'text' : 'password'"
              v-model="signUpPasswordConfirmation"
              placeholder="Repeat password"
              class="mg-login-dialog__form__input"
            />
            <img
              class="input-append-icon"
              src="../../assets/svg-icons/login/password-show-icon.svg"
              alt=""
              @click="toggleSignUpPasswordVisibility"
            />
          </div>
        </div>
      </div>

      <div class="mg-login-dialog__form__errors-container">
        {{ isSignIn ? signInErrorMessage : signUpErrorMessage }}
      </div>

      <div class="mg-login-dialog__form__buttons">
        <ButtonGradientBackground
          type="submit"
          :text-content="submitButtonTextContent"
          :additional-basic-styles="{
            background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
            width: '100%',
            height: '40px',
            marginBottom: '10px',
            fontSize: '14px',
            fontWeight: '500',
            borderRadius: '6px',
          }"
          :additional-hover-styles="{
            background: 'linear-gradient(93.79deg, #3AD192 37.36%, #28CBFF 84.4%, #129F64 107.5%)',
          }"
          :is-disabled="isPending"
        />

        <ButtonGradientOutline
          :text-content="isSignIn ? 'CREATE ACCOUNT' : 'LOG IN'"
          gradient-outline-style="thirdly"
          :additional-basic-styles="{
            border: '2px solid #3ad192',
            width: '100%',
            height: '45px',
            fontSize: '14px',
            fontWeight: '500',
          }"
          :additional-hover-styles="{
            border: '2px solid #28cbff',
          }"
          :click-handler="toggleSignInSignUp"
        />
      </div>

      <div class="mg-login-dialog__form__networks-container">
        <p>or continue with</p>
        <div class="mg-login-dialog__form__networks">
          <span @click="loginWithGoogle" class="mg-login-dialog-item">
            <img src="../../assets/svg-icons/login/google-circle-icon.svg" alt="" />
          </span>

          <span @click="loginWithFacebook">
            <img src="../../assets/svg-icons/login/fb-circle-icon.svg" alt="" />
          </span>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
// import Vue from 'vue';
// import axios from 'axios';
import CloseModalButton from '../common/CloseModalButton.vue';
import ButtonGradientOutline from '../common/ButtonGradientOutline.vue';
import ButtonGradientBackground from '../common/ButtonGradientBackground.vue';
// import { handleClientLoad /* handleAuthClick */ } from './AuthGoogle';

export default {
  name: 'LoginDialog',

  components: {
    CloseModalButton,
    ButtonGradientOutline,
    ButtonGradientBackground,
  },

  computed: {
    isOpen: {
      get() {
        return this.$store.getters['ui/isOpenLoginModal'];
      },

      set() {
        this.$store.dispatch('ui/closeLoginModal');
        this.resetForm();
      },
    },

    isSignIn() {
      return this.$store.getters['ui/isSignInModal'];
    },

    isPending() {
      return this.$store.getters['auth/getIsPending'];
    },

    submitButtonTextContent() {
      if (this.isPending) {
        this.$store.dispatch('canvas/watermark/toggleModal', false);
        return 'Processing...';
      }
      return this.isSignIn ? 'LOG IN' : 'CREATE ACCOUNT';
    },

    signInErrorMessage() {
      return this.$store.getters['auth/getSignInError'];
    },

    signUpErrorMessage() {
      return this.$store.getters['auth/getSignUpError'];
    },

    redirectUrl() {
      return this.$store.getters['auth/getRedirectUrl'];
    },
  },

  data() {
    return {
      signInEmail: '',
      signInPassword: '',
      isSignInPasswordVisible: false,
      signUpUsername: '',
      signUpEmail: '',
      signUpPassword: '',
      signUpPasswordConfirmation: '',
      isSignUpPasswordVisible: false,
    };
  },

  created() {
    window.fbAsyncInit = function() {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true,
        version: 'v13.0',
      });
      // eslint-disable-next-line no-undef
      FB.AppEvents.logPageView();
    };

    // eslint-disable-next-line wrap-iife
    (function(d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      // eslint-disable-next-line prefer-const
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  },

  methods: {
    loginWithFacebook() {
      window.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          this.$store.dispatch('auth/socialMediaAuth', {
            socialMedia: 'facebook',
            token: response.authResponse.accessToken,
          });
        } else {
          window.FB.login(data => {
            if (data.status === 'connected') {
              this.$store.dispatch('auth/socialMediaAuth', {
                socialMedia: 'facebook',
                token: data.authResponse.accessToken,
              });
            }
          });
        }
      });
    },
    async loginWithGoogle() {
      const googleUser = await this.$gAuth.signIn();
      this.$store.dispatch('auth/socialMediaAuth', {
        socialMedia: 'google',
        token: googleUser.vc.id_token,
      });
    },
    onSubmit() {
      if (this.isSignIn) {
        const payload = {
          email: this.signInEmail,
          password: this.signInPassword,
        };

        this.$store.dispatch('auth/login', payload).then(() => {
          this.redirectAfterLogin();
        });
      } else {
        const payload = {
          username: this.signUpUsername,
          email: this.signUpEmail,
          password: this.signUpPassword,
          password_confirmation: this.signUpPasswordConfirmation,
        };

        this.$store.dispatch('auth/register', payload).then(() => {
          this.redirectAfterLogin();
        });
      }
    },

    redirectAfterLogin() {
      if (this.redirectUrl) {
        this.$router.push(this.redirectUrl);
        this.$store.dispatch('auth/setRedirectAfterLogin', null);
      }
    },

    toggleSignInSignUp() {
      const action = this.isSignIn ? 'ui/openRegisterModal' : 'ui/openLoginModal';
      this.$store.dispatch(action);
      this.resetForm();
    },

    toggleSignInPasswordVisibility() {
      this.isSignInPasswordVisible = !this.isSignInPasswordVisible;
    },

    toggleSignUpPasswordVisibility() {
      this.isSignUpPasswordVisible = !this.isSignUpPasswordVisible;
    },

    closeLoginModal() {
      this.isOpen = false;
    },

    resetForm() {
      this.$store.dispatch('auth/setSignInError', null);
      this.$store.dispatch('auth/setSignUpError', null);
      this.$store.dispatch('auth/setRedirectAfterLogin', null);

      this.signInEmail = '';
      this.signInPassword = '';
      this.isSignInPasswordVisible = '';
      this.signUpUsername = '';
      this.signUpEmail = '';
      this.signUpPassword = '';
      this.signUpPasswordConfirmation = '';
      this.isSignUpPasswordVisible = '';
    },
  },
};
</script>
