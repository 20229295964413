/* eslint-disable */
import { fabric } from 'fabric';
import { ELEMENT_TYPES } from '../constants';

export const initMgPencilBrush = function () {

  // Add restriction area for brush
  fabric.MgPencilBrush = fabric.util.createClass(fabric.PencilBrush, {

    restrictionTop: null,
    restrictionLeft: null,
    restrictionRight: null,
    restrictionBottom: null,

    initialize: function(canvas, options) {
      this.callSuper('initialize', canvas);
      this.restrictionTop = options.restrictionTop;
      this.restrictionLeft = options.restrictionLeft;
      this.restrictionRight = options.restrictionRight;
      this.restrictionBottom = options.restrictionBottom;
    },

    _finalizeAndAddPath: function() {
      var ctx = this.canvas.contextTop;
      ctx.closePath();
      if (this.decimate) {
        this._points = this.decimatePoints(this._points, this.decimate);
      }
      var pathData = this.convertPointsToSVGPath(this._points).join('');
      if (pathData === 'M 0 0 Q 0 0 0 0 L 0 0') {
        // do not create 0 width/height paths, as they are
        // rendered inconsistently across browsers
        // Firefox 4, for example, renders a dot,
        // whereas Chrome 10 renders nothing
        this.canvas.requestRenderAll();
        return;
      }

      var path = this.createPath(pathData);
      this.canvas.clearContext(this.canvas.contextTop);
      this.canvas.fire('before:path:created', { path: path });
      this.canvas.add(path);

      //////////////////////////////////////////////////////////////
      // add clipping for drawing path
      //////////////////////////////////////////////////////////////
      path.clipPath = new fabric.Rect({
        width: this.restrictionRight - this.restrictionLeft,
        height: this.restrictionBottom - this.restrictionTop,
        left: this.restrictionLeft,
        top: this.restrictionTop,
        absolutePositioned: true
      });
      path.elementType = ELEMENT_TYPES.DRAWING;

      this.canvas.requestRenderAll();
      path.setCoords();
      this._resetShadow();
      //////////////////////////////////////////////////////////////

      // fire event 'path' created
      this.canvas.fire('path:created', { path: path });
    }
  });
};
/* eslint-enable */
