import { fabric } from 'fabric';
import { ELEMENT_TYPES, FRAME_SIDES, SIDEBARS } from '../../../../common/constants';
import { calculateRatio } from '../../../../common/utils';

const ImageElements = {
  computed: {
    activeRatio() {
      return calculateRatio(this.$store.getters['canvas/common/getAspectRatio']);
    },

    originalRatio() {
      return this.$store.getters['canvas/common/getOriginalRatio'];
    },

    frames() {
      return this.$store.getters['canvas/frames/getFrames'];
    },

    isFirstMainImageRender() {
      return this.$store.getters['canvas/elements/isFirstMainImageRender'];
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
    isCropping() {
      return this.$store.getters['canvas/elements/getCroppingValue'];
    },
    isHistorying() {
      return this.$store.getters['canvas/history/getIsHistorying'];
    },
  },

  methods: {
    renderImage(element, isMain = true) {
      if (this.$store.getters['canvas/layouts/getActiveLayout'] === 'layout-2') {
        // eslint-disable-next-line no-param-reassign
        element.visible = false;
      }
      return new Promise(resolve => {
        const tempObj = new fabric.Object();
        tempObj.set('id', element.id);
        this.canvas.add(tempObj);
        const url = element.url.includes('base64') ? element.url : `${element.url}?canvas=true`;
        fabric.Image.fromURL(
          url,
          img => {
            this.removeObjectById(element.id);
            img.set('id', element.id);

            let transformOptions = {};
            if (element.position.x !== null) {
              transformOptions = this.getTransformationsFromElement(element);
            }

            img.set(transformOptions);

            if (!transformOptions.scaleX) {
              let scaleFactor = 0.5;
              if (element.type === ELEMENT_TYPES.STICKER) {
                scaleFactor = 0.25;
              } else if (isMain) {
                scaleFactor = 1;
              }
              this.initialScaleImage(img, scaleFactor, isMain);
            } else if (!element.initialCanvasSize && isMain) {
              // Check if this image after cropp(when we use cropp there aren't any initialCanvasSize)
              this.initialScaleImage(img, 1, isMain);
            }

            this.canvas.add(img);
            this.addClipPathToObject(img);

            if (isMain) {
              const drawingAreaIndex = this.canvas.getObjects().indexOf(this.drawingArea);
              img.moveTo(drawingAreaIndex + 1);
              if (this.isFirstMainImageRender && !this.isHistorying) {
                this.$store.dispatch('canvas/history/saveHistory');
                // this.initialScaleImage(img, 1, isMain);
              }
            }

            this.addImageEvents(img, isMain);
            this.saveElementTransformations(img, element);
            this.renderDrawingArea(img);

            if (element.id === this.activeElementId) {
              this.canvas.setActiveObject(img);
            }
            resolve();
          },
          {
            originX: 'left',
            originY: 'top',
            left:
              this.drawingArea.left +
              this.convertPercentagesToPixels(this.frames[FRAME_SIDES.LEFT].size, 'left'),
            top: this.drawingArea.top, // this.convertPercentagesToPixels(this.frames[FRAME_SIDES.TOP].size, 'top'),

            elementType: isMain ? 'mainImage' : ELEMENT_TYPES.IMAGE,
            visible: element.visible,
            opacity: element.opacity,
            crossOrigin: 'anonymous',
            // commented it because we've changed moving of main image to true
            // lockMovementX: !this.isMovementAllowed(false, 'x'),
            // lockMovementY: !this.isMovementAllowed(false, 'y'),
            // lockRotation: false,
            // lockScalingX: false,
            // lockScalingY: false,
            // lockSkewingX: false,
            // lockSkewingY: false,
          },
        );
      });
    },

    updateImage(element, isMain = true) {
      const img = this.findObjectById(element.id);
      if (!img) {
        this.renderImage(element, isMain);
        return false;
      }

      img.set({
        visible: element.visible,
        opacity: element.opacity,
      });

      this.setObjectOption(img, 'left', element.position.x);
      this.setObjectOption(img, 'top', element.position.y);
      this.setObjectOption(img, 'width', element.size.width);
      this.setObjectOption(img, 'height', element.size.height);
      this.setObjectOption(img, 'angle', element.rotation);
      this.setObjectOption(img, 'scaleX', element.scaleX);
      this.setObjectOption(img, 'scaleY', element.scaleY);

      this.canvas.renderAll();
      return true;
    },

    initialScaleImage(img, factor = 1, isMain) {
      const imgRatio = parseFloat((img.width / img.height).toFixed(2));
      const canvasRatio = parseFloat((this.drawingArea.width / this.drawingArea.height).toFixed(2));
      const contentHeight = this.drawingArea.height;
      const contentWidth = this.drawingArea.width;

      if (isMain) {
        const topOffset = this.drawingArea.top;
        const leftOffset = this.drawingArea.left;
        img.set('top', topOffset);
        img.set('left', leftOffset);

        // unresolved bug with unwanted 1px margins
        const whiteFieldsCorrection = 0;
        if (this.activeRatio === 'original') {
          img.scaleToHeight(contentHeight + whiteFieldsCorrection);
        } else if (this.originalRatio < this.activeRatio) {
          img.scaleToWidth(contentWidth + whiteFieldsCorrection);
        } else if (this.originalRatio > this.activeRatio) {
          img.scaleToHeight(contentHeight + whiteFieldsCorrection);
        }
      }
      if (imgRatio < canvasRatio) {
        img.scaleToHeight(contentHeight * factor);
        const imgWidth = img.getBoundingRect().width;
        const leftCenterOffset =
          this.drawingArea.left +
          this.convertPercentagesToPixels(this.frames[FRAME_SIDES.LEFT].size, 'left') +
          (contentWidth - imgWidth) / 2;
        img.set('left', leftCenterOffset);
      } else {
        img.scaleToWidth(contentWidth * factor);
        const imgHeight = img.getBoundingRect().height;
        const topCenterOffset =
          this.drawingArea.top +
          // +this.convertPercentagesToPixels(this.frames[FRAME_SIDES.TOP].size, 'top') +
          (contentHeight - imgHeight) / 2;
        img.set('top', topCenterOffset);
      }
    },

    addImageEvents(imageElement) {
      imageElement.on('modified', event => {
        this.saveElementTransformations(event.target, imageElement);
        this.renderDrawingArea(event.target);
      });

      imageElement.on('moving', () => {
        // commented it because we had decided to moving our background
        // if (!isMain || this.activeRatio === 'original') {
        //   return;
        // }
        //   const activeObject = this.canvas.getActiveObject();
        //   if (this.originalRatio > this.activeRatio) {
        //     const leftFrame = this.frames[FRAME_SIDES.LEFT].size;
        //     const rightFrame = this.frames[FRAME_SIDES.RIGHT].size;
        //     const offsetHorizontal =
        //       event.transform.target.scaleX * event.transform.target.width -
        //       this.drawingArea.left -
        //       this.drawingArea.width;
        //     if (event.transform.target.left - leftFrame >= this.drawingArea.left) {
        //       activeObject.left = this.drawingArea.left + leftFrame;
        //     } else if (event.transform.target.left + rightFrame < -offsetHorizontal) {
        //       activeObject.left = -offsetHorizontal - rightFrame;
        //     }
        //   } else {
        //     const topFrame = this.frames[FRAME_SIDES.TOP].size;
        //     const bottomFrame = this.frames[FRAME_SIDES.BOTTOM].size;
        //     const offsetVertical =
        //       event.transform.target.scaleY * event.transform.target.height -
        //       this.drawingArea.top -
        //       this.drawingArea.height;
        //     if (event.transform.target.top - topFrame >= this.drawingArea.top) {
        //       activeObject.top = this.drawingArea.top + topFrame;
        //     } else if (event.transform.target.top + bottomFrame < -offsetVertical) {
        //       activeObject.top = -offsetVertical - bottomFrame;
        //     }
        //   }
      });

      imageElement.on('selected', () => {
        this.$store.dispatch('canvas/elements/selectElement', imageElement.id);
        this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.IMAGE);
      });

      imageElement.on('mousedown', () => {
        if (this.$store.getters['ui/getActiveSidebar'] !== SIDEBARS.IMAGE && !this.isBrushEnabled) {
          this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.IMAGE);
        }
      });

      imageElement.on('deselected', () => {
        if (imageElement.id !== this.activeElementId) return false;

        this.$store.dispatch('canvas/elements/selectElement', null);
        if (this.isActiveSidebarResetRequired) {
          this.$store.dispatch(
            'ui/setActiveSidebar',
            this.$store.getters['ui/isDesktop'] ? SIDEBARS.LAYOUT : null,
          );
        }

        return true;
      });
    },

    convertPercentagesToPixels(value, key) {
      if (typeof value === 'number') {
        return value;
      }

      const percentagesDigits = parseInt(value, 10) / 100;
      const drawingAreaSide =
        key === 'left' || key === 'right' ? this.drawingArea.width : this.drawingArea.height;

      return drawingAreaSide * percentagesDigits;
    },

    isMovementAllowed(isMain, axis) {
      if (!isMain) {
        return true;
      }

      if (this.activeRatio === 'original') {
        return false;
      }

      if (this.originalRatio > this.activeRatio) {
        return axis === 'x';
      }
      return axis === 'y';
    },
  },
};

export default ImageElements;
