<template>
  <div id="meme-generator">
      <router-view />
  </div>
</template>

<script>
import Bowser from 'bowser';
import { SIDEBARS } from './common/constants';

export default {
  name: 'App',

  created () {
    this.setAppHeight();
    window.addEventListener('resize', this.setAppHeight);

    const deviceInfo = Bowser.getParser(window.navigator.userAgent).parsedResult;
    this.$store.dispatch('ui/setDeviceInfo', deviceInfo);

    const isDesktop = this.$store.getters['ui/isDesktop'];
    this.$store.dispatch('ui/setActiveSidebar', isDesktop ? SIDEBARS.LAYOUT : null);
  },

  destroyed() {
    window.removeEventListener('resize', this.setAppHeight);
  },

  methods: {
    setAppHeight () {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    }
  }
};
</script>

<style>
@import './styles/index.css';
</style>
