import { queryGet } from './apiConfig';

export default {
  getStickerPacks(page) {
    return queryGet('/generator/sticker-packs', {
      params: { page },
    });
  },

  getStickerPack(id, page) {
    return queryGet(`/generator/sticker-packs/${id}`, { params: { page } });
  },

  getEmojis(page) {
    return queryGet('/generator/emojis', {
      params: { page },
    });
  },
};
