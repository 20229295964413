<template>
  <CustomButtonTemplate
    :text-content="textContent"
    :icon-name="iconName"
    :specificContentComponentName="specificContentComponentName"
    :gradient-outline-style="gradientOutlineStyle"
    :basic-styles="{...basicStyles, ...additionalBasicStyles}"
    :hover-styles="{...hoverStyles, ...additionalHoverStyles}"
    :is-disabled="isDisabled"
    @buttonClicked="clickHandler"
  />
</template>

<script>
import CustomButtonTemplate from './CustomButtonTemplate.vue';

export default {
  name: 'ButtonRoundGradientOutline',

  components: { CustomButtonTemplate },

  props: {
    textContent: String,
    iconName: String,
    specificContentComponentName: String,
    clickHandler: {
      type: Function,
      required: false,
      default: () => null
    },
    gradientOutlineStyle: String,
    additionalBasicStyles: Object,
    additionalHoverStyles: Object,
    isDisabled: Boolean,
  },

  data () {
    return {
      basicStyles: {
        width: '50px',
        height: '50px',
        borderRadius: '50%'
      },
      hoverStyles: {}
    };
  }
};
</script>
