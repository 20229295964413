<template>
  <div class="mg-sidebar__settings-block">
    <div class="mg-sidebar-subheader">Layer</div>

    <div
      class="mg-sidebar__layers-controls"
      :class="{ 'mg-sidebar__layers-controls--text': isTextSidebar }"
    >
      <ButtonGradientBackground
        icon-name="backward-icon"
        :text-content="textContentBackward"
        :click-handler="moveBackward"
        :additional-basic-styles="{
          background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
          width: `${buttonWidth}`,
          height: '40px',
          fontSize: '14px',
          marginRight: '10px',
        }"
        :additional-hover-styles="{
          background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)'
        }"
        :additional-icon-styles="{
          marginRight: '7px'
        }"
      />

      <ButtonGradientBackground
        icon-name="forward-icon"
        :text-content="textContentForward"
        :click-handler="moveForward"
        :additional-basic-styles="{
          background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
          width: `${buttonWidth}`,
          height: '40px',
          fontSize: '14px',
        }"
        :additional-hover-styles="{
          background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)'
        }"
        :additional-icon-styles="{
          marginRight: '7px'
        }"
      />
    </div>
  </div>
</template>

<script>
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';

export default {
  name: 'LayersControls',

  components: {
    ButtonGradientBackground
  },

  props: {
    isTextSidebar: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    textContentBackward () {
      return this.isTextSidebar ? 'backward' : 'move backward';
    },

    textContentForward () {
      return this.isTextSidebar ? 'forward' : 'move forward';
    },

    buttonWidth () {
      return this.isTextSidebar ? '115px' : '150px';
    }
  },

  methods: {
    moveBackward () {
      this.$store.dispatch('canvas/elements/moveLayer', -1);
    },

    moveForward () {
      this.$store.dispatch('canvas/elements/moveLayer', 1);
    },
  }
};
</script>
