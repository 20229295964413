<template>
  <CustomButtonTemplate
    :type="type"
    :text-content="textContent"
    :icon-name="iconName"
    :icon-hover-name="iconHoverName"
    :icon-active-name="iconActiveName"
    :iconPosition="iconPosition"
    :is-active="isActive"
    :basic-styles="{...basicStyles, ...additionalBasicStyles}"
    :hover-styles="{...hoverStyles, ...additionalHoverStyles}"
    :icon-styles="{...iconStyles, ...additionalIconStyles}"
    :is-disabled="isDisabled"
    @buttonClicked="clickHandler"
  />
</template>

<script>
import CustomButtonTemplate from './CustomButtonTemplate.vue';

export default {
  name: 'ButtonGradientBackground',

  components: { CustomButtonTemplate },

  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    textContent: String,
    clickHandler: {
      type: Function,
      default: () => null
    },
    iconName: String,
    iconHoverName: String,
    iconActiveName: String,
    iconPosition: {
      type: String,
      default: 'inline-start'
    },
    isActive: Boolean,
    additionalBasicStyles: Object,
    additionalHoverStyles: Object,
    additionalIconStyles: Object,
    isDisabled: Boolean,
  },

  data () {
    return {
      basicStyles: {
        width: '120px',
        height: '35px',
        background: 'linear-gradient(135deg, #9461FE 0%, #14E1DA 100%)',
        color: '#FFFFFF',
      },
      hoverStyles: {
        background: 'linear-gradient(135deg, #14E1DA 0%, #9461FE 100%)',
      },
      iconStyles: {
        display: 'flex',
      }
    };
  }
};
</script>
