<template>
  <TemplatesSidebar
    type="reddit"
    @templateAdded="templateAdded"
  />
</template>

<script>
import TemplatesSidebar from '../../sidebars/TemplatesSidebar.vue';

export default {
  name: 'RedditTab',

  components: {
    TemplatesSidebar
  },

  methods: {
    templateAdded () {
      this.$emit('templateAdded');
    }
  }
};
</script>
