var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPending)?_c('div',{staticClass:"mg-post-meme-loader"},[_c('b-spinner',{attrs:{"variant":"light"}})],1):_c('div',{staticClass:"mg-post-meme-container"},[_c('section',{staticClass:"mg-post-meme-container__meme-section"},[_c('FinalMemePreview',{attrs:{"image-url":_vm.imageUrl}})],1),_c('section',{staticClass:"mg-post-meme-container__controls-section"},[_c('div',{staticClass:"mg-post-meme-container__tools-block"},[_c('div',{staticClass:"mg-post-meme-container__tools-block__buttons-container"},[_c('ButtonGradientBackground',{attrs:{"text-content":"Post to Memehead","additional-basic-styles":{
              width: _vm.isDesktop ? '302px' : '100%',
              height: '58px',
              background: '#27CAFE',
              fontSize: '22px',
            },"click-handler":_vm.onPostToMemeheadClick}}),(_vm.isDesktop)?_c('ButtonGradientOutline',{attrs:{"text-content":"Hidden","gradient-outline-style":"primary-purple-blue","additional-basic-styles":{
              width: '306px',
              height: '64px',
              fontSize: '22px',
            },"click-handler":_vm.onHiddenClick}}):_vm._e()],1),_c('div',{staticClass:"mg-post-meme-container__tools-block__tools-container"},[_c('h2',[_vm._v("Tools")]),_c('div',{staticClass:"mg-post-meme-container__tools-block__tools"},[_c('div',{staticClass:"mg-post-meme-container__tools-block__tools-item",on:{"click":_vm.onDownload}},[_c('DownloadIcon'),_c('span',[_vm._v("Download")])],1),_c('router-link',{staticClass:"mg-post-meme-container__tools-block__tools-item",attrs:{"to":("/editor/" + _vm.memeId)}},[_c('EditIcon'),_c('span',[_vm._v("Edit")])],1),_c('router-link',{staticClass:"mg-post-meme-container__tools-block__tools-item",attrs:{"to":"/start"}},[_c('MakeAnotherMemeIcon'),_c('span',[_vm._v("Make Another Meme")])],1)],1)]),(!_vm.isDesktop)?_c('div',{staticClass:"mg-post-meme-container__share-block"},[_c('h2',[_vm._v("Share")]),_c('div',{staticClass:"mg-post-meme-container__buttons-container"},[_c('div',{staticClass:"mg-post-meme-container__share-buttons-container"},[_c('ShareButtons')],1),_c('ButtonGradientBackground',{attrs:{"text-content":"copy link","additional-basic-styles":{
                width: '100%',
                height: '42px',
                background: '#333333',
                fontSize: '12px',
              },"icon-name":"copy-link-icon","additional-icon-styles":{
                marginRight: '7px',
              }}})],1)]):_vm._e()])])]),(_vm.isDesktop)?_c('ShareDialog'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }