<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H2C0.9 0 0 0.9 0 2V12C0 13.1 0.9 14 2 14H16C17.1 14 18 13.1 18 12V2C18 0.9 17.1 0 16 0ZM16 12H2V2H16V12Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'Icon4To3'
};
</script>
