<template>
  <svg width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5465 14.12L4.43984 8L10.5465 1.88L8.6665 0L0.666504 8L8.6665 16L10.5465 14.12Z" fill="#BDBDBD"/>
  </svg>
</template>

<script>
export default {
  name: 'BackIcon'
};
</script>
