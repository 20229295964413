<template>
  <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.24956 0.8125C3.21343 0.8125 0.535156 4.03975 0.535156 7.55873C0.535156 9.19073 1.44706 11.2259 2.90679 11.8713C3.12844 11.9712 3.24904 11.9287 3.29842 11.7231C3.34206 11.567 3.53386 10.8147 3.62689 10.4598C3.6556 10.3461 3.64067 10.2473 3.54879 10.1405C3.06413 9.58007 2.67939 8.55906 2.67939 7.60122C2.67939 5.1469 4.63067 2.76378 7.95095 2.76378C10.8222 2.76378 12.8309 4.62893 12.8309 7.29687C12.8309 10.3117 11.2356 12.3973 9.16261 12.3973C8.01527 12.3973 7.16079 11.4969 7.43183 10.3829C7.75915 9.05636 8.40116 7.62993 8.40116 6.67324C8.40116 5.81532 7.9165 5.10555 6.9265 5.10555C5.75849 5.10555 4.81098 6.26208 4.81098 7.81484C4.81098 8.80139 5.16012 9.46751 5.16012 9.46751C5.16012 9.46751 4.00474 14.135 3.78997 15.0067C3.42705 16.4825 3.83936 18.8725 3.87496 19.0781C3.89678 19.1918 4.02427 19.2274 4.09547 19.1343C4.20917 18.985 5.60574 16.9924 5.99737 15.5522C6.13978 15.0273 6.72436 12.8992 6.72436 12.8992C7.10911 13.594 8.2197 14.1763 9.40264 14.1763C12.9216 14.1763 15.4644 11.0834 15.4644 7.24519C15.4517 3.56543 12.3026 0.8125 8.24956 0.8125Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'PinterestIcon'
};
</script>
