export default {
  namespaced: true,

  state: {
    isEnabled: true,
    isModalEnabled: false,
  },

  getters: {
    isWatermarkEnabled: state => state.isEnabled,
    isModalEnabled: state => state.isModalEnabled,
  },

  mutations: {
    toggleWatermark(state, value) {
      state.isEnabled = value;
    },
    toggleModal(state, value) {
      state.isModalEnabled = value;
    },
  },

  actions: {
    toggleWatermark({ commit }, value) {
      commit('toggleWatermark', value);
    },
    toggleModal({ commit }, value) {
      commit('toggleModal', value);
    },
  },
};
