<template>
  <div class="mg-image-dialog__upload-drop-area">
    <div class="mg-image-dialog__upload-text">
      <div>
        Click To Upload
        <ImageDialogUploadIcon />
      </div>
      <div>or, drag and drop files here</div>
    </div>

    <vue-dropzone
      id="imageDialogUploadArea"
      :options="dropzoneOptions"
      :includeStyling="false"
      @vdropzone-success="onUploadSuccess"
    />
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import imageCompression from 'browser-image-compression';
import ImageDialogUploadIcon from '../../common/icons/ImageDialogUploadIcon.vue';

export default {
  name: 'ImageDialogUploadArea',

  components: {
    vueDropzone: vue2Dropzone,
    ImageDialogUploadIcon,
  },

  data() {
    return {
      dropzoneOptions: {
        url: '/',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        previewsContainer: false,
        clickable: '.mg-image-dialog__upload-drop-area',
        accept: this.onUploadSuccess,
        acceptedFiles: 'image/*',
      },
    };
  },

  computed: {
    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
  },

  methods: {
    async onUploadSuccess(file) {
      const options = {
        maxSizeMB: process.env.VUE_APP_MAX_IMAGE_SIZE,
        maxWidthOrHeight: process.env.VUE_APP_MAX_IMAGE_RESOLUTION,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();
      reader.onload = async event => {
        let imageData;
        let imageSize;
        if (this.isTemplateGenerationMode) {
          const initialImageData = event.target.result;
          const updatedImageInfo = await this.getImageSize(initialImageData);
          imageData = updatedImageInfo.data;
          imageSize = { width: updatedImageInfo.width, height: updatedImageInfo.height };
        } else {
          imageData = event.target.result;
          imageSize = await this.getImageSize(imageData);
        }
        this.$emit('onUploadFinish', { imageData, imageSize });
      };
      reader.readAsDataURL(compressedFile);
    },

    getImageSize(imageData) {
      const img = new Image();
      img.src = imageData;
      return new Promise(resolve => {
        img.onload = () => {
          let updatedImageData;
          let updatedImageHeight;
          let updateImageWidth;
          if (this.isTemplateGenerationMode) {
            const originalRatio = img.width / img.height;
            const drawingAreaSize = this.$store.getters['canvas/common/getDrawingAreaSize'];
            updatedImageHeight = drawingAreaSize.height;
            updateImageWidth = updatedImageHeight * originalRatio;
            updatedImageData = this.generateBase64ImageBySize(
              img,
              updateImageWidth,
              updatedImageHeight,
            );
          }

          resolve({
            data: this.isTemplateGenerationMode ? updatedImageData : null,
            width: this.isTemplateGenerationMode ? updateImageWidth : img.width,
            height: this.isTemplateGenerationMode ? updatedImageHeight : img.height,
          });
        };
      });
    },

    generateBase64ImageBySize(img, width, height) {
      // create an off-screen canvas
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // set its dimension to target size
      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx.drawImage(img, 0, 0, width, height);

      // encode image to data-uri with base64 version of compressed image
      return canvas.toDataURL();
    },
  },
};
</script>
