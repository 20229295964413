<template>
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.1875 2.3026C18.5042 2.60234 17.7761 2.80102 17.017 2.89749C17.7979 2.43123 18.3939 1.69852 18.6741 0.815375C17.946 1.24948 17.1421 1.55612 16.2854 1.72723C15.594 0.991086 14.6087 0.535156 13.5337 0.535156C11.4482 0.535156 9.76916 2.22795 9.76916 4.30318C9.76916 4.60177 9.79443 4.88888 9.85645 5.16221C6.72466 5.00947 3.95348 3.50846 2.09186 1.22192C1.76685 1.7858 1.57621 2.43123 1.57621 3.12603C1.57621 4.43066 2.24805 5.58713 3.24948 6.25667C2.64426 6.24519 2.05052 6.06948 1.5475 5.7927C1.5475 5.80419 1.5475 5.81912 1.5475 5.83405C1.5475 7.66466 2.85327 9.18519 4.56559 9.53546C4.25896 9.6193 3.92477 9.65949 3.57794 9.65949C3.33677 9.65949 3.0933 9.64571 2.86476 9.59518C3.35284 11.087 4.73786 12.1838 6.38472 12.2194C5.10306 13.2219 3.47573 13.826 1.71402 13.826C1.40509 13.826 1.1088 13.8122 0.8125 13.7743C2.48118 14.8504 4.45879 15.4648 6.59144 15.4648C13.5234 15.4648 17.3132 9.72266 17.3132 4.74533C17.3132 4.5788 17.3075 4.41802 17.2995 4.25839C18.0471 3.72781 18.6753 3.06516 19.1875 2.3026Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'TwitterIcon'
};
</script>
