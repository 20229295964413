<template>
  <div :class="`mg-top-toolbar ${isTemplateGenerationMode ? 'mg-top-toolbar-tg-mode' : ''}`">
    <div class="mg-top-toolbar__item">
      <ButtonGradientOutline
        text-content="Cancel"
        gradient-outline-style="primary-purple-blue"
        :clickHandler="onCancel"
      />
    </div>

    <div class="mg-drawing-toolbar__item">
      <ButtonGradientBackground
        text-content="DONE BRUSHING"
        :additional-basic-styles="{
          fontSize: '12px',
          fontWeight: '500',
        }"
        :clickHandler="onApply"
      />
    </div>
  </div>
</template>

<script>
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';

export default {
  name: 'DrawToolbar',

  components: { ButtonGradientBackground, ButtonGradientOutline },

  computed: {
    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
  },

  methods: {
    onCancel() {
      this.$emit('onCancel');
    },

    onApply() {
      this.$store.dispatch('canvas/elements/setIsBrushing', true);
      this.$emit('onApply');
      setTimeout(() => {
        this.$store.dispatch('canvas/elements/setIsBrushing', false);
      }, 1000);
    },
  },
};
</script>
