<template>
  <infinite-loading
    @infinite="$emit('endScrolling', $event)"
    :distance="distance"
    class="mg-custom-infinite-scroll__container"
  >
    <div slot="spinner" class="mg-custom-infinite-scroll__loader">
      <b-spinner :variant="bootstrapTheme" />
    </div>
    <div slot="no-more" class="mg-custom-infinite-scroll__text">
      {{ noMoreText }}
    </div>
    <div slot="no-results" class="mg-custom-infinite-scroll__text">
      {{ noMoreText }}
    </div>
  </infinite-loading >
</template>

<script>
export default {
  name: 'CustomInfiniteScroll',

  props: {
    distance: {
      type: Number,
      default: 10
    },
    bootstrapTheme: {
      type: String,
      default: 'light'
    },
    noMoreText: {
      type: String,
      default: 'No More'
    }
  },
};
</script>
