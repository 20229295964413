<template>
  <div v-show="hasMentions" class="user-list-for-tag" ref="userList">
    <ul v-show="!mentionsLoading">
      <li v-for="u in mentionedUsers" :key="`mention-${u.username}`">
        <button
          type="button"
          @click.stop="addMentionedUser(u)"
        >
          <img :src="u.avatar" alt=""> {{ u.username }}
        </button>
      </li>

      <li v-if="!mentionedUsers.length" class="p-3">
        No users found.
      </li>
    </ul>

    <div v-show="mentionsLoading" class="text-center px-3">
      <b-spinner variant="light" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import getCaretPosition from '../../common/getCaretPosition';
import UsersAPI from '../../api/usersApi';

export default {
  name: 'UserMentionList',
  props: {
    input: {
      type: String,
      required: true
    },
    // DOM object of the input you want to attach to
    element: {
      required: true
    }
  },

  data () {
    return {
      mentionedUsers: [],
      hasMentions: false,
      mentionsLoading: false,
    };
  },

  watch: {
    input: debounce(function () {
      this.checkAndLoadMentionedUsers();
    }, 300)
  },

  methods: {
    async checkAndLoadMentionedUsers() {
      if (this.mentionsLoading) {
        return;
      }

      let lastWord = this.input.split(' ');
      lastWord = lastWord.length ? lastWord[lastWord.length - 1] : '';
      const pattern = /(@\w+)+/;
      const matches = lastWord.match(pattern);

      if (!matches || !matches.length) {
        this.hasMentions = false;
        return;
      }
      const query = matches[0].substr(1);
      if (query.length < 2) {
        return;
      }

      const pos = getCaretPosition(this.element, this.element.selectionEnd);

      this.$refs.userList.style.left = `${pos.x}px`;
      const topOffset = (this.input.split(/\r*\n/).length) * 55;
      this.$refs.userList.style.top = `${topOffset}px`;

      this.hasMentions = true;
      this.mentionsLoading = true;

      this.mentionedUsers = await UsersAPI.findUsers(query);
      this.mentionsLoading = false;
    },
    addMentionedUser(u) {
      this.$emit('userClicked', u);
      this.hasMentions = false;
      this.mentionsLoading = false;
    }
  }
};
</script>
