<template>
  <div>
    <backdrop :clicked="closeCropModal" />
    <div v-if="isCropModalOpen" class="crop_modal_page">
      <div class="mg-image-dialog__confirm-modal__buttons-container">
        <CloseModalButton :click-handler="closeCropModal" />
        <div class="content" v-if="element.url">
          <section class="cropper-area">
            <div class="img-cropper">
              <vue-cropper
                ref="cropper"
                :src="element.url"
                preview=".preview"
                class="vue-cropper"
              />
            </div>
          </section>
          <section class="preview-area">
            <p>Preview</p>
            <div class="preview" />
          </section>
        </div>
      </div>
      <div class="crop_buttons">
        <ButtonGradientBackground
          text-content="Apply"
          :click-handler="applyCrop"
          :additional-basic-styles="{
            width: '250px',
            height: '40px',
            background: '#27CAFE',
            fontSize: '14px',
          }"
        />

        <ButtonGradientOutline
          text-content="Cancel"
          :click-handler="closeCropModal"
          gradient-outline-style="thirdly"
          :additional-basic-styles="{
            width: '255px',
            height: '48px',
          }"
        />
      </div>
    </div>
    <div v-else class="error"><p>You should to choose an image</p></div>
  </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { v4 as uuidv4 } from 'uuid';
import CloseModalButton from '../../common/CloseModalButton.vue';
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';
import ImageElements from '../canvas/elements/ImageElements';
import Elements from '../canvas/elements/Elements';
import CanvasFunctions from '../canvas/CanvasFunctions';
import ImageDialogUploadAreaVue from './ImageDialogUploadArea.vue';
import Backdrop from './Backdrop.vue';

export default {
  name: 'ImageCropModal',

  components: {
    CloseModalButton,
    ButtonGradientBackground,
    ButtonGradientOutline,
    VueCropper,
    Backdrop,
  },
  mixins: [ImageElements, Elements, CanvasFunctions, ImageDialogUploadAreaVue],
  computed: {
    isCropModalOpen: {
      get() {
        return this.$store.getters['ui/getCropModalOpen'];
      },
      set() {
        this.$store.dispatch('ui/setCropModalOpen', false);
      },
    },
    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement'];
    },
  },
  data() {
    return {
      cropImg: '',
      data: null,
      element: {},
    };
  },
  mounted() {
    this.element = this.activeElement;
  },

  methods: {
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    setActiveTab(name) {
      this.activeTab = name;
    },
    closeCropModal() {
      this.$store.dispatch('ui/setCropModalOpen', false);
    },
    async applyCrop() {
      this.$store.dispatch('canvas/elements/setCropping', true);
      this.cropImage();
      const newId = uuidv4();
      this.$store.dispatch('ui/setCropModalOpen', false);
      this.$store.dispatch('canvas/elements/setIsFirstMainImageRender', true);
      if (this.element.type === 'image') {
        if (this.element.main) {
          await this.$store.dispatch('canvas/elements/addMainImage', {
            id: newId,
            imageData: this.cropImg,
          });
          const { width, height } = await this.getImageSize(this.cropImg);
          await this.$store.dispatch('canvas/common/setInitialUploadedMainImageRatio', {
            width,
            height,
          });
        } else {
          this.$store.dispatch('canvas/elements/deleteElement', this.element.id);
          this.$store.dispatch('canvas/elements/addImageElement', {
            id: newId,
            main: this.element.main,
            imageData: this.cropImg,
          });
        }
      }
      // prevent resizing background
      setTimeout(() => {
        this.$store.dispatch('canvas/history/saveHistory');
        this.$store.dispatch('canvas/elements/setIsFirstMainImageRender', false);
        this.$store.dispatch('canvas/elements/setCropping', false);
      }, 200);
    },
  },
};
</script>
<style scoped>
.image-crop-backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.error {
  width: 1000px;
  height: 1000px;
  background: chartreuse;
  z-index: 1000;
}
.cropper {
  height: 500px;
  background: #ddd;
}
.crop-main-content {
  color: white;
  display: flex;
  flex-direction: column;
}
.crop_modal_page {
  position: fixed;
  z-index: 500;
  background-color: #323232;
  width: 70%;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  height: 80%;
  border-radius: 10px;
}
.crop_main_content_image {
  width: 80%;
}
.crop-item-elements {
  display: flex;
  flex-direction: row;
}
.crop-input-type {
  background: linear-gradient(
    93.79deg,
    rgb(40, 203, 255) 37.36%,
    rgb(58, 209, 146) 84.4%,
    rgb(18, 159, 100) 107.5%
  );
}
.crop_image_container {
  width: 800px;
  height: 500px;
}
.destination_container {
  width: 400px;
  height: 400px;
  overflow: hidden;
}
.cropped_image_destination {
  min-width: 400px;
  min-height: 400px;
}
.crop_images_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.crop_buttons {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  bottom: 2%;
  vertical-align: bottom;
  width: 100%;
  justify-content: space-around;
  margin-left: -10px;
}

/* Adding */

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
}

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

.content {
  display: flex;
  justify-content: space-around;
}
/* 
.cropper-area {
  width: 80%;
  height: 80%;
} */

.preview-area {
  margin-left: 10px;
  width: 300px;
}

.preview-area p {
  color: white;
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 5px;
  text-align: center;
}

.preview-area p:last-of-type {
  margin-top: 5px;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
  margin: 0 auto;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
.img-cropper {
  min-width: 500px;
  /* min-height: 500px; */
  max-height: 800px;
  max-width: 800px;
}
.vue-cropper {
  min-height: 400px;
  max-height: 500px;
}
@media (max-width: 1400px) {
  .content {
    flex-direction: column;
    align-items: center;
  }
  .crop_modal_page {
    z-index: 500;
    width: 85%;
    left: 7.5%;
    top: 5%;
    height: 90%;
  }
  .preview-area {
    margin-left: 0px;
  }
  .crop_buttons {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (max-width: 600px) {
  .img-cropper {
    min-width: 200px;
    min-height: 200px;

    max-width: 400px;
  }
  .crop_modal_page {
    z-index: 500;
    width: 100%;
    left: 0%;
    top: 0%;
    height: 100%;
  }
  .preview-area {
    margin-left: 0px;
  }
  .crop_buttons {
    flex-direction: column-reverse;
    align-items: center;
  }

  @media (max-width: 540px) {
    .vue-cropper {
      min-height: 200px;
      max-height: 300px;
    }
    .img-cropper {
      min-width: 200px;
      min-height: 200px;
      max-height: 300px;
      max-width: 300px;
    }
    .content {
      margin-top: 70px;
      height: 400px;
    }
    .crop_modal_page {
      z-index: 500;
      width: 100%;
      left: 0%;
      top: 0%;
      height: 100%;
    }
    .preview-area {
      margin-left: 0px;
    }
    .crop_buttons {
      flex-direction: column-reverse;
      align-items: center;
      height: 60px;
    }
  }
  @media (max-width: 360px) {
    .crop_buttons {
      height: 45px;
    }
    .content {
      margin-top: 70px;
    }
  }
}
</style>
