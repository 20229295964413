<template>
  <div class="mg-templates-sidebar-container">
    <CustomSearchInput
      input-type="search"
      placeholder="Search Meme Templates"
      :additional-input-styles="{
        width: '100%',
      }"
      ref="searchInput"
      @inputValue="onSearch"
    />

    <div class="mg-templates-sidebar__items-container" ref="listContainer">
      <div
        class="mg-templates-list"
        :class="flexList"
        :style="{ height: this.listHeight ? `${this.listHeight}px` : '100%' }"
        v-infinite-scroll="loadMore"
        infinite-scroll-disabled="busy"
        infinite-scroll-distance="20"
      >
        <div
          v-for="template in templatesData"
          :key="`template-${type}-${template.id}`"
          class="mg-templates-list__item"
          v-on:click="selectTemplate(template)"
        >
          <img
            class="mg-templates-list__item-img"
            :src="template.example_image_url"
            alt="template"
          />
        </div>

        <div
          v-if="templatesData.length === 0 && !isTemplatesPending"
          class="mg-templates-list__empty-message"
        >
          There are no templates.
        </div>

        <div
          v-if="isTemplatesPending"
          class="mg-custom-infinite-scroll__loader mg-custom-infinite-scroll__loader--templates"
        >
          <b-spinner variant="light" />
        </div>
      </div>
    </div>

    <b-modal
      v-model="isConfirmationOpen"
      hide-header
      hide-footer
      size="sm"
      centered
      dialog-class="mg-image-dialog"
      body-class="mg-image-dialog__confirm-modal"
    >
      <div class="mg-image-dialog__confirm-modal__buttons-container">
        <CloseModalButton :click-handler="closeConfirmationModal" />

        <div class="mg-image-dialog__confirm-text">
          <p>
            Are you sure you'd like to use a Template? It will erase your changes and replace them.
          </p>
          <p>Proceed with Template / Cancel</p>
        </div>

        <ButtonGradientBackground
          text-content="Apply"
          :click-handler="applyTemplate"
          :additional-basic-styles="{
            width: '250px',
            height: '40px',
            background: '#27CAFE',
            fontSize: '14px',
          }"
        />

        <ButtonGradientOutline
          text-content="Cancel"
          :click-handler="closeConfirmationModal"
          gradient-outline-style="thirdly"
          :additional-basic-styles="{
            width: '255px',
            height: '48px',
          }"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import CustomSearchInput from '../../common/CustomSearchInput.vue';
import CloseModalButton from '../../common/CloseModalButton.vue';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';
// import fake from '../../../store/modules/fake-json.json';

export default {
  name: 'TemplatesSidebar',

  components: {
    CustomSearchInput,
    CloseModalButton,
    ButtonGradientOutline,
    ButtonGradientBackground,
  },

  props: {
    type: {
      required: false,
      type: String,
      default: 'sidebarTemplates',
    },
  },

  data() {
    return {
      isConfirmationOpen: false,
      busy: false,
      listHeight: 0,
      search: '',
      template: null,
    };
  },

  computed: {
    isTemplatesPending() {
      return this.$store.getters['images/isRecordsPendingByType'](this.type);
    },

    templatesData() {
      return this.$store.getters['images/getRecordsData'](this.type);
    },

    templatesCurrentPage() {
      return this.$store.getters['images/getRecordsCurrentPage'](this.type);
    },

    templatesLastPage() {
      return this.$store.getters['images/getRecordsLastPage'](this.type);
    },

    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    flexList() {
      if (this.isDesktop && this.templatesData.length < 4) {
        return 'mg-templates-list--flex-start';
      }

      return '';
    },

    elementsCount() {
      return this.$store.getters['canvas/elements/getElementsCount'];
    },
  },
  created() {
    this.$store.dispatch('images/resetRecordsByType', this.type);
    this.$store.dispatch('images/getRecordsByType', {
      type: this.type,
      page: 1,
      options: { search: '' },
    });
  },
  mounted() {
    const isSidebar = this.type === 'sidebarTemplates';
    let $sidebar = this.$el.closest('.mg-sidebar-container');
    if (this.$refs.listContainer) {
      const cssListContainer = window.getComputedStyle(this.$refs.listContainer);

      if (isSidebar && !this.isDesktop && $sidebar && cssListContainer) {
        setTimeout(() => {
          const cssSidebar = window.getComputedStyle($sidebar);
          const sidebarPaddingTop = parseFloat(cssSidebar.getPropertyValue('padding-top'));

          const $sidebarHeader = $sidebar.querySelector('.mg-sidebar-header-container');
          const sidebarHeaderHeight = $sidebarHeader.clientHeight;
          const searchInputHeight = this.$refs.searchInput.$el.clientHeight;
          const listContainerMarginTop = parseFloat(
            cssListContainer.getPropertyValue('margin-top'),
          );

          this.listHeight =
            $sidebar.clientHeight -
            sidebarPaddingTop -
            sidebarHeaderHeight -
            searchInputHeight -
            listContainerMarginTop;
        }, 300);

        return true;
      }

      $sidebar = this.$el.closest('.mg-sidebar-content-container');
      if (isSidebar && $sidebar) {
        const $sidebarHeader = $sidebar.querySelector('.mg-sidebar-header-container');
        const sidebarHeaderHeight = $sidebarHeader.clientHeight;
        const searchInputHeight = this.$refs.searchInput.$el.clientHeight;
        const listContainerMarginTop = parseFloat(cssListContainer.getPropertyValue('margin-top'));

        this.listHeight =
          $sidebar.clientHeight - sidebarHeaderHeight - searchInputHeight - listContainerMarginTop;
      } else {
        // image dialog
        const $modalBody = this.$el.closest('.mg-image-dialog__main-modal');
        const cssEl = window.getComputedStyle(this.$el);
        if ($modalBody && cssEl) {
          const cssModalBody = window.getComputedStyle($modalBody);
          const modalBodyPaddingTop = parseFloat(cssModalBody.getPropertyValue('padding-top'));
          const modalBodyPaddingBottom = parseFloat(
            cssModalBody.getPropertyValue('padding-bottom'),
          );

          const $tabs = $modalBody.querySelector('.mg-image-dialog__tabs-container');
          const tabsHeight = $tabs.clientHeight;

          const elPaddingTop = parseFloat(cssEl.getPropertyValue('padding-top'));

          const searchInputHeight = this.$refs.searchInput.$el.clientHeight;

          const listContainerPaddingTop = parseFloat(
            cssListContainer.getPropertyValue('padding-top'),
          );
          const listContainerMarginTop = parseFloat(
            cssListContainer.getPropertyValue('margin-top'),
          );

          this.listHeight =
            $modalBody.clientHeight -
            modalBodyPaddingTop -
            modalBodyPaddingBottom -
            tabsHeight -
            elPaddingTop -
            searchInputHeight -
            listContainerPaddingTop -
            listContainerMarginTop;
        }
      }
    }
    return true;
  },

  methods: {
    getImageSizes(url) {
      const img = new Image();
      img.src = url;
      return new Promise(resolve => {
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
        };
      });
    },
    async selectTemplate(template) {
      const newTemplate = { ...template };
      delete newTemplate.data;
      const data = JSON.parse(template.data);
      this.template = { ...newTemplate, data };
      if (this.elementsCount > 0) {
        this.openConfirmationModal();
      } else {
        this.$store.dispatch('canvas/common/clearCanvas');
        this.applyTemplate();
      }
    },

    applyTemplate() {
      if (!this.isDesktop) {
        this.$store.dispatch('ui/closeMobileSidebarModal', false);
      }
      this.$store.dispatch('canvas/common/clearCanvas');
      if (this.template.description) {
        this.$store.dispatch('canvas/history/resetHistoryAction');
        this.$store.dispatch('meme/restoreCanvasFromMemeData', { memeData: this.template.data }); // this.template.data
        if (this.$route.path !== '/editor') {
          this.$router.push('/editor');
        }
      } else {
        console.error('There is no image in template');
      }

      this.$emit('templateAdded');
      this.closeConfirmationModal();
    },

    onSearch: debounce(function(value) {
      this.search = value;
      this.$store.dispatch('images/resetRecordsByType', this.type);
      this.$store.dispatch('images/getRecordsByType', {
        type: this.type,
        page: 1,
        options: { search: value },
      });
    }, 300),

    loadMore() {
      if (this.templatesCurrentPage < this.templatesLastPage) {
        this.busy = true;
        this.$store
          .dispatch('images/getRecordsByType', {
            type: this.type,
            page: this.templatesCurrentPage + 1,
            options: { search: this.search },
          })
          .then(() => {
            this.busy = false;
          });
      }
    },

    openConfirmationModal() {
      this.isConfirmationOpen = true;
    },

    closeConfirmationModal() {
      this.template = null;
      this.isConfirmationOpen = false;
    },
  },
};
</script>
