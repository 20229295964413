<template>
  <div class="mg-custom-slider-container">
    <div class="mg-custom-slider__rail">
      <vue-slider
        v-model="outputValue"
        v-bind="styleOptions"
        :min="minValue"
        :max="maxValue"
        :interval="interval"
        @change="onChange"/>
    </div>
    <div class="mg-custom-slider__input" v-if="showInput">
      <input
        ref="customSliderInput"
        type="number"
        v-model="outputValue"
        :min="minValue"
        :max="maxValue"
        :step="interval"
        @input="onChange">
    </div>
    <div class="mg-customs-slider__units">
      <span v-if="showUnits">
        <span v-if="unitVariability">
          <select v-model="unitTypeSelectModel" @change="changeUnitType">
            <option value="px">px</option>
            <option value="%">%</option>
          </select>
        </span>
        <span v-else>{{unitsType}}</span>
      </span>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'CustomSlider',

  components: {
    VueSlider,
  },

  props: {
    inputValue: {
      type: Number,
      required: true,
      default: 0,
    },
    minValue: {
      type: Number,
      required: false,
      default: 0
    },
    maxValue: {
      type: Number,
      required: false,
      default: 100
    },
    interval: {
      type: Number,
      required: false,
      default: 1
    },
    showInput: {
      type: Boolean,
      required: false,
      default: true
    },
    showUnits: {
      type: Boolean,
      required: false,
      default: true
    },
    unitsType: {
      type: String,
      required: false,
      default: 'px'
    },
    unitVariability: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    sideName: {
      type: String,
      required: false,
    }
  },

  data() {
    return {
      outputValue: this.inputValue,
      unitTypeSelectModel: this.unitsType,
      styleOptions: {
        railStyle: {
          'background-color': '#4F4F4F',
        },
        processStyle: {
          'background-color': '#62CAFA',
        },
        dotStyle: {
          background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
          width: '13px',
          height: '13px',
        },
        tooltip: 'none',

      },
    };
  },

  watch: {
    inputValue(newValue) {
      this.outputValue = newValue;
    },
    outputValue(newValue) {
      if (+newValue > this.maxValue) {
        this.outputValue = this.maxValue;
      } else if (+newValue < this.minValue) {
        this.outputValue = this.minValue;
      }
    },
    unitsType(newValue) {
      this.unitTypeSelectModel = newValue;
    }
  },

  methods: {
    onChange(newValue) {
      const payload = typeof newValue === 'number' ? newValue : +newValue.target.value;
      if (payload <= this.maxValue && payload >= this.minValue) {
        this.$emit('valueChanged', payload);
      }
    },

    changeUnitType() {
      const currentSize = this.$store.getters['canvas/frames/getSideSize'](this.sideName);
      const newSize = typeof currentSize === 'number' ? `${currentSize}%` : parseInt(currentSize, 10);
      this.$store.dispatch('canvas/frames/setSideSize', { side: this.sideName, size: newSize });
      this.$refs.customSliderInput.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }
};
</script>
