<template>
  <div class="mg-saved-sidebar-container">
    <p class="mg-saved-sidebar__description">
      <span v-if="isLoggedIn"
        >These are memes that you've previously saved. Click to edit or re-use.</span
      >
      <span v-else
        >To save memes and use them again later,
        <span class="mg-saved-link-underline"
          ><span class="mg-saved-link-modal" v-on:click="onLoginClick"
            >login or create an account</span
          ></span
        ></span
      >
    </p>

    <div v-if="isMemesPending && !memesCurrentPage">
      <b-spinner variant="light" />
    </div>

    <div class="mg-memes-list-container">
      <div class="mg-memes-list" ref="memesList">
        <div
          v-for="meme in memesData"
          :key="`meme-${meme.id}`"
          class="mg-memes-list__item"
          v-on:click="selectMeme(meme.id)"
        >
          <img class="mg-memes-list__item-img" :src="meme.image_url" alt="meme" />
        </div>
      </div>

      <CustomInfiniteScroll
        v-if="memesCurrentPage"
        :distance="0"
        no-more-text="No more memes"
        @endScrolling="loadMore"
      />
    </div>
  </div>
</template>

<script>
import CustomInfiniteScroll from '../../common/CustomInfiniteScroll.vue';
import { SIDEBARS } from '../../../common/constants';

export default {
  name: 'SavedSidebar',

  components: {
    CustomInfiniteScroll,
  },

  computed: {
    isMemesPending() {
      return this.$store.getters['meme/isMemesPending'];
    },

    memesData() {
      return this.$store.getters['meme/getMemesData'];
    },

    memesCurrentPage() {
      return this.$store.getters['meme/getMemesCurrentPage'];
    },

    memesLastPage() {
      return this.$store.getters['meme/getMemesLastPage'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/getIsLoggedIn'];
    },
  },

  created() {
    this.$store.dispatch('meme/getSavedMemes', 1);
  },

  beforeDestroy() {
    this.$store.dispatch('meme/resetMemesPagination');
  },

  methods: {
    onLoginClick() {
      this.$store.dispatch('ui/openLoginModal');
    },
    selectMeme(memeId) {
      const isDesktop = this.$store.getters['ui/isDesktop'];
      this.$store.dispatch('canvas/common/clearCanvas');
      this.$store.dispatch('ui/setActiveSidebar', isDesktop ? SIDEBARS.LAYOUT : null);
      this.$store.dispatch('meme/loadMemeCanvas', {
        memeId,
        isClone: true,
      });

      if (this.$route.path !== '/editor') {
        this.$router.push('/editor');
      }
    },

    async loadMore($state) {
      let currentScrollPosition = null;
      if (this.$refs.memesList) {
        currentScrollPosition = this.$refs.memesList.scrollTop;
      }

      if (this.memesCurrentPage >= this.memesLastPage) {
        $state.complete();
        return;
      }

      await this.$store.dispatch('meme/getSavedMemes', this.memesCurrentPage + 1);

      if (this.$refs.memesList) {
        this.$refs.memesList.scrollTop = currentScrollPosition;
      }

      $state.loaded();
    },
  },
};
</script>
