<template>
  <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.8 8.38658C24.8933 3.78658 20.8533 0.333252 16 0.333252C12.1467 0.333252 8.8 2.51992 7.13333 5.71992C3.12 6.14658 0 9.54659 0 13.6666C0 18.0799 3.58667 21.6666 8 21.6666H25.3333C29.0133 21.6666 32 18.6799 32 14.9999C32 11.4799 29.2667 8.62659 25.8 8.38658ZM25.3333 18.9999H8C5.05333 18.9999 2.66667 16.6133 2.66667 13.6666C2.66667 10.9333 4.70667 8.65325 7.41333 8.37325L8.84 8.22658L9.50667 6.95992C10.7733 4.51992 13.2533 2.99992 16 2.99992C19.4933 2.99992 22.5067 5.47992 23.1867 8.90658L23.5867 10.9066L25.6267 11.0533C27.7067 11.1866 29.3333 12.9333 29.3333 14.9999C29.3333 17.1999 27.5333 18.9999 25.3333 18.9999ZM10.6667 12.3333H14.0667V16.3333H17.9333V12.3333H21.3333L16 6.99992L10.6667 12.3333Z" fill="#BDBDBD"/>
  </svg>
</template>

<script>
export default {
  name: 'ImageDialogUploadIcon'
};
</script>
