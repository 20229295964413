<template>
  <div v-if="!isButtonHidden" class="mg-frame-sidebar__aspect-ratio-item">
    <ButtonRoundGradientOutline
      :gradient-outline-style="isActiveRatio ? 'rounded--active' : 'rounded'"
      :click-handler="onItemClick"
      :specificContentComponentName="specificContentComponentName"
    />
    <span :class="`mg-frame-sidebar__aspect-ratio-item__title${isActiveRatio ? '--active' : ''}`">{{ratio}}</span>
  </div>
</template>

<script>
import ButtonRoundGradientOutline from '../../common/ButtonRoundGradientOutline.vue';

export default {
  name: 'FrameSidebarAspectRatioItem',

  components: { ButtonRoundGradientOutline },

  props: {
    ratio: String,
    activeRatio: String
  },

  computed: {
    isActiveRatio () {
      return this.ratio === this.activeRatio;
    },

    isButtonHidden () {
      return this.ratio === 'original' && !this.$store.getters['canvas/elements/getMainImageId'];
    },

    specificContentComponentName () {
      // function generates the component name of the aspect ratio icon
      // example:
      // ratio = '4:3', firstValue = '4', secondValue = '3', return 'Icon4To3'
      if (this.ratio === 'original') return null;
      const firstValue = this.ratio.match(/^.*?(?=:)/);
      const secondValue = this.ratio.match(/[^:]*$/);
      return `Icon${firstValue}To${secondValue}`;
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    }
  },

  methods: {
    onItemClick() {
      this.$emit('itemClicked', this.ratio);
    }
  }
};
</script>
