import axios from 'axios';

const getApiUrl = function () {
  return process.env.VUE_APP_API_URL;
};

export const apiUrl = getApiUrl();

const queryInstance = axios.create({
  baseURL: `${apiUrl}/api`,
  withCredentials: true
});

export const createErrorHandler = function (errorCallback) {
  queryInstance.interceptors.response.use(
    (response) => response.data,
    (error) => errorCallback(error)
  );
};

export const queryGet = function (url, config = {}) {
  return queryInstance.get(url, config);
};

export const queryPost = function (url, data = null, config = {}) {
  return queryInstance.post(url, data, config);
};

export const queryDelete = function (url, data = null, config = {}) {
  return queryInstance.delete(url, data, config);
};

export const queryPut = function (url, data = null, config = {}) {
  return queryInstance.put(url, data, config);
};
