<template>
  <div>
    <div class="mg-post-meme-loader" v-if="isPending">
      <b-spinner variant="light" />
    </div>

    <div class="mg-post-meme-container" v-else>
      <section class="mg-post-meme-container__meme-section">
        <FinalMemePreview :image-url="imageUrl" />
      </section>

      <section class="mg-post-meme-container__controls-section">
        <div class="mg-post-meme-container__tools-block">
          <div class="mg-post-meme-container__tools-block__buttons-container">
            <ButtonGradientBackground
              text-content="Post to Memehead"
              :additional-basic-styles="{
                width: isDesktop ? '302px' : '100%',
                height: '58px',
                background: '#27CAFE',
                fontSize: '22px',
              }"
              :click-handler="onPostToMemeheadClick"
            />

            <ButtonGradientOutline
              v-if="isDesktop"
              text-content="Hidden"
              gradient-outline-style="primary-purple-blue"
              :additional-basic-styles="{
                width: '306px',
                height: '64px',
                fontSize: '22px',
              }"
              :click-handler="onHiddenClick"
            />
          </div>

          <div class="mg-post-meme-container__tools-block__tools-container">
            <h2>Tools</h2>
            <div class="mg-post-meme-container__tools-block__tools">
              <div class="mg-post-meme-container__tools-block__tools-item" v-on:click="onDownload">
                <DownloadIcon />
                <span>Download</span>
              </div>
              <router-link
                :to="`/editor/${memeId}`"
                class="mg-post-meme-container__tools-block__tools-item"
              >
                <EditIcon />
                <span>Edit</span>
              </router-link>
              <router-link to="/start" class="mg-post-meme-container__tools-block__tools-item">
                <MakeAnotherMemeIcon />
                <span>Make Another Meme</span>
              </router-link>
            </div>
          </div>

          <div class="mg-post-meme-container__share-block" v-if="!isDesktop">
            <h2>Share</h2>

            <div class="mg-post-meme-container__buttons-container">
              <div class="mg-post-meme-container__share-buttons-container">
                <ShareButtons />
              </div>

              <ButtonGradientBackground
                text-content="copy link"
                :additional-basic-styles="{
                  width: '100%',
                  height: '42px',
                  background: '#333333',
                  fontSize: '12px',
                }"
                icon-name="copy-link-icon"
                :additional-icon-styles="{
                  marginRight: '7px',
                }"
              />
            </div>
          </div>
        </div>
      </section>
    </div>

    <ShareDialog v-if="isDesktop" />
  </div>
</template>

<script>
import MakeAnotherMemeIcon from '../components/common/icons/post-meme-tools/MakeAnotherMemeIcon.vue';
import EditIcon from '../components/common/icons/post-meme-tools/EditIcon.vue';
import DownloadIcon from '../components/common/icons/post-meme-tools/DownloadIcon.vue';
import ButtonGradientBackground from '../components/common/ButtonGradientBackground.vue';
import ButtonGradientOutline from '../components/common/ButtonGradientOutline.vue';
import FinalMemePreview from '../components/post-meme/FinalMemePreview.vue';
import ShareDialog from '../components/post-meme/ShareDialog.vue';
import ShareButtons from '../components/post-meme/ShareButtons.vue';

export default {
  name: 'PostMemeTools',

  components: {
    FinalMemePreview,
    MakeAnotherMemeIcon,
    EditIcon,
    DownloadIcon,
    ButtonGradientBackground,
    ButtonGradientOutline,
    ShareDialog,
    ShareButtons,
  },

  computed: {
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    isLoggedIn() {
      return this.$store.getters['auth/getIsLoggedIn'];
    },

    memeId() {
      return this.$route.params.memeId;
    },

    isMemeDataEmpty() {
      return this.$store.getters['meme/isMemeDataEmpty'];
    },

    imageUrl() {
      return this.$store.getters['meme/getMemeImageUrl'];
    },

    isPending() {
      return this.$store.getters['meme/getIsPending'];
    },
  },

  created() {
    if (this.isMemeDataEmpty) {
      this.$store.dispatch('meme/loadMeme', this.memeId);
    }
  },

  methods: {
    onPostToMemeheadClick() {
      if (this.isLoggedIn) {
        this.$router.push(`/create-post/${this.memeId}`);
      } else {
        this.$store.dispatch('auth/setRedirectAfterLogin', `/create-post/${this.memeId}`);
        this.$store.dispatch('ui/openLoginModal');
      }
    },

    onHiddenClick() {
      this.$store.dispatch('ui/openShareModal');
    },

    async onDownload() {
      const image = await fetch(this.imageUrl);
      const imageBlob = await image.blob();
      const blob = new Blob([imageBlob], { type: 'image/jpeg' });
      const href = URL.createObjectURL(blob);
      const a = Object.assign(document.createElement('a'), {
        href,
        style: 'display: none',
        download: this.memeId,
      });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(href);
      a.remove();
    },
  },
};
</script>
