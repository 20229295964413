var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mg-main-toolbar__button-container",style:({background: this.dynamicStyles.background})},[_c('ButtonGradientBackground',{attrs:{"text-content":_vm.startCase(_vm.sidebarName),"icon-name":(_vm.sidebarName + "-icon"),"icon-hover-name":(_vm.sidebarName + "-hover-icon"),"icon-active-name":(_vm.sidebarName + "-active-icon"),"icon-position":"top","is-active":_vm.isActiveButton,"additional-basic-styles":{
      background: this.dynamicStyles.background,
      width: '100%',
      height: 'auto',
      fontSize: '14px',
      fontWeight: '400',
      color: this.dynamicStyles.textColor
    },"additional-hover-styles":{
      background: '#242424',
      color: '#27CAFE',
    },"click-handler":_vm.onSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }