<template>
  <div>
    <b-modal
      v-model="isOpen"
      hide-footer
      hide-header
      size="lg"
      centered
      dialog-class="mg-image-dialog"
      body-class="mg-image-dialog__main-modal"
    >
      <CloseModalButton :click-handler="closeMainModal" />

      <div class="mg-image-dialog__tabs-container">
        <ImageDialogTab
          v-for="tabName of tabs"
          :key="tabName"
          :name="tabName"
          :active-tab="activeTab"
          @tabClicked="setActiveTab"
        />
      </div>

      <div class="mg-image-dialog__tab-content-area">
        <UploadTab v-if="activeTab === 'Upload'" @onUploadFinish="onUploadFinish" />
        <StickersTab v-if="activeTab === 'Stickers'" @stickerSelected="addSticker" />
        <EmojisTab v-if="activeTab === 'Emojis'" @emojiSelected="addSticker" />
        <MemesTab v-if="activeTab === 'Memes'" @templateAdded="templateAdded" />
        <RedditTab v-if="activeTab === 'Reddit'" @templateAdded="templateAdded" />
      </div>
    </b-modal>

    <b-modal
      v-model="isConfirmationOpen"
      hide-header
      hide-footer
      size="sm"
      centered
      dialog-class="mg-image-dialog"
      body-class="mg-image-dialog__confirm-modal"
    >
      <div class="mg-image-dialog__confirm-modal__buttons-container">
        <CloseModalButton :click-handler="closeConfirmationModal" />

        <ButtonGradientBackground
          text-content="Replace main background"
          :click-handler="replaceMainBackground"
          :additional-basic-styles="{
            width: '250px',
            height: '40px',
            background: '#27CAFE',
            fontSize: '14px',
          }"
        />

        <ButtonGradientOutline
          text-content="Add Image to Meme"
          :click-handler="addImage"
          gradient-outline-style="thirdly"
          :additional-basic-styles="{
            width: '255px',
            height: '48px',
          }"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { ELEMENT_TYPES } from '../../../common/constants';
import ImageDialogTab from './ImageDialogTabItem.vue';
import UploadTab from './tabs/UploadTab.vue';
import StickersTab from './tabs/stickers/StickersTab.vue';
import EmojisTab from './tabs/EmojisTab.vue';
import MemesTab from './tabs/MemesTab.vue';
import RedditTab from './tabs/RedditTab.vue';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';
import CloseModalButton from '../../common/CloseModalButton.vue';

export default {
  name: 'ImageDialog',

  components: {
    ButtonGradientOutline,
    ButtonGradientBackground,
    ImageDialogTab,
    UploadTab,
    StickersTab,
    EmojisTab,
    MemesTab,
    RedditTab,
    CloseModalButton,
  },

  data() {
    return {
      isConfirmationOpen: false,
      imageData: null,
      imageSize: null,
      tabs: ['Upload', 'Stickers', 'Emojis', 'Memes', 'Reddit'],
      activeTab: 'Upload',
      message: null,
    };
  },

  computed: {
    isOpen: {
      get() {
        return this.$store.getters['ui/isOpenAddImageModal'];
      },

      set() {
        this.$store.dispatch('ui/closeImageModal');
      },
    },

    hasMainImage() {
      return this.$store.getters['canvas/elements/hasMainImage'];
    },
  },

  methods: {
    setActiveTab(name) {
      this.activeTab = name;
    },

    closeMainModal() {
      this.isOpen = false;
      this.setActiveTab('Upload');
    },

    openConfirmationModal() {
      this.isConfirmationOpen = true;
    },

    closeConfirmationModal() {
      this.isConfirmationOpen = false;
    },

    onUploadFinish(payload) {
      this.imageData = payload.imageData;
      this.imageSize = payload.imageSize;
      this.closeMainModal();

      if (!this.hasMainImage) {
        this.replaceMainBackground();
      } else {
        this.openConfirmationModal();
      }
    },

    replaceMainBackground() {
      const id = uuidv4();
      // if user write url path to set a layout we won't delete sidebars
      const queryLayout = this.$store.getters['canvas/layouts/getQueryLayout'];
      if (!queryLayout) {
        this.$store.dispatch('canvas/frames/resetSideSize');
      }

      this.$store.dispatch('canvas/common/setInitialUploadedMainImageRatio', this.imageSize);
      this.$store.dispatch('canvas/elements/setIsFirstMainImageRender', true);
      this.$store.dispatch('canvas/elements/addMainImage', {
        id,
        imageData: this.imageData,
      });
      this.$store.dispatch('canvas/common/setAspectRatio', 'original');
      this.imageData = null;
      this.imageSize = null;
      this.closeConfirmationModal();
      setTimeout(() => {
        this.$store.dispatch('canvas/history/saveHistory');
      }, 100);
    },

    addImage() {
      const id = uuidv4();
      this.$store.dispatch('canvas/elements/addImageElement', {
        id,
        imageData: this.imageData,
      });
      this.imageData = null;
      this.closeConfirmationModal();
    },

    addSticker(sticker) {
      const id = uuidv4();
      this.$store.dispatch('canvas/elements/addImageElement', {
        id,
        imageData: sticker.image_url,
        type: ELEMENT_TYPES.STICKER,
        stickerId: sticker.id,
      });

      this.closeMainModal();
    },

    templateAdded() {
      this.closeMainModal();
    },
  },
};
</script>
