<template>
  <div class="mg-watermark-sidebar-container">
    <div class="mg-watermark-sidebar__default-memehead-watermark-container">
      <div class="mg-watermark-sidebar__checkbox-label">Show Memehead Watermark</div>
      <div class="mg-watermark-sidebar__checkbox" @click="clickWatermarkCheckboxHandler">
        <b-form-checkbox v-model="isWatermarkEnabled" :disabled="!isProAccount" switch size="lg" />
      </div>
    </div>
    <watermark-modal v-if="isModalEnable" :handler="closeUpgradeAccountModal">
      <div class="watermark-upper-text">
        <p>Upgrade to remove watermarks and unlock all Memehead Pro benefits.</p>
        <p>Download the Memehead app now to get started.</p>
      </div>
      <div class="watermark-store-images">
        <a href="#"
          ><img src="../../../assets/images/appleStore.png" class="watermark-each-image"
        /></a>
        <a href="#"
          ><img src="../../../assets/images/googlePlay.png" class="watermark-each-image"
        /></a>
      </div>
      <div class="watermark-bottom-text">
        <span
          >Already have Memehead Pro?
          <span class="mg-saved-link-underline"
            ><span class="mg-saved-link-modal" v-on:click="onLoginClick">Login now</span></span
          ></span
        >
      </div>
      <CloseModalButton :click-handler="closeUpgradeAccountModal" />
    </watermark-modal>
  </div>
</template>

<script>
import CloseModalButton from '../../common/CloseModalButton.vue';
import WatermarkModal from './WatermarkModal.vue';

export default {
  name: 'WatermarkSidebar',

  components: {
    CloseModalButton,
    WatermarkModal,
  },

  computed: {
    isModalEnable: {
      get() {
        return this.$store.getters['canvas/watermark/isModalEnabled'];
      },
      set(value) {
        this.$store.dispatch('canvas/watermark/toggleModal', value);
      },
    },
    isLoggedIn() {
      return this.$store.getters['auth/getIsLoggedIn'];
    },
    isProAccount() {
      const user = this.$store.getters['auth/getUser'];
      return user === undefined || user === null ? 0 : user.is_pro_active;
    },

    isWatermarkEnabled: {
      get() {
        return this.$store.getters['canvas/watermark/isWatermarkEnabled'];
      },
      set(value) {
        this.$store.dispatch('canvas/watermark/toggleWatermark', value);
      },
    },
  },

  data() {
    return {
      isUpgradeAccountModalOpen: false,
    };
  },

  methods: {
    onLoginClick() {
      this.$store.dispatch('ui/openLoginModal');
    },
    clickWatermarkCheckboxHandler() {
      if (!this.isProAccount) {
        this.isModalEnable = true;
      }
    },

    closeUpgradeAccountModal() {
      this.isModalEnable = false;
    },
  },
};
</script>
<style scoped>
.watermark-images {
  display: flex;
  flex-direction: row;
}
.watermark-store-images {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.watermark-each-image {
  margin-left: 2.5%;
  width: 95%;
}
.watermark-upper-text {
  margin-top: 1.4rem;
  text-align: center;
  font-size: 0.9rem;
}
.watermark-bottom-text {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
}
@media (max-width: 800px) {
  .watermark-upper-text {
    margin-top: 0.6rem;
    text-align: center;
    font-size: 0.8rem;
  }
}
@media (max-width: 1100px) {
  .watermark-upper-text {
    margin-top: 0.6rem;
    text-align: center;
    font-size: 1rem;
  }
  .watermark-bottom-text {
    text-align: center;
    margin-top: 1.8rem;
    font-size: 0.9rem;
  }
}
@media (max-width: 500px) {
  .watermark-upper-text {
    margin-top: 1.1rem;
    text-align: center;
    font-size: 0.8rem;
  }
  .watermark-bottom-text {
    text-align: center;
    margin-top: 1.8rem;
    font-size: 0.8rem;
  }
}
@media (max-width: 360px) {
  .watermark-upper-text {
    margin-top: 0.2rem;
    text-align: center;
    font-size: 0.7rem;
  }
  .watermark-bottom-text {
    text-align: center;
    margin-top: 1.5rem;
    font-size: 0.7rem;
  }
}
</style>
