import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import VueHotkey from 'v-hotkey';
import InfiniteLoading from 'vue-infinite-loading';
import VueEvents from 'vue-plugin-events';
import Vuelidate from 'vuelidate';
import infiniteScroll from 'vue-infinite-scroll';
import VueTextareaAutosize from 'vue-textarea-autosize';
import VueClipboard from 'vue-clipboard2';
import GAuth from 'vue-google-oauth2';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(VueHotkey);
Vue.use(InfiniteLoading, {
  system: {
    throttleLimit: 100,
  },
});
const token = document.cookie.split('TOKEN=')[1];
if (token) {
  localStorage.setItem('token', token);
}

console.log('cookie:', document.cookie);
Vue.use(VueEvents);
Vue.use(Vuelidate);
Vue.use(infiniteScroll);
Vue.use(VueTextareaAutosize);
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.use(GAuth, {
  client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
