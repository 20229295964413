<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.1394 6.93894C16.5591 6.93894 16.0484 7.18317 15.6725 7.56369C14.2905 6.59288 12.427 5.96813 10.3621 5.90152L11.4348 0.996922L14.8502 1.7748C14.8502 2.62311 15.5316 3.316 16.3677 3.316C17.2198 3.316 17.9051 2.60473 17.9051 1.75566C17.9051 0.906578 17.2244 0.195312 16.3684 0.195312C15.772 0.195312 15.2575 0.560516 15.001 1.0597L11.2288 0.211391C11.0389 0.160094 10.8529 0.297906 10.8023 0.490844L9.62481 5.89769C7.576 5.9842 5.73238 6.60819 4.34583 7.57977C3.96991 7.18317 3.43933 6.93894 2.85899 6.93894C0.706049 6.93894 0.000908554 9.87052 1.97239 10.8727C1.90272 11.1828 1.87133 11.5135 1.87133 11.8435C1.87133 15.1365 5.52719 17.8047 10.016 17.8047C14.524 17.8047 18.1799 15.1365 18.1799 11.8435C18.1799 11.5135 18.1455 11.1675 18.0605 10.8566C19.9921 9.85061 19.2809 6.94047 17.1394 6.93894ZM5.10074 11.081C5.10074 10.2166 5.78214 9.52063 6.63811 9.52063C7.47417 9.52063 8.15635 10.212 8.15635 11.081C8.15635 11.93 7.47494 12.6222 6.63811 12.6222C5.78597 12.626 5.10074 11.93 5.10074 11.081ZM13.3993 14.7544C11.9898 16.1846 8.01241 16.1846 6.60213 14.7544C6.44671 14.6166 6.44671 14.3732 6.60213 14.2162C6.73764 14.0784 6.97805 14.0784 7.11356 14.2162C8.19003 15.3363 11.7609 15.3555 12.8841 14.2162C13.0196 14.0784 13.26 14.0784 13.3955 14.2162C13.554 14.3739 13.554 14.6174 13.3993 14.7544ZM13.368 12.6252C12.5319 12.6252 11.8505 11.9339 11.8505 11.0856C11.8505 10.2212 12.5319 9.52522 13.368 9.52522C14.2201 9.52522 14.9053 10.2166 14.9053 11.0856C14.9015 11.93 14.2201 12.6252 13.368 12.6252Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'RedditIcon'
};
</script>
