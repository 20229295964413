/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import Vue from 'vue';
import _pick from 'lodash/pick';
import imageCompression from 'browser-image-compression';
import { ELEMENT_TYPES } from '../../common/constants';
import memeApi from '../../api/memeApi';
import postApi from '../../api/postApi';
import { rgbaToObj } from '../../common/colors';

export default {
  namespaced: true,

  state: {
    isPending: false,
    isSaving: false,
    memeData: {
      id: null,
      image_url: null,
      url: null,
    },
    post: {
      isPending: false,
    },
    memes: {
      isPending: false,
      data: [],
      currentPage: null,
      lastPage: null,
    },
    templates: {
      isPending: false,
      data: [],
      currentPage: null,
      lastPage: null,
    },
  },

  getters: {
    getIsPending: state => state.isPending,
    getIsSaving: state => state.isSaving,
    getIsPostPending: state => state.post.isPending,
    getMemeImageUrl: state => state.memeData.image_url,
    getMemeUrl: state => state.memeData.url,
    isMemeDataEmpty: state => state.memeData.id === null,

    isMemesPending: state => state.memes.isPending,
    getMemesData: state => state.memes.data,
    getMemesCurrentPage: state => state.memes.currentPage,
    getMemesLastPage: state => state.memes.lastPage,
  },

  mutations: {
    setIsSaving(state, isSaving) {
      state.isSaving = isSaving;
    },

    setIsPending(state, isPending) {
      state.isPending = isPending;
    },

    setPostPending(state, isPending) {
      state.post.isPending = isPending;
    },

    setMemeData(state, memeData) {
      state.memeData.id = memeData.id;
      state.memeData.image_url = memeData.image_url;
      state.memeData.url = memeData.url;
    },

    setMemesPending(state, isPending) {
      state.memes.isPending = isPending;
    },

    setMemes(state, response) {
      Vue.set(state.memes, 'currentPage', response.current_page);
      Vue.set(state.memes, 'lastPage', response.last_page);

      const data = state.memes.data.concat(response.data);
      Vue.set(state.memes, 'data', data);
    },

    resetMemesPagination(state) {
      Vue.set(state.memes, 'currentPage', null);
      Vue.set(state.memes, 'lastPage', null);
      Vue.set(state.memes, 'data', []);
    },
  },

  actions: {
    setIsSaving({ commit }, value) {
      commit('setIsSaving', value);
    },

    setIsPending({ commit }, value) {
      commit('setIsPending', value);
    },

    setMemeData({ commit }, memeData) {
      commit('setMemeData', memeData);
    },

    saveAssets({ rootState }) {
      const canvasState = rootState.canvas;
      const base64Images = canvasState.elements.ids
        .filter(id => {
          const element = canvasState.elements.entities[id];
          return element.type === ELEMENT_TYPES.IMAGE;
        })
        .map(id => canvasState.elements.entities[id])
        .filter(imageElement => imageElement.url.includes('base64'));

      const assetsPromises = base64Images.map(image => {
        const { id, url } = image;
        return memeApi.saveAsset(url).then(response => ({
          ...response.responseData.data,
          elementId: id,
        }));
      });
      return Promise.all(assetsPromises).then(responses => {
        const assetsMap = {};
        responses.forEach(response => {
          assetsMap[response.elementId] = response;
        });
        return assetsMap;
      });
    },

    // eslint-disable-next-line consistent-return
    async saveMeme({ rootState, dispatch, rootGetters }, { memeId, image, assetsMap }) {
      const canvasState = rootState.canvas;
      const elements = canvasState.elements.ids.map(id => {
        const element = canvasState.elements.entities[id];
        element.initialCanvasSize = {
          width: canvasState.elements.currentCanvasSize.width,
          height: canvasState.elements.currentCanvasSize.height,
        };
        element.initialDrawingAreaData = {
          left: canvasState.elements.currentTopLeftDrawingArea.left,
          top: canvasState.elements.currentTopLeftDrawingArea.top,
          toolbarsSpace: canvasState.elements.currentTopLeftDrawingArea.toolbarsSpace,
          sidePaddings: canvasState.elements.currentTopLeftDrawingArea.sidePaddings,
          verticalFramesSize: canvasState.elements.currentTopLeftDrawingArea.verticalFramesSize,
          ratio: canvasState.elements.currentTopLeftDrawingArea.ratio,
          aspectRatio: canvasState.elements.currentTopLeftDrawingArea.aspectRatio,
          width: canvasState.elements.currentDrawingAreaSizes.width,
          height: canvasState.elements.currentDrawingAreaSizes.height,
          isTemplateGenerationMode: rootState.ui.isTemplateGenerationMode,
        };

        if (element.type === ELEMENT_TYPES.IMAGE) {
          const url = assetsMap[element.id] ? assetsMap[element.id].url : element.url;

          return {
            ...element,
            url,
          };
        }

        if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
          const objectsData = element.json.objects.map(drawingItem => {
            const objectData = _pick(drawingItem, [
              'path',
              'left',
              'top',
              'width',
              'height',
              'scaleX',
              'scaleY',
              'angle',
              'stroke',
              'fill',
              'strokeWidth',
              'strokeLineCap',
              'strokeLineJoin',
              'strokeMiterLimit',
            ]);
            if (objectData.fill) {
              objectData.fill = rgbaToObj(objectData.fill);
            }
            if (objectData.stroke) {
              objectData.stroke = rgbaToObj(objectData.stroke);
            }

            return objectData;
          });

          return {
            ...element,
            json: {
              objects: objectsData,
            },
          };
        }
        return element;
      });

      const data = {
        id: null,
        aspectRatio: canvasState.common.aspectRatio.active,
        originalRatio: canvasState.common.aspectRatio.original,
        height: canvasState.common.height,
        width: canvasState.common.width,
        layout: {
          name: canvasState.layouts.activeLayout,
        },
        frame: canvasState.frames,
        elements,
        isTemplate: rootGetters['ui/isTemplateGenerationMode'],
      };

      try {
        const options = {
          maxSizeMB: process.env.VUE_APP_MAX_IMAGE_SIZE,
          maxWidthOrHeight: process.env.VUE_APP_MAX_IMAGE_RESOLUTION,
          useWebWorker: true,
        };
        const imageFile = await fetch(image)
          .then(res => res.blob())
          .then(blob => new File([blob], 'File name', { type: 'image/png' }));
        const compressedFile = await imageCompression(imageFile, options);
        const compressedBASE64 = await imageCompression.getDataUrlFromFile(compressedFile);

        return memeApi.saveMeme(memeId, compressedBASE64, data).then(response => {
          const responseData = response.responseData.data;
          dispatch('setMemeData', responseData);
          return responseData;
        });
      } catch (err) {
        console.log(err);
      }
    },

    loadMeme({ dispatch }, memeId) {
      dispatch('setIsPending', true);
      memeApi
        .openMeme(memeId)
        .then(response => {
          dispatch('setMemeData', response.responseData.data);
          dispatch('setIsPending', false);
        })
        .catch(error => {
          console.log(error);
          dispatch('setIsPending', false);
        });
    },

    loadMemeCanvas({ dispatch }, { memeId, isClone = false }) {
      dispatch('setIsPending', true);
      memeApi.openMeme(memeId).then(response => {
        const responseData = response.responseData.data;
        if (!isClone) {
          dispatch('setMemeData', responseData);
        }
        const memeData = JSON.parse(responseData.meme_data);
        dispatch('restoreCanvasFromMemeData', { memeData, fromSaved: true });
        dispatch('setIsPending', false);
      });
    },

    restoreCanvasFromMemeData(
      { dispatch, rootState, rootGetters },
      { memeData, fromSaved = false },
    ) {
      if (memeData === 'get_data_from_template') {
        const data = rootGetters['template/getTemplateData'];
        memeData = JSON.parse(data.data);
      }
      const { currentCanvasSize, currentTopLeftDrawingArea } = rootState.canvas.elements;
      const { type } = rootState.ui.deviceInfo.platform;
      dispatch(
        'canvas/common/setCanvasSize',
        {
          height: memeData.height,
          width: memeData.width,
        },
        { root: true },
      );
      const newElements = memeData.elements.map(element => {
        // Getting new sizes of Drawing Area
        let width;
        let height;

        if (type === 'desktop') {
          height = currentCanvasSize.height - currentTopLeftDrawingArea.toolbarsSpace;
          width =
            currentTopLeftDrawingArea.aspectRatio === 'original'
              ? height /* (height - currentTopLeftDrawingArea.verticalFramesSize) */ *
                element.initialDrawingAreaData.ratio
              : height * element.initialDrawingAreaData.ratio;
        } else {
          const lookaheadWidth = currentCanvasSize.width - currentTopLeftDrawingArea.sidePaddings;
          const lookaheadHeight = lookaheadWidth / element.initialDrawingAreaData.ratio;
          const availableHeight =
            currentCanvasSize.height - currentTopLeftDrawingArea.toolbarsSpace;

          if (lookaheadHeight < availableHeight) {
            width = currentCanvasSize.width - currentTopLeftDrawingArea.sidePaddings;
            height = width / element.initialDrawingAreaData.ratio;
          } else {
            height = availableHeight;
            width = height * element.initialDrawingAreaData.ratio;
          }
        }

        const newScaleX = width / element.initialDrawingAreaData.width;
        const newScaleY = height / element.initialDrawingAreaData.height;

        element.scaleX *= newScaleX;
        element.scaleY *= newScaleY;

        if (!element.initialDrawingAreaData.isTemplate && fromSaved) {
          const newLeftDrawingAreaPosition = (currentCanvasSize.width - width) / 2;
          const newTopDrawingAreaPosition = currentTopLeftDrawingArea.topToolbarSize;
          element.position.x =
            (element.position.x - element.initialDrawingAreaData.left) *
              (width / element.initialDrawingAreaData.width) +
            newLeftDrawingAreaPosition;
          element.position.y =
            (element.position.y - element.initialDrawingAreaData.top) *
              (height / element.initialDrawingAreaData.height) +
            newTopDrawingAreaPosition;
        }

        if (!fromSaved) {
          // MISTAKE SOMEWHERE HERE FIX
          element.position.x *= width / element.initialDrawingAreaData.width;
          element.position.y *= height / element.initialDrawingAreaData.height;
        }

        // if (memeData.isTemplate && element.type !== 'text') {
        //   // THIS 2 PARAMS BELOW FORCE IMAGE GO DOWN IN DRAWING AREA. IT NEEDS TO FIX
        //   // element.position.x *= width;
        //   // element.position.y *= height;
        // } else if (element.type === 'text') {
        //   // MISTAKE SOMEWHERE HERE FIX
        //   element.position.x *= width / element.initialDrawingAreaData.width;
        //   element.position.y *= height / element.initialDrawingAreaData.height;
        // }
        return element;
      });

      memeData.elements = newElements;
      dispatch('canvas/elements/setIsFirstMainImageRender', true, { root: true });
      dispatch('canvas/elements/setIsFromTemplate', memeData.isTemplate, { root: true });
      dispatch('canvas/common/setAspectRatio', memeData.aspectRatio, { root: true });
      dispatch('canvas/common/setOriginalAspectRatio', memeData.originalRatio, { root: true });
      dispatch('canvas/layouts/setActiveLayout', memeData.layout.name, { root: true });
      dispatch('canvas/frames/mergeFramesFromLayout', memeData.frame, { root: true });
      dispatch('canvas/elements/loadElements', memeData.elements, { root: true });
      // allows to preserve the displacement of the main image along the X or Y axes
      // when restoring the canvas
      setTimeout(() => {
        dispatch('canvas/elements/setIsFirstMainImageRender', false, { root: true });
        dispatch('canvas/history/resetHistoryAction', null, { root: true });
      }, 2000);
    },

    uploadPost({ commit }, post) {
      commit('setPostPending', true);
      return postApi
        .uploadPost(post)
        .then(response => response)
        .finally(() => {
          commit('setPostPending', false);
        });
    },

    getSavedMemes({ commit }, page) {
      commit('setMemesPending', true);
      return memeApi
        .getMemes(page)
        .then(response => {
          commit('setMemes', response.responseData);
        })
        .finally(() => {
          commit('setMemesPending', false);
        });
    },

    resetMemesPagination({ commit }) {
      commit('resetMemesPagination');
    },
  },
};
