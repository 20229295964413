import { fabric } from 'fabric';
import { ELEMENT_TYPES, FRAME_SIDES } from '../../../common/constants';
import { rgba } from '../../../common/colors';

const Frames = {
  computed: {
    frames() {
      return this.$store.getters['canvas/frames/getFrames'];
    },
  },

  watch: {
    frames: {
      deep: true,
      handler() {
        // this.drawFrames();
        // this.renderElements();
      },
    },
  },

  methods: {
    drawFrames() {
      this.findObjectsByType(ELEMENT_TYPES.FRAME).forEach(object => {
        this.canvas.remove(object);
      });
      const drawingAreaIndex = this.canvas.getObjects().indexOf(this.drawingArea);
      Object.keys(FRAME_SIDES).forEach((sideKey, i) => {
        const side = FRAME_SIDES[sideKey];
        let sizeOptions = {};
        const { size } = this.frames[side];
        let widthSize = size;
        let heightSize = size;
        if (size.toString().includes('%')) {
          const percent = parseInt(size.replace('%', ''), 10) / 100;
          widthSize = this.drawingArea.width * percent;
          heightSize = this.drawingArea.height * percent;
        }
        if (side === FRAME_SIDES.TOP) {
          sizeOptions = {
            height: heightSize,
            width: this.drawingArea.width,
            left: this.drawingArea.left,
            top: this.drawingArea.top,
          };
        } else if (side === FRAME_SIDES.BOTTOM) {
          sizeOptions = {
            height: heightSize,
            width: this.drawingArea.width,
            left: this.drawingArea.left,
            top: this.drawingArea.top + this.drawingArea.height - heightSize,
          };
        } else if (side === FRAME_SIDES.LEFT) {
          sizeOptions = {
            height: this.drawingArea.height,
            width: widthSize,
            left: this.drawingArea.left,
            top: this.drawingArea.top,
          };
        } else if (side === FRAME_SIDES.RIGHT) {
          sizeOptions = {
            height: this.drawingArea.height,
            width: widthSize,
            left: this.drawingArea.left + this.drawingArea.width - widthSize,
            top: this.drawingArea.top,
          };
        }
        this.canvas.renderAll();
        const frameColor = rgba(this.frames[side].color);
        const frame = new fabric.Rect({
          fill: frameColor,
          selectable: false,
          elementType: ELEMENT_TYPES.FRAME,
          ...sizeOptions,
        });

        this.canvas.add(frame);
        frame.moveTo(drawingAreaIndex + 2 + i);
      });
      this.canvas.renderAll();
    },
  },
};

export default Frames;
