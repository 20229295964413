<template>
  <div
    :class="`mg-canvas-container ${isTemplateGenerationMode ? 'mg-canvas-tg-mode' : ''}`"
    ref="container"
    v-hotkey="keymap"
  >
    <TopToolbar
      v-if="!isBrushEnabled"
      @onDelete="deleteActiveElement"
      @onClone="cloneActiveElement"
    />

    <DrawToolbar v-if="isBrushEnabled" @onCancel="cancelDraw" @onApply="applyDraw" />

    <image-crop-modal v-if="isCropModalOpen" />
    <json-data v-if="jsonModal" />
    <canvas id="canvas" />
    <BottomToolbar v-if="!isBrushEnabled" />
  </div>
</template>

<script>
import { fabric } from 'fabric';
import TopToolbar from '../toolbars/TopToolbar.vue';
import BottomToolbar from '../toolbars/BottomToolbar.vue';
import DrawToolbar from '../toolbars/DrawToolbar.vue';
import CanvasFunctions from './CanvasFunctions';
import DrawingArea from './DrawingArea';
import Frames from './Frames';
import Elements from './elements/Elements';
import ImageElements from './elements/ImageElements';
import TextElements from './elements/TextElements';
import Brush from './elements/Brush';
import WatermarkImage from '../../../assets/images/watermark.png';
import { ELEMENT_TYPES } from '../../../common/constants';
import ImageCropModal from '../image-dialog/ImageCropModal.vue';
import JsonData from '../image-dialog/JsonData.vue';

export default {
  name: 'Canvas',

  components: {
    DrawToolbar,
    BottomToolbar,
    TopToolbar,
    ImageCropModal,
    JsonData,
  },

  mixins: [CanvasFunctions, DrawingArea, Frames, Elements, Brush, ImageElements, TextElements],

  data() {
    return {
      canvas: null,
      drawingArea: null,
      isRenderingElements: false,
    };
  },

  computed: {
    jsonModal() {
      return this.$store.getters['canvas/history/getJsonModal'];
    },
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    activeSidebar() {
      return this.$store.getters['ui/getActiveSidebar'];
    },

    isCropModalOpen() {
      return this.$store.getters['ui/getCropModalOpen'];
    },

    isActiveSidebarResetRequired() {
      return (
        (this.activeSidebar === 'image' ||
          this.activeSidebar === 'text' ||
          this.activeSidebar === 'brush') &&
        !this.isBrushEnabled
      );
    },

    isWatermarkEnabled() {
      return this.$store.getters['canvas/watermark/isWatermarkEnabled'];
    },

    keymap() {
      return {
        del: this.deleteActiveElement,
        left: this.moveElement,
        up: this.moveElement,
        right: this.moveElement,
        down: this.moveElement,
      };
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
  },

  events: {
    Global_onSave() {
      const imagePromise = this.toImage();
      const assetsPromise = this.$store.dispatch('meme/saveAssets');
      this.$store.dispatch('meme/setIsSaving', true);
      Promise.all([imagePromise, assetsPromise]).then(([image, assetsMap]) => {
        this.$store
          .dispatch('meme/saveMeme', {
            memeId: this.$route.params.memeId,
            image,
            assetsMap,
          })
          .then(memeData => {
            this.$store.dispatch('meme/setIsSaving', false);
            this.$router.push(`/post-meme-tools/${memeData.id}`);
          })
          .catch(() => {
            this.$store.dispatch('meme/setIsSaving', false);
          });
      });
    },
  },

  mounted() {
    this.initBrush();
    this.initCanvas();
    this.renderDrawingArea();
    this.drawFrames();
    this.renderElements();
  },

  beforeDestroy() {
    this.$store.dispatch('canvas/common/clearCanvas');
    this.clearElements();
  },

  methods: {
    initCanvas() {
      const { width, height } = this.$refs.container.getBoundingClientRect();
      fabric.devicePixelRatio = 2;
      this.canvas = new fabric.Canvas('canvas', {
        preserveObjectStacking: true,
        width,
        height,
      });

      window.canvas = this.canvas;

      this.$store.dispatch('canvas/elements/addCurrentCanvasSize', this.canvas);
    },

    toImage() {
      return this.addWatermark().then(() => {
        this.canvas.setBackgroundColor('#fff');
        this.canvas.renderAll();
        const base64 = this.canvas.toDataURL({
          format: 'png',
          multiplier: 2,
          left: this.drawingArea.left + 1,
          top: this.drawingArea.top + 1,
          width: this.drawingArea.width - 1,
          height: this.drawingArea.height - 1,
        });
        this.canvas.setBackgroundColor('transparent');
        this.removeObjectById('watermark');
        this.canvas.renderAll();
        return base64;
      });
    },

    addWatermark() {
      return new Promise(resolve => {
        if (!this.isWatermarkEnabled) {
          resolve();
          return false;
        }

        fabric.Image.fromURL(
          WatermarkImage,
          img => {
            const offset = 15;
            img.set({
              left: this.drawingArea.left + this.drawingArea.width - img.width - offset,
              top: this.drawingArea.top + this.drawingArea.height - img.height - offset,
            });

            this.canvas.add(img);
            resolve();
          },
          {
            originX: 'left',
            originY: 'top',
            elementType: ELEMENT_TYPES.WATERMARK,
            id: 'watermark',
          },
        );

        return true;
      });
    },
  },
};
</script>
