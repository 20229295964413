<template>
  <div class="mg-image-settings-sidebar-container">
    <div class="mg-sidebar__settings-block">
      <div class="mg-sidebar-subheader">Opacity</div>
      <CustomSlider
        :input-value="activeElement ? activeElement.opacity * 100 : 100"
        :min-value="0"
        :max-value="100"
        :show-units="true"
        units-type="%"
        @valueChanged="changeOpacity"
      />
    </div>

    <LayersControls v-if="!isMainImage" :is-text-sidebar="false" />
  </div>
</template>

<script>
import CustomSlider from '../../common/CustomSlider.vue';
import LayersControls from './LayersControls.vue';

export default {
  name: 'ImageSettingsSidebar',

  components: {
    LayersControls,
    CustomSlider,
  },

  computed: {
    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement'];
    },

    isMainImage() {
      return this.activeElement ? this.activeElement.main : false;
    },
  },

  methods: {
    onChange(value, path) {
      const payload = {
        desktop: {
          actionName: 'canvas/elements/setElementOption',
          value: {
            elementId: this.activeElement.id,
            path,
            value,
          },
        },
        mobile: {
          actionName: 'canvas/elements/setElementOptionsGroup',
          value: {
            elementId: this.activeElement.id,
            path,
            value,
          },
        },
      };

      this.$emit('settingChanged', payload);
    },

    changeOpacity(value) {
      this.onChange(value / 100, 'opacity');
    },
  },
};
</script>
