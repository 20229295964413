<template>
  <div class="mg-editor-page">
    <MainToolbar />

    <SidebarContainer v-if="isDesktop" />
    <MobileSidebarContainer v-else-if="activeSidebar" />

    <Canvas v-show="(isDesktop && !isFullWidthSidebar) || !isDesktop" />
    <ImageDialog />
  </div>
</template>

<script>
import Canvas from '../components/editor/canvas/Canvas.vue';
import MainToolbar from '../components/layout/main-toolbar/MainToolbar.vue';
import SidebarContainer from '../components/editor/sidebars/SidebarContainer.vue';
import ImageDialog from '../components/editor/image-dialog/ImageDialog.vue';
import MobileSidebarContainer from '../components/editor/sidebars/MobileSidebarContainer.vue';
import StartPageVue from './StartPage.vue';

export default {
  name: 'EditorPage',

  components: {
    ImageDialog,
    SidebarContainer,
    MainToolbar,
    Canvas,
    MobileSidebarContainer,
  },

  mixins: [StartPageVue],

  computed: {
    templateById() {
      return this.$store.getters['template/getTemplateData'];
    },

    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    activeSidebar() {
      return this.$store.getters['ui/getActiveSidebar'];
    },

    isFullWidthSidebar() {
      return this.activeSidebar === 'saved';
    },
  },
  watch: {
    templateById: {
      handler() {
        if (this.$route.query.template_id) {
          this.$store.dispatch('meme/restoreCanvasFromMemeData', {
            memeData: 'get_data_from_template',
          });
        }
      },
    },
  },

  created() {
    const { memeId } = this.$route.params;
    const { template_id: templateId } = this.$route.query;
    if (templateId) {
      this.$store.dispatch('template/setData', templateId);
    }
    const { layout_number: layoutNumber } = this.$route.query;
    if ('tg' in this.$route.query && this.isDesktop) {
      this.$store.dispatch('ui/setIsTemplateGenerationMode', true);
    } else {
      this.$store.dispatch('ui/setIsTemplateGenerationMode', false);
    }

    if (memeId) {
      this.$store.dispatch('meme/loadMemeCanvas', {
        memeId,
        isClone: false,
      });
    }

    if (layoutNumber) {
      this.$store.dispatch('canvas/layouts/setLayoutFromQuery', true);
      this.$store.dispatch('canvas/layouts/setActiveLayout', `layout-${layoutNumber}`);
    }
  },
};
</script>
