<template>
  <UploadImage
    @onUploadFinish="onUploadFinish"
  />
</template>

<script>
import UploadImage from '../ImageDialogUploadArea.vue';

export default {
  name: 'UploadTab',

  components: { UploadImage },

  methods: {
    onUploadFinish (imageData) {
      this.$emit('onUploadFinish', imageData);
    }
  }
};
</script>
