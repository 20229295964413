<template>
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 0H2C0.9 0 0 0.9 0 2V10C0 11.1 0.9 12 2 12H16C17.1 12 18 11.1 18 10V2C18 0.9 17.1 0 16 0ZM16 10H2V2H16V10Z" fill="#BDBDBD"/>
  </svg>
</template>

<script>
export default {
  name: 'Icon16To9'
};
</script>
