<template>
  <button
    :type="type"
    :class="currentClass"
    :style="[basicStyles, isOnHover ? hoverStyles : {}]"
    @click="$emit('buttonClicked')"
    @mouseenter="isDesktop ?  isOnHover = true : null"
    @mouseleave="isDesktop ?  isOnHover = false : null"
    :disabled="isDisabled"
  >

    <span :class="`mg-custom-button__content-container--${iconPosition}`">
      <span :style="iconStyles" v-if="iconName">
        <img :src="require(`../../assets/svg-icons/${currentIcon}.svg`)" alt="Icon" />
      </span>

      <span v-if="textContent"> {{ textContent }}</span>

      <CustomButtonSpecificContent
        v-if="specificContentComponentName"
        :specific-content-component-name="specificContentComponentName"
      />
    </span>

  </button>
</template>

<script>
import CustomButtonSpecificContent from './CustomButtonSpecificContent.vue';

export default {
  name: 'CustomButtonTemplate',

  components: {
    CustomButtonSpecificContent
  },

  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    textContent: String,
    iconName: String,
    iconHoverName: String,
    iconActiveName: String,
    iconPosition: String,
    isActive: Boolean,
    specificContentComponentName: String,
    gradientOutlineStyle: String,
    basicStyles: Object,
    hoverStyles: Object,
    iconStyles: Object,
    isDisabled: Boolean,
  },

  data() {
    return {
      isOnHover: false,
    };
  },

  computed: {
    isDesktop () {
      return this.$store.getters['ui/isDesktop'];
    },
    currentClass () {
      let resultClass = 'mg-custom-button';

      if (this.gradientOutlineStyle) {
        resultClass = `mg-custom-button--gradient-outline mg-custom-button--gradient-outline--${this.gradientOutlineStyle}`;

        if (this.isOnHover) {
          resultClass += '--hover';
        }
      }
      return resultClass;
    },

    currentIcon() {
      let resultIcon = this.iconName;

      if (this.iconHoverName && this.isOnHover) {
        resultIcon = this.iconHoverName;
      }

      if (this.iconActiveName && this.isActive) {
        resultIcon = this.iconActiveName;
      }

      return resultIcon;
    }
  },

};
</script>
