<template>
    <div class="mg-post-meme-tag-checkbox__container" v-on:click="onSelectCategory">

      <div class="mg-post-meme-tag-checkbox__container--left-block">

        <div class="mg-post-meme-tag-checkbox__container__emoji">
          <img
            :src="require(`../../assets/svg-icons/categories/icon-${slug}.svg`)"
            alt="Icon"
          />
        </div>

        <div class="mg-post-meme-tag-checkbox__container__title">
          {{title}}
        </div>

      </div>

      <div class="mg-post-meme-tag-checkbox__container--right-block">

        <div class="mg-post-meme-tag-checkbox__container__checkbox">
          <div class="mg-custom-checkbox">
            <b-form-checkbox
              size="lg"
              :checked="activeCategorySlug === slug"
              @change="onSelectCategory"
            />
          </div>
        </div>

      </div>

    </div>
</template>

<script>
export default {
  name: 'TagCheckboxItem',

  props: {
    title: String,
    slug: String,
    activeCategorySlug: String
  },

  methods: {
    onSelectCategory () {
      this.$emit('onSelectCategory', this.slug);
    }
  }
};
</script>
