<template>
  <svg width="1" height="24" viewBox="0 0 1 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.15" width="1" height="24" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'DelimiterIcon'
};
</script>
