<template>
  <div class="mg-custom-search-input__container">
    <form class="mg-custom-search-input__form"
          @submit.prevent="submitHandler"
    >
      <div v-if="inputType === 'search'" id="custom-search-input-icon">
        <SearchIcon/>
      </div>
      <input
        id="custom-search-input"
        :type="inputType"
        v-model="inputValue"
        :disabled="!!fixedValue"
        :placeholder="placeholder"
        :style="additionalInputStyles"
        @input="$emit('inputValue', inputValue)"
      >
      <button v-if="buttonTitle" type="submit" id="mg-custom-search-input-submit-button">{{buttonTitle}}</button>
    </form>
  </div>
</template>

<script>
import SearchIcon from './icons/SearchIcon.vue';

export default {
  name: 'CustomSearchInput',

  components: {
    SearchIcon
  },

  data() {
    return {
      inputValue: this.fixedValue ? this.fixedValue : '',
    };
  },

  props: {
    inputType: String,
    fixedValue: String,
    placeholder: String,
    buttonTitle: String,
    additionalInputStyles: Object,
  },

  methods: {
    submitHandler() {
      const payload = this.fixedValue ? this.fixedValue : this.inputValue;
      this.$emit('searchSubmitted', payload);

      this.inputValue = this.fixedValue ? this.fixedValue : '';
    }
  }
};
</script>
