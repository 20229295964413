/* eslint-disable implicit-arrow-linebreak */
import stateMerge from 'vue-object-merge';
import Bowser from 'bowser';
import { FRAME_SIDES } from '../../../common/constants';

// eslint-disable-next-line no-unused-vars
const platform = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type;
const frameSize = platform === 'desktop' ? 80 : 50;
const defaultFamily = { family: 'Roboto' };
const defaultColor = {
  color: {
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  },
};
export default {
  namespaced: true,

  state: {
    activeLayout: 'layout-1',
    queryLayout: null,
    layoutFromQuery: false,
    layoutList: [
      {
        name: 'layout-1',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: frameSize,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: 0,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            font: { alignment: 'left', ...defaultFamily, ...defaultColor },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-2',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: 0,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: 0,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            font: { alignment: 'left', ...defaultFamily, ...defaultColor },
            position: {
              x: 'center',
              y: 'center',
            },
          },
          mainImage: {
            visible: false,
          },
        },
      },

      {
        name: 'layout-3',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: 0,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: 0,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            font: {
              alignment: 'center',
              family: 'Anton-Regular',
              color: {
                r: 255,
                g: 255,
                b: 255,
                a: 1,
              },
              outline: {
                thickness: 1,
              },
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-4',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: frameSize,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: frameSize,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            font: { ...defaultFamily, ...defaultColor },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-5',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: frameSize,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: 0,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            ...defaultFamily,
            font: {
              ...defaultFamily,
              ...defaultColor,
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-6',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: 0,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: frameSize,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            font: {
              ...defaultFamily,
              ...defaultColor,
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-7',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: frameSize,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: frameSize,
          },
          [FRAME_SIDES.LEFT]: {
            size: 15,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 15,
          },
        },
        elements: {
          text: {
            font: {
              ...defaultFamily,
              ...defaultColor,
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-8',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: 15,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: frameSize,
          },
          [FRAME_SIDES.LEFT]: {
            size: 15,
          },
          [FRAME_SIDES.RIGHT]: {
            size: 15,
          },
        },
        elements: {
          text: {
            font: {
              ...defaultFamily,
              ...defaultColor,
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-9',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: 0,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: 0,
          },
          [FRAME_SIDES.LEFT]: {
            size: 0,
          },
          [FRAME_SIDES.RIGHT]: {
            size: '50%',
          },
        },
        elements: {
          text: {
            font: {
              ...defaultFamily,
              ...defaultColor,
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },

      {
        name: 'layout-10',
        frames: {
          [FRAME_SIDES.TOP]: {
            size: 0,
          },
          [FRAME_SIDES.BOTTOM]: {
            size: 0,
          },
          [FRAME_SIDES.LEFT]: {
            size: '50%',
          },
          [FRAME_SIDES.RIGHT]: {
            size: 0,
          },
        },
        elements: {
          text: {
            font: {
              ...defaultFamily,
              ...defaultColor,
            },
          },
          mainImage: {
            visible: true,
          },
        },
      },
    ],
  },

  getters: {
    getLayoutFromQuery: state => state.layoutFromQuery,
    getLayoutsShort: state =>
      state.layoutList.map(layout => ({
        name: layout.name,
      })),

    getActiveLayout: state => state.activeLayout,
    getQueryLayout: state => state.queryLayout,
  },

  mutations: {
    setLayoutFromQuery(state, value) {
      state.layoutFromQuery = value;
    },
    setQueryLayout(state, queryLayout) {
      state.queryLayout = queryLayout;
    },
    setActiveLayout(state, layoutName) {
      state.activeLayout = layoutName;
    },

    setLayoutOptionsGroup(state, { options }) {
      stateMerge(state, options);
    },
  },

  actions: {
    setLayoutFromQuery({ commit }, value) {
      commit('setLayoutFromQuery', value);
    },
    setQueryLayout({ commit }, query) {
      commit('setQueryLayout', query);
    },
    setActiveLayout({ commit, state, dispatch }, layoutName) {
      commit('setActiveLayout', layoutName);

      const layout = state.layoutList.find(_layout => _layout.name === layoutName);
      dispatch('canvas/frames/mergeFramesFromLayout', layout.frames, { root: true });
      if (layout.elements) {
        dispatch('canvas/elements/mergeElementsFromLayout', layout.elements, { root: true });
      }
    },

    setLayoutOptionsGroup({ commit, state, dispatch }, payload) {
      commit('setLayoutOptionsGroup', payload);

      const layout = state.layoutList.find(
        _layout => _layout.name === payload.options.activeLayout,
      );
      dispatch('canvas/frames/mergeFramesFromLayout', layout.frames, { root: true });
      if (layout.elements) {
        dispatch('canvas/elements/mergeElementsFromLayout', layout.elements, { root: true });
      }
    },
  },
};
