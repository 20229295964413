<template>
  <TemplatesSidebar
   type="templates"
   @templateAdded="templateAdded"
  />
</template>

<script>
import TemplatesSidebar from '../../sidebars/TemplatesSidebar.vue';

export default {
  name: 'MemesTab',

  components: {
    TemplatesSidebar
  },

  methods: {
    templateAdded () {
      this.$emit('templateAdded');
    }
  }
};
</script>
