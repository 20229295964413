import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import ui from './modules/ui';
import canvas from './modules/canvas/canvas';
import alerts from './modules/alerts';
import images from './modules/images';
import meme from './modules/meme';
import template from './modules/template';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    ui,
    alerts,
    canvas,
    images,
    meme,
    template,
  },
});
