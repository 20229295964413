<template>
  <component
    :is="specificContentComponentName"
  />
</template>

<script>
import Icon1To1 from './icons/aspect-ratio/Icon1To1.vue';
import Icon3To4 from './icons/aspect-ratio/Icon3To4.vue';
import Icon4To3 from './icons/aspect-ratio/Icon4To3.vue';
import Icon9To16 from './icons/aspect-ratio/Icon9To16.vue';
import Icon16To9 from './icons/aspect-ratio/Icon16To9.vue';

export default {
  name: 'CustomButtonSpecificContent',

  components: {
    Icon1To1,
    Icon3To4,
    Icon4To3,
    Icon9To16,
    Icon16To9
  },

  props: {
    specificContentComponentName: String,
  }
};
</script>
