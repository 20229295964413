/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    template: null,
  },
  getters: {
    getTemplateData: state => state.template,
  },
  mutations: {
    setData(state, templateData) {
      state.template = templateData;
    },
  },
  actions: {
    setData({ commit }, id) {
      axios(`${process.env.VUE_APP_API_URL}/api/generator/templates/${id}`).then(response => {
        commit('setData', response.data.responseData);
      });
    },
  },
};
