/* eslint-disable consistent-return */
import { queryGet, queryPost } from './apiConfig';

export default {
  login(payload) {
    return queryPost('/auth/login', payload);
  },

  logout() {
    const localToken = localStorage.getItem('token');
    return queryPost('/auth/logout', null, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localToken}`,
        credentials: 'include',
      },
    });
  },

  register(payload) {
    return queryPost('/auth/register', payload);
  },

  getProfile() {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      return queryGet('/me/profile', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localToken}`,
        },
      });
    }
  },
};
