import stateMerge from 'vue-object-merge';

export default {
  namespaced: true,

  state: {
    width: 800,
    height: 600,
    aspectRatio: {
      active: '1:1',
      original: '',
      list: ['4:3', '16:9', '1:1', '3:4', '9:16', 'original'],
    },
  },

  getters: {
    getAspectRatio: state => state.aspectRatio.active,
    getOriginalRatio: state => state.aspectRatio.original,
    getAspectRatioList: state => state.aspectRatio.list,
    getDrawingAreaSize: state => ({ width: state.width, height: state.height }),
  },

  mutations: {
    setCanvasSize(state, { width, height }) {
      // drawing area, not canvas wrapper
      state.width = width;
      state.height = height;
    },

    setInitialUploadedMainImageRatio(state, { width, height }) {
      state.aspectRatio.original = width / height;
    },

    setAspectRatio(state, aspectRatio) {
      state.aspectRatio.active = aspectRatio;
    },

    setOriginalAspectRatio(state, originalRatio) {
      state.aspectRatio.original = originalRatio;
    },

    setCommonOptionsGroup(state, { options }) {
      stateMerge(state, options);
    },
  },

  actions: {
    setCanvasSize({ commit }, size) {
      commit('setCanvasSize', size);
    },

    setInitialUploadedMainImageRatio({ commit }, payload) {
      commit('setInitialUploadedMainImageRatio', payload);
    },

    setAspectRatio({ commit }, aspectRatio) {
      commit('setAspectRatio', aspectRatio);
    },

    setOriginalAspectRatio({ commit }, originalRatio) {
      commit('setOriginalAspectRatio', originalRatio);
    },

    setCommonOptionsGroup({ commit }, payload) {
      commit('setCommonOptionsGroup', payload);
    },

    clearCanvas({ dispatch }) {
      dispatch('canvas/elements/clearElements', null, { root: true });
      dispatch('canvas/elements/disableFreeDrawing', null, { root: true });
      dispatch('canvas/layouts/setActiveLayout', 'layout-1', { root: true });
      dispatch('setAspectRatio', '1:1');
      dispatch('setOriginalAspectRatio', '');
    },
  },
};
