import axios from 'axios';
import authApi from '../../api/authApi';

export default {
  namespaced: true,

  state: {
    user: null,
    isPending: false,
    isLoggedIn: false,
    authData: null,
    signInError: null,
    signUpError: null,
    redirectAfterLogin: null,
  },

  getters: {
    getUser: state => state.user,
    getIsPending: state => state.isPending,
    getIsLoggedIn: state => state.isLoggedIn,
    getSignInError: state => state.signInError,
    getSignUpError: state => state.signUpError,
    getRedirectUrl: state => state.redirectAfterLogin,
  },

  mutations: {
    setProfile(state, user) {
      state.isLoggedIn = true;
      state.user = user;
    },

    resetProfile(state) {
      state.isLoggedIn = false;
      state.user = null;
    },

    setIsPending(state, payload) {
      state.isPending = payload;
    },

    setAuthData(state, authData) {
      state.isLoggedIn = !!authData;
      state.authData = authData;
    },

    setSignInError(state, signInError) {
      state.signInError = signInError;
    },

    setSignUpError(state, signUpError) {
      state.signUpError = signUpError;
    },

    setRedirectAfterLogin(state, url) {
      state.redirectAfterLogin = url;
    },
  },

  actions: {
    socialMediaAuth({ dispatch }, payload) {
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/api/auth/${payload.socialMedia}?token=${payload.token}`,
        )
        .then(data => {
          const user = data.data.responseData.data;
          if (user) {
            localStorage.setItem('token', user.token);
            dispatch('setAuthData', user);
            dispatch('setProfile', user);
            dispatch('setSignInError', null);
            dispatch('ui/closeLoginModal', null, { root: true });
          }
        })
        .catch(err => console.log('ERROR:', err));
    },

    getProfile({ dispatch }) {
      const token = localStorage.getItem('token');
      if (token) {
        authApi.getProfile().then(response => {
          const user = response.responseData.data;
          const localToken = localStorage.getItem('token');
          user.token = localToken;
          dispatch('setProfile', user);

          dispatch('canvas/watermark/toggleWatermark', Boolean(!user.is_pro_active), {
            root: true,
          });
        });
      }
    },

    setProfile({ commit }, user) {
      commit('setProfile', user);
    },

    resetProfile({ commit }) {
      commit('resetProfile');
    },

    login({ dispatch, commit }, payload) {
      commit('setIsPending', true);

      return authApi
        .login(payload)
        .then(response => {
          const authData = response.responseData.data;
          const { token } = authData;
          localStorage.setItem('token', token);

          dispatch('setAuthData', authData);
          dispatch('setProfile', authData);
          dispatch('setSignInError', null);
          dispatch('ui/closeLoginModal', null, { root: true });
          dispatch('canvas/watermark/toggleWatermark', Boolean(!authData.is_pro_active), {
            root: true,
          });
        })
        .catch(error => {
          const message = error.response.data.responseMessage;
          dispatch('setSignInError', message);
          throw error;
        })
        .finally(() => {
          commit('setIsPending', false);
        });
    },

    logout({ dispatch, commit }) {
      commit('setIsPending', true);

      authApi
        .logout()
        .then(() => {
          dispatch('setAuthData', null);
          dispatch('resetProfile');
        })
        .catch(error => {
          console.error('error', error);
        })
        .finally(() => {
          commit('setIsPending', false);
          dispatch('canvas/watermark/toggleWatermark', true, { root: true });
        });
    },

    register({ dispatch, commit }, payload) {
      commit('setIsPending', true);

      return authApi
        .register(payload)
        .then(response => {
          const authData = response.responseData.data;
          dispatch('setAuthData', authData);
          dispatch('setProfile', authData);
          dispatch('setSignUpError', null);
          dispatch('ui/closeLoginModal', null, { root: true });
        })
        .catch(error => {
          const message = error.response.data.responseMessage;
          dispatch('setSignUpError', message);
          throw error;
        })
        .finally(() => {
          commit('setIsPending', false);
        });
    },

    setAuthData({ commit }, authData) {
      commit('setAuthData', authData);
    },

    setSignInError({ commit }, signInError) {
      commit('setSignInError', signInError);
    },

    setSignUpError({ commit }, signUpError) {
      commit('setSignUpError', signUpError);
    },

    setRedirectAfterLogin({ commit }, url) {
      commit('setRedirectAfterLogin', url);
    },
  },
};
