import FontFaceObserver from 'fontfaceobserver';
import { fabric } from 'fabric';
import { FONTS } from '../../../common/constants';

export default {
  namespaced: true,

  state: {
    isPending: false,
    isLoaded: false,
    isTextFontModalClosed: true,
  },

  getters: {
    getIsTextFontModalClosed: state => state.isTextFontModalClosed,
  },

  mutations: {
    setTextFontModal(state, payload) {
      state.isTextFontModalClosed = payload;
    },
    loadFontsPending(state, isPending) {
      state.isPending = isPending;
    },

    loadFontsSuccess(state) {
      state.isLoaded = true;
    },
  },

  actions: {
    setTextFontModal({ commit }, payload) {
      commit('setTextFontModal', payload);
    },
    loadFonts({ commit }) {
      commit('loadFontsPending', true);
      const fontObservers = FONTS.map(font => {
        const observer = new FontFaceObserver(font.value);
        return observer.load();
      });

      Promise.all(fontObservers)
        .then(() => {
          FONTS.forEach(font => {
            fabric.util.clearFabricFontCache(font.value);
          });
          commit('loadFontsSuccess');
          commit('loadFontsPending', false);
        })
        .catch(err => err);
    },
  },
};
