import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { ELEMENT_TYPES, SIDEBARS } from '../../../../common/constants';
import { rgba } from '../../../../common/colors';
import { initMgPencilBrush } from '../../../../common/fabric/MgPencilBrush';

// noinspection JSIgnoredPromiseFromCall
const Brush = {
  computed: {
    isBrushEnabled() {
      return this.$store.getters['canvas/elements/isBrushEnabled'];
    },

    brushColor() {
      return this.$store.getters['canvas/elements/getBrushColor'];
    },

    brushThickness() {
      return this.$store.getters['canvas/elements/getBrushThickness'];
    },

    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },
    historyArray() {
      return this.$store.getters['canvas/history/getHistoryArray'];
    },
    isFirstRenderImage() {
      return this.$store.getters['canvas/elements/isFirstMainImageRender'];
    },
    isBrushing() {
      return this.$store.getters['canvas/elements/isBrushing'];
    },
  },

  watch: {
    isBrushEnabled: {
      handler() {
        this.toggleFreeDrawing();
      },
    },

    brushColor: {
      handler() {
        this.setBrushColor();
      },
    },

    brushThickness: {
      handler() {
        this.setBrushThickness();
      },
    },
  },

  methods: {
    initBrush() {
      initMgPencilBrush();
    },

    renderBrushElement(element) {
      return new Promise(resolve => {
        const drawings = element.json.objects.map(drawingItem => {
          const path = new fabric.Path(drawingItem.path, {
            left: drawingItem.left,
            top: drawingItem.top,
            width: drawingItem.width,
            height: drawingItem.height,
            scaleX: drawingItem.scaleX,
            scaleY: drawingItem.scaleY,
            angle: drawingItem.angle,
            stroke: drawingItem.stroke,
            fill: drawingItem.fill,
            strokeWidth: drawingItem.strokeWidth,
            strokeLineCap: drawingItem.strokeLineCap,
            strokeLineJoin: drawingItem.strokeLineJoin,
            strokeMiterLimit: drawingItem.strokeMiterLimit,
          });
          this.addClipPathToObject(path);

          return path;
        });

        const drawingGroup = new fabric.Group([], {
          id: element.id,
          elementType: ELEMENT_TYPES.DRAWING_GROUP,
        });
        this.addClipPathToObject(drawingGroup);
        this.canvas.add(drawingGroup);

        drawings.forEach(drawing => {
          drawingGroup.addWithUpdate(drawing);
        });
        if (element.position?.x) {
          drawingGroup.set({
            left: element.position.x,
            top: element.position.y,
            width: element.size.width,
            height: element.size.height,
            scaleX: element.scaleX,
            scaleY: element.scaleY,
            angle: element.rotation,
          });
        }
        drawingGroup.setCoords();

        this.addBrushEvents(drawingGroup);
        resolve();
      });
    },

    updateBrushElement(element) {
      // if (this.historyArray.length === 0 || this.isBrushing) {
      //   // this.$store.dispatch('canvas/history/saveHistory');
      // }

      const drawingGroup = this.findObjectById(element.id);
      if (!drawingGroup) {
        this.renderBrushElement(element);
        return false;
      }

      this.setObjectOption(drawingGroup, 'left', element.position.x);
      this.setObjectOption(drawingGroup, 'top', element.position.y);
      this.setObjectOption(drawingGroup, 'width', element.size.width);
      this.setObjectOption(drawingGroup, 'height', element.size.height);
      this.setObjectOption(drawingGroup, 'angle', element.rotation);
      this.setObjectOption(drawingGroup, 'scaleX', element.scaleX);
      this.setObjectOption(drawingGroup, 'scaleY', element.scaleY);

      this.canvas.renderAll();
      return true;
    },

    toggleFreeDrawing() {
      this.canvas.isDrawingMode = this.isBrushEnabled;
      if (this.isBrushEnabled) {
        this.canvas.freeDrawingBrush = new fabric.MgPencilBrush(this.canvas, {
          restrictionTop: this.drawingArea.top,
          restrictionLeft: this.drawingArea.left,
          restrictionRight: this.drawingArea.left + this.drawingArea.width,
          restrictionBottom: this.drawingArea.top + this.drawingArea.height,
        });
        this.setBrushColor();
        this.setBrushThickness();
      }
      return true;
    },

    cancelDraw() {
      const drawings = this.findObjectsByType(ELEMENT_TYPES.DRAWING);
      drawings.forEach(drawing => {
        this.canvas.remove(drawing);
      });
      this.$store.dispatch('canvas/elements/disableFreeDrawing');
      this.$store.dispatch('ui/setActiveSidebar', this.isDesktop ? SIDEBARS.LAYOUT : null);
    },

    applyDraw() {
      const drawings = this.findObjectsByType(ELEMENT_TYPES.DRAWING);
      if (drawings.length === 0) return false;

      const id = uuidv4();

      const drawingGroup = new fabric.Group([], {
        id,
        elementType: ELEMENT_TYPES.DRAWING_GROUP,
      });
      this.addClipPathToObject(drawingGroup);
      this.canvas.add(drawingGroup);
      drawings.forEach(drawing => {
        this.canvas.remove(drawing);
        drawingGroup.addWithUpdate(drawing);
      });

      this.addBrushEvents(drawingGroup);

      const element = {
        id,
        json: drawingGroup.toJSON(),
      };
      this.$store.dispatch('canvas/elements/addDrawingGroup', element);
      this.saveElementTransformations(drawingGroup, element);
      this.renderDrawingArea(drawingGroup);

      this.$store.dispatch('ui/setActiveSidebar', this.isDesktop ? SIDEBARS.LAYOUT : null);
      this.$store.dispatch('canvas/elements/disableFreeDrawing');
      this.$store.dispatch('canvas/elements/setIsFirstMainImageRender', false);
      return true;
    },

    addBrushEvents(brushElement) {
      brushElement.on('modified', event => {
        this.$store.dispatch('canvas/history/saveHistory');
        this.saveElementTransformations(event.target, brushElement);
        this.renderDrawingArea(event.target);
      });

      brushElement.on('selected', () => {
        this.$store.dispatch('canvas/elements/selectElement', brushElement.id);
        this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.BRUSH);
      });

      brushElement.on('mouseup', () => {
        this.$store.dispatch('canvas/history/saveHistory');
      });

      brushElement.on('mousedown', () => {
        if (this.$store.getters['ui/getActiveSidebar'] !== SIDEBARS.BRUSH && !this.isBrushEnabled) {
          this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.BRUSH);
        }
      });

      brushElement.on('deselected', () => {
        this.$store.dispatch('canvas/elements/selectElement', null);
        if (this.isActiveSidebarResetRequired) {
          this.$store.dispatch(
            'ui/setActiveSidebar',
            this.$store.getters['ui/isDesktop'] ? SIDEBARS.LAYOUT : null,
          );
        }
      });
    },

    setBrushColor() {
      this.canvas.freeDrawingBrush.color = rgba(this.brushColor);
    },

    setBrushThickness() {
      this.canvas.freeDrawingBrush.width = this.brushThickness;
    },
  },
};

export default Brush;
