export const rgba = function (color) {
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`;
};

export const rgbaToObj = function (color) {
  const arr = color.match(/\d+/g);
  return {
    r: parseInt(arr[0], 10),
    g: parseInt(arr[1], 10),
    b: parseInt(arr[2], 10),
    a: parseInt(arr[3], 10)
  };
};

export default {
  rgba,
  rgbaToObj
};
