<template>
  <div :class="`mg-bottom-toolbar ${isTemplateGenerationMode ? 'mg-bottom-toolbar-tg-mode' : ''}`">
    <div v-if="isTemplateGenerationMode" class="template-generation-alert">
      template generation mode activated
    </div>
    <div class="mg-bottom-toolbar__item">
      <ButtonGradientBackground
        :text-content="isDesktop ? 'Add Text' : 'add text'"
        icon-name="add-text-icon"
        :icon-position="isDesktop ? 'top' : 'inline-start'"
        :additional-basic-styles="isDesktop ? desktopButtonStyles.basic : mobileButtonStyles.basic"
        :additional-hover-styles="isDesktop ? desktopButtonStyles.hover : mobileButtonStyles.hover"
        :additional-icon-styles="isDesktop ? desktopButtonStyles.icon : mobileButtonStyles.icon"
        :click-handler="addText"
      />
    </div>

    <div class="mg-bottom-toolbar__item">
      <ButtonGradientBackground
        :text-content="isDesktop ? 'Add Image' : 'add image'"
        icon-name="add-image-icon"
        :icon-position="isDesktop ? 'top' : 'inline-start'"
        :additional-basic-styles="isDesktop ? desktopButtonStyles.basic : mobileButtonStyles.basic"
        :additional-hover-styles="isDesktop ? desktopButtonStyles.hover : mobileButtonStyles.hover"
        :additional-icon-styles="isDesktop ? desktopButtonStyles.icon : mobileButtonStyles.icon"
        :click-handler="showImageModal"
      />
    </div>

    <div class="mg-bottom-toolbar__item">
      <ButtonGradientBackground
        :text-content="isDesktop ? 'Add Brush' : 'add brush'"
        icon-name="add-brush-icon"
        :icon-position="isDesktop ? 'top' : 'inline-start'"
        :additional-basic-styles="isDesktop ? desktopButtonStyles.basic : mobileButtonStyles.basic"
        :additional-hover-styles="isDesktop ? desktopButtonStyles.hover : mobileButtonStyles.hover"
        :additional-icon-styles="isDesktop ? desktopButtonStyles.icon : mobileButtonStyles.icon"
        :click-handler="addBrush"
      />
    </div>

    <div v-if="isTemplateGenerationMode" class="mg-bottom-toolbar__item">
      <ButtonGradientBackground
        :text-content="'JSON'"
        :icon-position="'center'"
        :additional-basic-styles="isDesktop ? desktopButtonStyles.basic : mobileButtonStyles.basic"
        :additional-hover-styles="isDesktop ? desktopButtonStyles.hover : mobileButtonStyles.hover"
        :additional-icon-styles="isDesktop ? desktopButtonStyles.icon : mobileButtonStyles.icon"
        :click-handler="showJson"
        :disabled="elementsCount < 1"
      />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { SIDEBARS } from '../../../common/constants';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';

export default {
  name: 'BottomToolbar',

  components: {
    ButtonGradientBackground,
  },

  computed: {
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    isBrushEnabled() {
      return this.$store.getters['canvas/elements/isBrushEnabled'];
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
    elementsCount() {
      return this.$store.getters['canvas/elements/getElementsCount'];
    },
  },

  data() {
    return {
      desktopButtonStyles: {
        basic: {
          width: '100px',
          height: '70px',
          fontSize: '14px',
          fontWeight: '400',
        },
        hover: {},
        icon: {},
      },
      mobileButtonStyles: {
        basic: {
          width: '100px',
          height: '40px',
          fontSize: '12px',
          marginRight: '10px',
          background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
        },
        hover: {
          background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)',
        },
        icon: {
          height: '20px',
        },
      },
    };
  },

  methods: {
    addText() {
      const id = uuidv4();
      this.$store.dispatch('canvas/elements/addTextElement', id);
    },

    showJson() {
      this.$store.dispatch('canvas/history/setJsonModal', true);
    },

    addBrush() {
      this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.BRUSH);

      if (!this.isDesktop) {
        this.$store.dispatch('ui/openMobileSidebarModal');
      }

      const actionName = this.isBrushEnabled ? 'disableFreeDrawing' : 'enableFreeDrawing';
      this.$store.dispatch(`canvas/elements/${actionName}`);
      this.$store.dispatch('canvas/elements/selectElement', null);
    },

    showImageModal() {
      this.$store.dispatch('ui/openImageModal');
    },
  },
};
</script>
