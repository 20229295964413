import { queryPost } from './apiConfig';

export default {
  uploadPost(newPost) {
    const formData = new FormData();

    Object.keys(newPost).forEach(prop => {
      const value = newPost[prop];
      if (value !== null || value !== '') {
        if (Array.isArray(value)) {
          value.forEach(valueItem => {
            formData.append(`${prop}[]`, valueItem);
          });
        } else {
          formData.append(prop, value);
        }
      }
    });

    const localToken = localStorage.getItem('token');
    const config = {
      headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localToken}` },
    };

    return queryPost('/posts', formData, config).then(response => response.responseData.data.url);
  },
};
