import common from './common';
import layouts from './layouts';
import frames from './frames';
import elements from './elements';
import fonts from './fonts';
import watermark from './watermark';
import history from './history';

export default {
  namespaced: true,

  modules: {
    common,
    layouts,
    frames,
    elements,
    fonts,
    watermark,
    history,
  },
};
