<template>
  <div :class="`mg-top-toolbar ${isTemplateGenerationMode ? 'mg-top-toolbar-tg-mode' : ''}`">
    <div class="mg-top-toolbar__item ">
      <ButtonGradientOutline
        v-if="!isDesktop"
        :style="[{ visibility: activeElement ? 'visible' : 'hidden' }, { width: '60px' }]"
        :text-content="editElementTextContent"
        gradient-outline-style="primary-purple-blue"
        :clickHandler="editElement"
      />
    </div>

    <button type="button" class="mg-top-toolbar__item" v-on:click="onClone">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33317 6.00008H0.666504V24.6667C0.666504 26.1334 1.8665 27.3334 3.33317 27.3334H21.9998V24.6667H3.33317V6.00008ZM24.6665 0.666748H8.6665C7.19984 0.666748 5.99984 1.86675 5.99984 3.33341V19.3334C5.99984 20.8001 7.19984 22.0001 8.6665 22.0001H24.6665C26.1332 22.0001 27.3332 20.8001 27.3332 19.3334V3.33341C27.3332 1.86675 26.1332 0.666748 24.6665 0.666748ZM24.6665 19.3334H8.6665V3.33341H24.6665V19.3334ZM15.3332 18.0001H17.9998V12.6667H23.3332V10.0001H17.9998V4.66675H15.3332V10.0001H9.99984V12.6667H15.3332V18.0001Z"
          fill="#767676"
        />
      </svg>
    </button>

    <DelimiterIcon />

    <button
      type="button"
      class="mg-top-toolbar__item"
      v-on:click="undo"
      :disabled="undoHistoryArray <= 1"
    >
      <svg
        width="28"
        height="13"
        viewBox="0 0 28 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.6665 1.66659C11.1332 1.66659 7.93317 2.98659 5.4665 5.13325L0.666504 0.333252V12.3333H12.6665L7.83984 7.50659C9.69317 5.95992 12.0532 4.99992 14.6665 4.99992C19.3865 4.99992 23.3998 8.07992 24.7998 12.3333L27.9598 11.2933C26.1065 5.70659 20.8665 1.66659 14.6665 1.66659Z"
          fill="#767676"
        />
      </svg>
    </button>

    <DelimiterIcon />

    <button
      type="button"
      class="mg-top-toolbar__item"
      v-on:click="redo"
      :disabled="redoHistoryArray < 1"
    >
      <svg
        width="28"
        height="13"
        viewBox="0 0 28 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5332 5.13325C20.0666 2.98659 16.8666 1.66659 13.3332 1.66659C7.13322 1.66659 1.89322 5.70659 0.0532227 11.2933L3.19989 12.3333C4.59989 8.07992 8.59989 4.99992 13.3332 4.99992C15.9332 4.99992 18.3066 5.95992 20.1599 7.50659L15.3332 12.3333H27.3332V0.333252L22.5332 5.13325Z"
          fill="#767676"
        />
      </svg>
    </button>

    <DelimiterIcon />
    <button
      type="button"
      class="mg-top-toolbar__item"
      v-on:click="onCrop"
      :disabled="activeElement !== 'image'"
    >
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6668 18.9999H24.3335V8.33325C24.3335 6.86658 23.1335 5.66658 21.6668 5.66658H11.0002V8.33325H21.6668V18.9999ZM8.3335 21.6666V0.333252H5.66683V5.66658H0.333496V8.33325H5.66683V21.6666C5.66683 23.1333 6.86683 24.3333 8.3335 24.3333H21.6668V29.6666H24.3335V24.3333H29.6668V21.6666H8.3335Z"
          fill="#767676"
        />
      </svg>
    </button>

    <DelimiterIcon />

    <button type="button" class="mg-top-toolbar__item" v-on:click="onDelete">
      <svg
        width="20"
        height="24"
        viewBox="0 0 20 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.3337 8V21.3333H4.66699V8H15.3337ZM13.3337 0H6.66699L5.33366 1.33333H0.666992V4H19.3337V1.33333H14.667L13.3337 0ZM18.0003 5.33333H2.00033V21.3333C2.00033 22.8 3.20033 24 4.66699 24H15.3337C16.8003 24 18.0003 22.8 18.0003 21.3333V5.33333Z"
          fill="#767676"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';
import DelimiterIcon from '../../common/icons/DelimiterIcon.vue';

export default {
  name: 'TopToolbar',

  components: {
    DelimiterIcon,
    ButtonGradientOutline,
  },

  computed: {
    undoHistoryArray() {
      return this.$store.getters['canvas/history/getHistoryArray'].length;
    },
    redoHistoryArray() {
      return this.$store.getters['canvas/history/getRedoArray'].length;
    },
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement']?.type;
    },

    editElementTextContent() {
      // For Edit button, if decide to change button from 'Edit' to "Edit + text/image etc"
      // let elementName = '';
      // if (this.activeElement === 'sticker' || this.activeElement === 'image') {
      //   elementName = 'Image';
      // } else if (this.activeElement === 'text') {
      //   elementName = 'Text';
      // } else if (this.activeElement === 'drawingGroup') {
      //   elementName = 'Brush';
      // }

      return 'Edit';
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
    historyArray() {
      return this.$store.getters['canvas/history/getHistoryArray'];
    },
    isHistorying() {
      return this.$store.getters['canvas/history/getIsHistorying'];
    },
  },
  created() {
    window.addEventListener('keydown', e => {
      if (
        (e.ctrlKey &&
          e.code === 'KeyZ' &&
          !e.shiftKey &&
          this.undoHistoryArray >= 1 &&
          !this.isHistorying) ||
        (e.metaKey &&
          e.code === 'KeyZ' &&
          !e.shiftKey &&
          this.undoHistoryArray >= 1 &&
          !this.isHistorying)
      ) {
        this.undo();
      }
      if (
        (e.ctrlKey && e.shiftKey && e.code === 'KeyZ' && this.redoHistoryArray >= 1) ||
        (e.metaKey && e.shiftKey && e.code === 'KeyZ' && this.redoHistoryArray >= 1)
      ) {
        this.redo();
      }
    });
  },
  methods: {
    undo() {
      this.$store.dispatch('canvas/history/undoAction');
      const undoObj = _cloneDeep(this.historyArray[this.historyArray.length - 1]);
      if (this.historyArray.length > 0) {
        this.$store.dispatch('canvas/history/restoreFromHistory', undoObj);
      }
    },
    redo() {
      this.$store.dispatch('canvas/history/redoAction');
      const redoObj = _cloneDeep(this.historyArray[this.historyArray.length - 1]);
      if (this.historyArray.length > 0) {
        this.$store.dispatch('canvas/history/restoreFromHistory', redoObj);
      }
    },
    editElement() {
      if (!this.isDesktop && this.activeElement) {
        this.$store.dispatch('ui/openMobileSidebarModal');
      }
    },

    openCropModal() {
      this.$store.dispatch('ui/openCropModal');
    },

    onCrop() {
      this.$store.dispatch('ui/setCropModalOpen', true);
    },

    onDelete() {
      this.$emit('onDelete');
    },

    onClone() {
      this.$emit('onClone');
    },
  },
};
</script>
