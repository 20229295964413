<template>
  <div class="mg-layout-sidebar__item-container">
    <button
      type="button"
      class="mg-layout-sidebar__item"
      @click="onSelectLayout"
      @mouseenter="isDesktop ?  isOnHover = true : null"
      @mouseleave="isDesktop ?  isOnHover = false : null"
    >
      <img
        :src="require(`./../../../assets/svg-icons/layout-sidebar/${layoutName}${isOnHover || isActiveLayout ? '-active' : ''}-icon.svg`)"
        alt=""
      >
    </button>
  </div>
</template>

<script>

export default {
  name: 'LayoutSidebarItem',

  props: {
    layoutName: String,
    activeLayout: String
  },

  data() {
    return {
      isOnHover: false,
    };
  },

  computed: {
    isActiveLayout() {
      return this.layoutName === this.activeLayout;
    }
  },

  methods: {
    isDesktop () {
      return this.$store.getters['ui/isDesktop'];
    },

    onSelectLayout() {
      this.$emit('onSelectLayout', this.layoutName);
    },
  }
};
</script>
