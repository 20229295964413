<template>
  <div class="mg-main-toolbar__button-container" :style="{background: this.dynamicStyles.background}">
    <ButtonGradientBackground
      :text-content="startCase(sidebarName)"
      :icon-name="`${sidebarName}-icon`"
      :icon-hover-name="`${sidebarName}-hover-icon`"
      :icon-active-name="`${sidebarName}-active-icon`"
      icon-position="top"
      :is-active="isActiveButton"
      :additional-basic-styles="{
        background: this.dynamicStyles.background,
        width: '100%',
        height: 'auto',
        fontSize: '14px',
        fontWeight: '400',
        color: this.dynamicStyles.textColor
      }"
      :additional-hover-styles="{
        background: '#242424',
        color: '#27CAFE',
      }"
      :click-handler="onSelect"
    />
  </div>
</template>

<script>
import _startCase from 'lodash/startCase';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';

export default {
  name: 'MainToolbarItem',

  components: {
    ButtonGradientBackground
  },

  props: {
    sidebarName: String,
    isDesktop: Boolean,
  },

  computed: {
    isActiveButton () {
      return this.isDesktop ? this.$store.getters['ui/getActiveSidebar'] === this.sidebarName : false;
    },

    dynamicStyles () {
      const resultStyles = {
        textColor: '#767676',
        background: 'inherit'
      };

      if (this.isActiveButton) {
        resultStyles.textColor = '#ffffff';
        resultStyles.background = '#242424';
      }

      return resultStyles;
    }
  },

  methods: {
    onSelect() {
      this.$emit('onSelect', this.sidebarName);
    },

    startCase(value) {
      return _startCase(value);
    }
  },
};
</script>
