<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6562 2.40625C15.6562 1.56406 14.9672 0.875 14.125 0.875H1.875C1.03281 0.875 0.34375 1.56406 0.34375 2.40625V11.5938C0.34375 12.4359 1.03281 13.125 1.875 13.125H14.125C14.9672 13.125 15.6562 12.4359 15.6562 11.5938V2.40625ZM14.125 2.40625L8 6.23438L1.875 2.40625H14.125ZM14.125 11.5938H1.875V3.9375L8 7.76562L14.125 3.9375V11.5938Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'EmailIcon'
};
</script>
