const CanvasFunctions = {
  methods: {
    removeObjectById(id) {
      const object = this.findObjectById(id);
      if (object) {
        this.canvas.remove(object);
      }
    },

    findObjectById(id) {
      return this.canvas.getObjects().find(object => object.id === id);
    },

    findObjectsByType(type) {
      return this.canvas.getObjects().filter(object => object.elementType === type);
    },

    setObjectOption(element, field, value) {
      if (value !== null && value !== undefined) {
        element.set(field, value);
      }
    },
  },
};

export default CanvasFunctions;
