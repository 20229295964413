<template>
  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.00033 2.31917L8.91699 4.94417V9.5H7.75033V6H4.25033V9.5H3.08366V4.94417L6.00033 2.31917ZM6.00033 0.75L0.166992 6H1.91699V10.6667H5.41699V7.16667H6.58366V10.6667H10.0837V6H11.8337L6.00033 0.75Z" fill="#BDBDBD"/>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon'
};
</script>
