import { v4 as uuidv4 } from 'uuid';
import { ELEMENT_TYPES, MOVE_STEP } from '../../../../common/constants';

const Elements = {
  computed: {
    elements() {
      return this.$store.getters['canvas/elements/getElements'];
    },

    activeElementId() {
      return this.$store.getters['canvas/elements/getActiveElementId'];
    },
    getDrawingAreaSize() {
      return this.$store.getters['canvas/common/getDrawingAreaSize'];
    },

    isActiveElementMainImage() {
      return this.elements.entities[this.activeElementId].main;
    },
    brushHistorying() {
      return this.$store.getters['canvas/history/getBrushHistorying'];
    },
    historyRedo() {
      return this.$store.getters['canvas/history/getRedoArray'];
    },
    lastHistoryElement() {
      const historyElement = this.$store.getters['canvas/history/getHistoryArray'];
      const lastElements = { ...historyElement[historyElement.length - 1] };
      return lastElements;
    },
    isHistorying() {
      return this.$store.getters['canvas/history/getIsHistorying'];
    },
  },

  watch: {
    elements: {
      deep: true,
      handler() {
        this.clearNotExistingElements();
        this.updateElements();
      },
    },

    brushHistorying: {
      handler() {
        this.updateElements();
        this.$store.dispatch('canvas/history/setBrushHistorying', false);
      },
    },

    activeElementId: {
      handler() {
        this.selectElement();
      },
    },
  },
  methods: {
    clearNotExistingElements() {
      const dontClearElementTypes = [ELEMENT_TYPES.DRAWING_AREA, ELEMENT_TYPES.FRAME];
      const notExistingElements = this.canvas
        .getObjects()
        .filter(object => !dontClearElementTypes.includes(object.elementType))
        .filter(object => !this.elements.ids.includes(object.id));

      notExistingElements.forEach(object => {
        this.canvas.remove(object);
      });
    },

    renderElements() {
      this.isRenderingElements = true;
      let textElementIndex = -1;
      const promises = this.elements.ids.map(elementId => {
        const element = this.elements.entities[elementId];
        this.removeObjectById(element.id);

        if (element.type === ELEMENT_TYPES.IMAGE && element.main) {
          return this.renderImage(element, true);
        }

        if (element.type === ELEMENT_TYPES.IMAGE && !element.main) {
          return this.renderImage(element, false);
        }

        if (element.type === ELEMENT_TYPES.TEXT) {
          textElementIndex += 1;
          return this.renderTextElement(element, textElementIndex);
        }

        if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
          return this.renderBrushElement(element);
        }

        return Promise.resolve();
      });

      Promise.all(promises).then(() => {
        this.canvas.renderAll();
        this.isRenderingElements = false;
      });
    },

    updateElements() {
      if (this.isRenderingElements) return false;
      this.elements.ids.forEach(elementId => {
        const element = this.elements.entities[elementId];
        if (element.type === ELEMENT_TYPES.IMAGE && element.main) {
          this.updateImage(element, true);
        } else if (element.type === ELEMENT_TYPES.IMAGE && !element.main) {
          this.updateImage(element, false);
        } else if (element.type === ELEMENT_TYPES.STICKER) {
          this.updateImage(element, false);
        } else if (element.type === ELEMENT_TYPES.TEXT) {
          this.updateTextElement(element);
        } else if (element.type === ELEMENT_TYPES.DRAWING_GROUP) {
          this.updateBrushElement(element);
        }
      });

      this.moveLayers();

      return true;
    },

    moveLayers() {
      const fixedElementTypes = [
        ELEMENT_TYPES.DRAWING_AREA,
        ELEMENT_TYPES.FRAME,
        ELEMENT_TYPES.MAIN_IMAGE,
      ];

      const startIndex = this.canvas
        .getObjects()
        .findIndex(object => !fixedElementTypes.includes(object.elementType));

      this.elements.ids.forEach((id, i) => {
        const object = this.findObjectById(id);
        const element = this.elements.entities[id];
        const isMainImg = element.type === ELEMENT_TYPES.IMAGE && element.main;
        if (object && !isMainImg) {
          object.moveTo(startIndex + i);
        }
      });
    },

    saveElementTransformations(canvasObject, element) {
      const options = {
        left: canvasObject.left,
        top: canvasObject.top,
        width: canvasObject.width,
        height: canvasObject.height,
        scaleX: canvasObject.scaleX,
        scaleY: canvasObject.scaleY,
        angle: canvasObject.angle,
      };
      this.$store.dispatch('canvas/elements/setElementTransformations', {
        id: element.id,
        options,
      });
    },

    selectElement() {
      if (!this.activeElementId) {
        this.canvas.discardActiveObject();
        this.canvas.renderAll();
        return false;
      }

      const object = this.findObjectById(this.activeElementId);
      const activeObject = this.canvas.getActiveObject();

      if (this.isActiveElementMainImage) {
        // changed to true because we had changed background resizing to true
        object.hasControls = true;
      }

      if (!activeObject || (activeObject && activeObject.id !== object.id)) {
        this.canvas.setActiveObject(object);
      }

      return true;
    },

    deselectElement() {
      const activeObject = this.canvas.getActiveObject();

      if (activeObject) {
        this.canvas.discardActiveObject().renderAll();
      }
    },

    deleteActiveElement() {
      if (!this.activeElementId) return false;

      const element = this.elements.entities[this.activeElementId];
      if (element.type === ELEMENT_TYPES.IMAGE && element.main && this.elements.ids.length === 1) {
        this.$store.dispatch('canvas/history/resetHistoryAction');
        // this.$store.dispatch('canvas/history/saveHistory');
      }

      const object = this.findObjectById(this.activeElementId);
      if (!object) return false;

      this.canvas.remove(object);
      this.$store.dispatch('canvas/elements/deleteElement', object.id);
      this.$store.dispatch('canvas/history/saveHistory');
      return true;
    },

    cloneActiveElement() {
      if (!this.activeElementId) return false;

      const element = this.elements.entities[this.activeElementId];
      if (element.type === ELEMENT_TYPES.IMAGE && element.main) return false;

      const newId = uuidv4();
      this.$store.dispatch('canvas/elements/cloneElement', {
        id: element.id,
        newId,
      });
      this.$store.dispatch('canvas/elements/selectElement', newId);
      this.$store.dispatch('canvas/history/saveHistory');
      return true;
    },

    clearElements() {
      this.canvas.clear();
    },

    getTransformationsFromElement(element) {
      return {
        left: element.position.x,
        top: element.position.y,
        width: element.size.width,
        height: element.size.height,
        angle: element.rotation,
        scaleX: element.scaleX,
        scaleY: element.scaleY,
      };
    },

    moveElement(event) {
      const activeObject = this.canvas.getActiveObject();

      if (activeObject) {
        if (event.code === 'ArrowLeft') {
          activeObject.left -= MOVE_STEP;
        } else if (event.code === 'ArrowUp') {
          activeObject.top -= MOVE_STEP;
        } else if (event.code === 'ArrowRight') {
          activeObject.left += MOVE_STEP;
        } else if (event.code === 'ArrowDown') {
          activeObject.top += MOVE_STEP;
        }

        this.canvas.renderAll();
      }
    },
  },
};

export default Elements;
