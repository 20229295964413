import { queryGet, queryPost } from './apiConfig';

export default {
  saveMeme(memeId, image, data) {
    // log meme data
    if (memeId) {
      console.log('Create meme', JSON.parse(JSON.stringify(data)));
    } else {
      console.log('Edit meme:', memeId, JSON.parse(JSON.stringify(data)));
    }

    const url = memeId ? `/generator/memes/${memeId}` : '/generator/memes';

    const localToken = localStorage.getItem('token');

    let headersData = {};

    if (localToken) {
      headersData = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localToken}`,
      };
    } else {
      headersData = {
        'Content-Type': 'application/json',
      };
    }

    return queryPost(
      url,
      {
        meme_data: data,
        file: image,
      },
      {
        headers: headersData,
      },
    );
  },

  openMeme(memeId) {
    return queryGet(`/generator/memes/${memeId}`);
  },

  saveAsset(data) {
    return queryPost('/generator/assets', { data });
  },

  getMemes(page) {
    const localToken = localStorage.getItem('token');

    return queryGet('/generator/memes', {
      params: { page },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localToken}`,
      },
    });
  },

  getTemplates(page, type, options) {
    return queryGet(`${process.env.VUE_APP_API_URL}/api/generator/templates`, {
      params: {
        page,
        type,
        search: options.search,
      },
    });
  },
};
