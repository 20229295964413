<template>
    <img :src="imageUrl" alt="Result meme">
</template>

<script>
export default {
  name: 'FinalMemePreview',

  props: {
    imageUrl: String
  }
};
</script>
