/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable no-const-assign */
/* eslint-disable object-curly-newline */
import { fabric } from 'fabric';
import { calculateRatio } from '../../../common/utils';
import { ELEMENT_TYPES } from '../../../common/constants';

const DrawingArea = {
  computed: {
    layoutFromQuery() {
      return this.$store.getters['canvas/layouts/getLayoutFromQuery'];
    },
    aspectRatio() {
      return this.$store.getters['canvas/common/getAspectRatio'];
    },

    originalRatio() {
      return this.$store.getters['canvas/common/getOriginalRatio'];
    },

    frames() {
      return this.$store.getters['canvas/frames/getFrames'];
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },

    isFromTemplate() {
      return this.$store.getters['canvas/elements/isFromTemplate'];
    },
    elementsObj() {
      return this.$store.getters['canvas/elements/getElements'];
    },
    currentTopLeftDrawingArea() {
      return this.$store.getters['canvas/elements/getCurrentTopLeftDrawingArea'];
    },
    isFirstRenderImage() {
      return this.$store.getters['canvas/elements/isFirstMainImageRender'];
    },
    drawingCounter() {
      return this.$store.getters['canvas/elements/getDrawingCounter'];
    },
    historyArray() {
      return this.$store.getters['canvas/history/getHistoryArray'];
    },
    historyRedoArray() {
      return this.$store.getters['canvas/history/getRedoArray'];
    },
    onlyElementsFromStore() {
      const data = this.$store.getters['canvas/elements/getOnlyElement'];
      const newArray = [];
      for (let key in data) {
        newArray.push(data[key]);
      }
      return JSON.stringify(newArray);
    },
    lastHistoryElement() {
      if (this.historyArray.length > 0) {
        return JSON.stringify(this.historyArray[this.historyArray.length - 1].elements);
      }
    },
    lastRedoArray() {
      if (this.historyRedoArray.length > 0) {
        return JSON.stringify(this.historyRedoArray[this.historyRedoArray.length - 1].elements);
      }
    },
    isCropping() {
      return this.$store.getters['canvas/elements/getCroppingValue'];
    },
    isBrushing() {
      return this.$store.getters['canvas/elements/isBrushing'];
    },
    activeSidebar() {
      return this.$store.getters['ui/getActiveSidebar'];
    },
    getIsHistorying() {
      return this.$store.getters['canvas/history/getIsHistorying'];
    },
    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement'];
    },
    isDeselectedText() {
      return this.$store.getters['canvas/elements/getIsDeselectedText'];
    },
    frameColor() {
      return this.$store.getters['canvas/frames/getFrameColor'];
    },
  },
  watch: {
    isFromTemplate: {
      handler() {
        this.renderElements();
      },
    },
    activeElement: {
      handler() {
        if (!this.activeElement) {
          this.$store.dispatch('canvas/fonts/setTextFontModal', true);
        }
      },
    },
    frameColor: {
      handler() {
        this.drawFrames();
      },
    },

    aspectRatio: {
      handler() {
        this.callAspectRatioWatchHandler();
        this.renderElements();
      },
      flush: 'post',
    },
    originalRatio: {
      handler() {
        this.callAspectRatioWatchHandler();
      },
    },
    elementsObj: {
      deep: true,
      handler() {
        if (
          this.onlyElementsFromStore !== this.lastHistoryElement &&
          this.isFirstRenderImage &&
          !this.isCropping &&
          !this.getIsHistorying &&
          this.activeElement?.type !== ELEMENT_TYPES.TEXT &&
          this.isDeselectedText
        ) {
          this.$store.dispatch('canvas/history/saveHistory');
          this.callAspectRatioWatchHandler();
        }
      },
    },
    isCropping: {
      handler() {
        // After closing cropp invoke this function
        if (!this.isCropping) {
          this.renderElements();
          this.callAspectRatioWatchHandler();
        }
      },
    },
    frames: {
      deep: true,
      handler() {
        this.drawFrames();
        // this.$store.dispatch('canvas/history/saveHistory');
      },
    },
    isBrushing: {
      handler() {
        this.$store.dispatch('canvas/history/saveHistory');
      },
    },
    isFirstRenderImage: {
      handler() {
        this.renderDrawingArea();
      },
    },
  },
  created() {
    if (!this.layoutFromQuery) {
      this.$store.dispatch('canvas/layouts/setActiveLayout', 'layout-1');
    }
    // this.$store.dispatch('canvas/history/saveHistory');
  },
  mounted() {
    this.$store.dispatch('canvas/layouts/setLayoutFromQuery', false);
  },
  methods: {
    callAspectRatioWatchHandler() {
      this.renderDrawingArea();
      this.drawFrames();
      // this.renderElements();
    },

    getDrawingAreaSizes() {
      const ratio =
        this.aspectRatio === 'original' ? this.originalRatio : calculateRatio(this.aspectRatio);
      // hard-code sizes for toolbars =(
      const topToolbarSize = this.isDesktop ? 96 : 67;
      const bottomToolbarSize = 134;
      const toolbarsSpace = topToolbarSize + bottomToolbarSize;
      // To better display images use sidePadding
      const sidePaddings = 30;
      let width;
      let height;

      const lookaheadWidth = this.canvas.width - sidePaddings;
      const lookaheadHeight = lookaheadWidth / ratio;
      const availableHeight = this.canvas.height - toolbarsSpace;

      if (lookaheadHeight < availableHeight) {
        width = this.canvas.width - sidePaddings;
        height = width / ratio;
      } else {
        height = availableHeight;
        width = height * ratio;
      }

      return {
        sidePaddings,
        topToolbarSize,
        toolbarsSpace,
        size: { height, width },
        ratio,
        aspectRatio: this.aspectRatio,
      };
    },

    renderDrawingArea() {
      const {
        topToolbarSize,
        size,
        ratio,
        toolbarsSpace,
        sidePaddings,
        aspectRatio,
      } = this.getDrawingAreaSizes();
      this.clearNotExistingElements();
      // adding drawingArea sizes to store
      const isDrawingAreaExist = this.findObjectsByType(ELEMENT_TYPES.DRAWING_AREA).length > 0;
      if (!isDrawingAreaExist) {
        this.drawingArea = new fabric.Rect({
          elementType: ELEMENT_TYPES.DRAWING_AREA,
          fill: '#fff',
          selectable: false,
        });

        this.canvas.add(this.drawingArea);
      }
      let leftDrawingAreaPosition = (this.canvas.width - size.width) / 2;
      let topDrawingAreaPosition = topToolbarSize;
      this.$store.dispatch('canvas/elements/addCurrentTopLeftDrawingArea', {
        left: leftDrawingAreaPosition,
        top: topDrawingAreaPosition,
        toolbarsSpace,
        sidePaddings,
        ratio,
        aspectRatio,
        topToolbarSize,
        size,
      });
      this.$store.dispatch(
        'canvas/elements/addCurrentDrawingAreaSizes',
        this.currentTopLeftDrawingArea.size,
      );
      if (this.isFromTemplate) {
        this.calibrateElementsFromTemplate(leftDrawingAreaPosition, topDrawingAreaPosition);
      }
      // Calibrate images for all screen sizes
      if (!this.isTemplateGenerationMode && !this.getIsHistorying) {
        const elements = Object.values(this.elementsObj.entities);
        if (elements.length > 0 && elements[0].position.x !== null) {
          elements.forEach(element => {
            if (element.type !== ELEMENT_TYPES.DRAWING_GROUP) {
              const options = {
                left: element.position.x,
                top: element.position.y,
                width: element.size.width,
                height: element.size.height,
                scaleX: element.scaleX,
                scaleY: element.scaleY,
                angle: element.rotation,
              };
              this.$store.dispatch('canvas/elements/setElementTransformations', {
                id: element.id,
                options,
              });
            }
          });
        }
      }
      // set drawing area in center
      this.drawingArea.set({
        ...this.currentTopLeftDrawingArea.size,
        left: this.isTemplateGenerationMode ? 0 : leftDrawingAreaPosition,
        top: this.isTemplateGenerationMode ? 0 : topDrawingAreaPosition,
      });
      this.$store.dispatch('canvas/common/setCanvasSize', size);
      this.canvas.renderAll();
    },

    addClipPathToObject(object) {
      /* eslint-disable no-param-reassign */
      object.clipPath = new fabric.Rect({
        width: this.drawingArea.width,
        height: this.drawingArea.height,
        left: this.drawingArea.left,
        top: this.drawingArea.top,
        absolutePositioned: true,
      });
      /* eslint-enable no-param-reassign */
    },

    calibrateElementsFromTemplate(left, top) {
      console.log('calibrate');
      this.$store.dispatch('canvas/elements/setIsFromTemplate', false);
      const elementsObj = this.$store.getters['canvas/elements/getElements'];
      const elements = Object.values(elementsObj.entities);
      if (elements.length) {
        elements.forEach(element => {
          const options = {
            left: element.position.x + left,
            top: element.position.y + top,
          };
          this.$store.dispatch('canvas/elements/calibrateElementPosition', {
            id: element.id,
            options,
          });
        });
      }
    },
  },
};

export default DrawingArea;
