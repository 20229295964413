<template>
  <div class="mg-post-meme__share-buttons">
    <a
      class="mg-post-meme__share-button"
      v-for="item of SHARE_ITEMS"
      :key="item.name"
      :href="shareUrl(item)"
      target="_blank"
    >
      <component :is="`${startCase(item.name)}Icon`"/>
    </a>
  </div>
</template>

<script>
import _startCase from 'lodash/startCase';
import { SHARE_ITEMS } from '../../common/constants';
import FacebookIcon from '../common/icons/share-items/FacebookIcon.vue';
import PinterestIcon from '../common/icons/share-items/PinterestIcon.vue';
import TwitterIcon from '../common/icons/share-items/TwitterIcon.vue';
import RedditIcon from '../common/icons/share-items/RedditIcon.vue';
import EmailIcon from '../common/icons/share-items/EmailIcon.vue';

export default {
  name: 'ShareButtons',

  components: {
    FacebookIcon,
    PinterestIcon,
    TwitterIcon,
    RedditIcon,
    EmailIcon
  },

  props: {
    memeUrl: {
      type: String,
      required: true
    },
    memeImageUrl: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      SHARE_ITEMS
    };
  },

  computed: {
    shareUrl () {
      // eslint-disable-next-line
      return (shareItem) => {
        if (shareItem.name === 'pinterest') {
          return `${shareItem.link}${this.memeUrl}&media=${this.memeImageUrl}`;
        }

        if (shareItem.name === 'email') {
          return `mailto:?subject=I wanted you to see this site&body=Check out this site ${this.memeUrl}.`;
        }

        return shareItem.link + this.memeUrl;
      };
    }
  },

  methods: {
    startCase(value) {
      return _startCase(value);
    }
  }
};
</script>
