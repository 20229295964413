<template>
  <div :style="style" class="font-style" v-on:click="changeFontFamily">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'font-types',
  props: {
    func: {
      type: Function,
    },
    fontFamilyName: String,
  },
  computed: {
    style() {
      return `font-family: ${this.fontFamilyName}`;
    },
    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement'];
    },
  },
  methods: {
    changeFontFamily() {
      this.$store.dispatch('canvas/elements/changeFontFamily', {
        id: this.activeElement.id,
        family: this.fontFamilyName,
      });
      this.$store.dispatch('canvas/history/saveHistory');
      this.$store.dispatch('canvas/fonts/setTextFontModal', true);
      this.$store.dispatch('ui/closeMobileSidebarModal', true);
    },
  },
};
</script>

<style scoped>
.font-style {
  box-sizing: border-box;
  padding: 5px;
  font-size: 1.45rem;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
}
.font-style:hover {
  background-image: linear-gradient(135deg, rgb(148, 97, 254) 0%, rgb(20, 225, 218) 100%);
  color: white;
}
</style>
