<template>
  <div>
    <backdrop :clicked="handler"></backdrop>
    <div class="watermark_modal_page">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Backdrop from '../image-dialog/Backdrop.vue';

export default {
  name: 'watermarkModal',
  components: { Backdrop },
  props: {
    handler: Function,
  },
  computed: {
    isProAccount() {
      const user = this.$store.getters['auth/getUser'];
      return user === undefined || user === null ? 0 : user.is_pro_active;
    },

    isWatermarkEnabled: {
      get() {
        return this.$store.getters['canvas/watermark/isWatermarkEnabled'];
      },
      set(value) {
        this.$store.dispatch('canvas/watermark/toggleWatermark', value);
      },
    },
  },

  data() {
    return {
      isUpgradeAccountModalOpen: false,
    };
  },

  methods: {
    clickWatermarkCheckboxHandler() {
      if (!this.isProAccount) {
        this.isUpgradeAccountModalOpen = true;
      }
    },

    closeUpgradeAccountModal() {
      this.isUpgradeAccountModalOpen = false;
    },
  },
};
</script>

<style>
.watermark_modal_page {
  position: fixed;
  z-index: 500;
  background-color: #323232;
  width: 30%;
  padding: 16px;
  left: 35%;
  top: 35%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  height: auto;
  border-radius: 10px;
}
@media (max-width: 500px) {
  .watermark_modal_page {
    width: 80%;
    left: 10%;
    top: 30%;
  }
}
</style>
