<template>
  <div class="mg-start-page">
    <section class="mg-start-page__logo-section">
      <div class="mg-start-page__logo-wrapper">
        <img src="../assets/svg-icons/start-page/memehead-logo-big.svg" alt="Memehead logo" />
        <div class="mg-start-page__logo-text">Create New Meme</div>
      </div>
    </section>

    <section class="mg-start-page__controls-section">
      <div class="mg-start-page__controls-section-left-block">
        <ButtonGradientBackground
          text-content="Browse Meme Templates"
          :additional-basic-styles="{
            background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
            width: '306px',
            height: '64px',
            fontSize: '16px',
          }"
          :additional-hover-styles="{
            background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)',
          }"
          :clickHandler="onBrowseMemeTemplatesClick"
        />

        <ButtonGradientBackground
          text-content="Start With Empty Canvas"
          :additional-basic-styles="{
            background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
            width: '306px',
            height: '64px',
            fontSize: '16px',
          }"
          :additional-hover-styles="{
            background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)',
          }"
          :clickHandler="onStartWithEmptyClick"
        />
      </div>

      <div class="mg-start-page__controls-section-center-block">
        <img src="../assets/svg-icons/start-page/buttons/or-button.svg" alt="Or" />
      </div>

      <div class="mg-start-page__controls-section-right-block">
        <UploadImages />
      </div>
    </section>
  </div>
</template>

<script>
import UploadImages from '../components/start-page/StartPageUploadArea.vue';
import ButtonGradientBackground from '../components/common/ButtonGradientBackground.vue';
import { SIDEBARS } from '../common/constants';

export default {
  name: 'StartPage',
  components: {
    UploadImages,
    ButtonGradientBackground,
  },

  computed: {
    templateById() {
      return this.$store.getters['template/getTemplateData'];
    },
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },
  },

  methods: {
    onBrowseMemeTemplatesClick() {
      this.$router.push('editor');
      this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.TEMPLATES);

      if (!this.isDesktop) {
        this.$store.dispatch('ui/openMobileSidebarModal');
      }
    },

    onStartWithEmptyClick() {
      this.$router.push('editor');
    },
  },
};
</script>
