<template>
 <ButtonGradientOutline
   :text-content="name"
   :gradient-outline-style="isActiveTab ? 'thirdly--active' : 'thirdly'"
   :additional-basic-styles="{
     width: '120px'
   }"
   :click-handler="onTabClick"
 />
</template>

<script>
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';

export default {
  name: 'ImageDialogTabItem',

  components: { ButtonGradientOutline },

  props: {
    name: String,
    activeTab: String
  },

  computed: {
    isActiveTab () {
      return this.name === this.activeTab;
    }
  },

  methods: {
    onTabClick() {
      this.$emit('tabClicked', this.name);
    }
  }
};
</script>
