<template>
  <div>
    <div class="mg-post-meme-loader" v-if="isPending">
      <b-spinner variant="light" />
    </div>

    <div class="mg-post-meme-container" v-else>
      <section class="mg-post-meme-container__meme-section">
        <FinalMemePreview :image-url="imageUrl"/>
      </section>

      <section class="mg-post-meme-container__controls-section">

        <div class="mg-post-meme-container__description-block" v-show="step === CREATE_POST_STEPS.TITLE">

          <div class="mg-post-meme-container__description-block__description-container">
            <h2>Give your post a title</h2>
            <p>An accurate, descriptive title cam help people discover your post.</p>

            <div class="post-meme-description-field">
              <textarea
                id="post-meme-description"
                v-model.trim="$v.description.$model"
                :class="getValidationClass($v, 'description')"
                placeholder="Enter description"
                ref="descriptionInput"
              ></textarea>

              <div class="description-message-error-container">
                <div class="message-error" v-if="$v.description.$error">Description is required</div>
              </div>

              <UserMentionList
                v-if="$refs.descriptionInput"
                :input="description"
                :element="$refs.descriptionInput"
                @userClicked="mentionClicked"
              />
            </div>

            <p>Add tags</p>
            <Multiselect
              v-model="tags"
              placeholder="Enter tags"
              label="name"
              :options="tagOptions"
              track-by="name"
              :multiple="true"
              :searchable="true"
              :loading="isLoadingTags"
              :internal-search="false"
              :clear-on-select="false"
              :close-on-select="false"
              :options-limit="6"
              :limit="10"
              :max="10"
              :max-height="600"
              :show-no-results="false"
              :hide-selected="true"
              :taggable="true"
              tag-position="bottom"
              no-options="No tags found"
              @search-change="findTag"
              @tag="addTag"
            />

            <div class="mg-post-meme-container__description-block__checkbox-container">
              <div class="mg-custom-checkbox">
                <b-form-checkbox size="lg" v-model="is_sensitive">
                  <span>This is sensitive</span>
                </b-form-checkbox>
              </div>

              <div class="mg-custom-checkbox" v-if="!isDesktop">
                <b-form-checkbox size="lg">
                  <span>Attribute original poster</span>
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="mg-post-meme-container__section-block" v-show="step === CREATE_POST_STEPS.SECTION">
          <div>
            <h2>Choose a section</h2>
            <p>Submitting to the right section to make sure your post gets the right exposure it deserves!</p>

            <p>Add tags</p>
          </div>

          <div class="mg-post-meme-container__section-block__tags-container">
            <TagCheckboxItem
              v-for="categoryItem of CATEGORIES"
              :key="`category-${categoryItem.slug}`"
              :title="categoryItem.name"
              :slug="categoryItem.slug"
              :active-category-slug="$v.category.$model"
              @onSelectCategory="onSelectCategory"
            />
          </div>
          <div class="section-message-error-container">
            <div class="message-error" v-if="$v.category.$error">Section is required</div>
          </div>
        </div>

        <div v-if="!isPostPending" class="mg-post-meme-container__description-block__buttons-container">
          <ButtonGradientOutline
            text-content="BACK"
            gradient-outline-style="primary-pink-bg-dark"
            :additional-basic-styles="{
              width: '175px',
              height: '70px',
            }"
            :click-handler="onBackClick"
          />

          <ButtonGradientBackground
            :text-content="step === CREATE_POST_STEPS.SECTION ? 'POST' : 'NEXT'"
            :additional-basic-styles="{
              background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
              width: '175px',
              height: '62px',
              fontSize: '14px'
            }"
            :additional-hover-styles="{
              background: 'linear-gradient(93.79deg, #3AD192 37.36%, #28CBFF 84.4%, #129F64 107.5%)',
            }"
            :click-handler="onNextClick"
          />
        </div>

        <div v-if="isPostPending">
          <b-spinner variant="light" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import { required } from 'vuelidate/lib/validators';
import debounce from 'lodash/debounce';
import ButtonGradientOutline from '../components/common/ButtonGradientOutline.vue';
import ButtonGradientBackground from '../components/common/ButtonGradientBackground.vue';
import FinalMemePreview from '../components/post-meme/FinalMemePreview.vue';
import UserMentionList from '../components/post-meme/UserMentionList.vue';
import TagCheckboxItem from '../components/post-meme/TagCheckboxItem.vue';
import TagsAPI from '../api/tagsApi';
import { CATEGORIES, CREATE_POST_STEPS } from '../common/constants';
import { apiUrl } from '../api/apiConfig';

export default {
  name: 'PostMemeTitle',

  components: {
    UserMentionList,
    FinalMemePreview,
    ButtonGradientOutline,
    ButtonGradientBackground,
    Multiselect,
    TagCheckboxItem
  },

  data () {
    return {
      CREATE_POST_STEPS,
      CATEGORIES,
      step: CREATE_POST_STEPS.TITLE,
      description: '',
      tags: [],
      tagOptions: [],
      isLoadingTags: false,
      category: '',
      is_sensitive: false
    };
  },

  validations: {
    description: {
      required,
    },
    category: {
      required
    }
  },

  computed: {
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    memeId () {
      return this.$route.params.memeId;
    },

    isMemeDataEmpty () {
      return this.$store.getters['meme/isMemeDataEmpty'];
    },

    imageUrl () {
      return this.$store.getters['meme/getMemeImageUrl'];
    },

    isPending () {
      return this.$store.getters['meme/getIsPending'];
    },

    isPostPending () {
      return this.$store.getters['meme/getIsPostPending'];
    }
  },

  created () {
    if (this.isMemeDataEmpty) {
      this.$store.dispatch('meme/loadMeme', this.memeId);
    }
  },

  methods: {
    onNextClick () {
      if (this.step === CREATE_POST_STEPS.TITLE) {
        this.$v.description.$touch();

        if (!this.$v.description.$invalid) {
          this.step = CREATE_POST_STEPS.SECTION;
        }
      } else {
        this.$v.category.$touch();

        if (!this.$v.category.$invalid) {
          this.createPost();
        }
      }
    },

    onBackClick () {
      if (this.step === CREATE_POST_STEPS.SECTION) {
        this.step = CREATE_POST_STEPS.TITLE;
      } else {
        this.$router.push(`/post-meme-tools/${this.memeId}`);
      }
    },

    getValidationClass(validation, field) {
      return {
        error: validation[field].$error,
        dirty: validation[field].$dirty
      };
    },

    mentionClicked (user) {
      const words = this.description.split(' ');
      if (words.length) {
        words.pop();
      }

      words.push(`@${user.username} `);
      this.description = words.join(' ');
      this.$refs.descriptionInput.focus();
    },

    findTag: debounce(async function (query) {
      if (query.length < 2) {
        this.tagOptions = [];
        return;
      }

      if (this.isLoadingTags) {
        return;
      }

      this.isLoadingTags = true;
      this.tagOptions = await TagsAPI.findTags(query);
      this.isLoadingTags = false;
    }, 400),

    addTag(newTag) {
      if (newTag.length < 2) {
        return;
      }

      if (this.tags.filter((tag) => tag.name === newTag).length > 0) {
        return;
      }

      const condition = /^[a-zA-Z0-9]+$/i;
      if (!condition.test(newTag)) {
        return;
      }
      this.tagOptions.push({ name: newTag });
      this.tags.push({ name: newTag });
    },

    onSelectCategory (slug) {
      this.$v.category.$touch();
      this.category = slug === this.category ? null : slug;
    },

    createPost () {
      const post = {
        description: this.description,
        category: this.category,
        tags: this.tags.map((tag) => tag.name),
        is_sensitive: this.is_sensitive,
        type: 3,
        meme_id: this.memeId
      };

      this.$store.dispatch('meme/uploadPost', post).then((url) => {
        if (url) {
          window.location.href = `${apiUrl}${url}`;
        }

        return true;
      });
    }
  }
};
</script>
