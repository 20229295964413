<template>
  <div class="mg-layout-sidebar-container">
    <LayoutSidebarItem
      v-for="layout in layouts"
      :key="`${layout.name}-item`"
      :layout-name="layout.name"
      :activeLayout="activeLayout"
      @onSelectLayout="onSelectLayout"
    />
  </div>
</template>

<script>
import LayoutSidebarItem from './LayoutSidebarItem.vue';

export default {
  name: 'LayoutSidebar',

  components: { LayoutSidebarItem },

  props: {
    activeLayout: String,
  },

  computed: {
    layouts() {
      return this.$store.getters['canvas/layouts/getLayoutsShort'];
    },
  },

  methods: {
    onSelectLayout(value) {
      const payload = {
        desktop: {
          actionName: 'canvas/layouts/setActiveLayout',
          value,
        },
        mobile: {
          actionName: 'canvas/layouts/setLayoutOptionsGroup',
          value: {
            path: 'activeLayout',
            value,
          },
        },
      };

      this.$emit('settingChanged', payload);
    },
  },
};
</script>
