var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"hide-footer":"","hide-header":"","size":"lg","centered":"","dialog-class":"mg-image-dialog","body-class":"mg-image-dialog__main-modal"},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('CloseModalButton',{attrs:{"click-handler":_vm.closeMainModal}}),_c('div',{staticClass:"mg-image-dialog__tabs-container"},_vm._l((_vm.tabs),function(tabName){return _c('ImageDialogTab',{key:tabName,attrs:{"name":tabName,"active-tab":_vm.activeTab},on:{"tabClicked":_vm.setActiveTab}})}),1),_c('div',{staticClass:"mg-image-dialog__tab-content-area"},[(_vm.activeTab === 'Upload')?_c('UploadTab',{on:{"onUploadFinish":_vm.onUploadFinish}}):_vm._e(),(_vm.activeTab === 'Stickers')?_c('StickersTab',{on:{"stickerSelected":_vm.addSticker}}):_vm._e(),(_vm.activeTab === 'Emojis')?_c('EmojisTab',{on:{"emojiSelected":_vm.addSticker}}):_vm._e(),(_vm.activeTab === 'Memes')?_c('MemesTab',{on:{"templateAdded":_vm.templateAdded}}):_vm._e(),(_vm.activeTab === 'Reddit')?_c('RedditTab',{on:{"templateAdded":_vm.templateAdded}}):_vm._e()],1)],1),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","size":"sm","centered":"","dialog-class":"mg-image-dialog","body-class":"mg-image-dialog__confirm-modal"},model:{value:(_vm.isConfirmationOpen),callback:function ($$v) {_vm.isConfirmationOpen=$$v},expression:"isConfirmationOpen"}},[_c('div',{staticClass:"mg-image-dialog__confirm-modal__buttons-container"},[_c('CloseModalButton',{attrs:{"click-handler":_vm.closeConfirmationModal}}),_c('ButtonGradientBackground',{attrs:{"text-content":"Replace main background","click-handler":_vm.replaceMainBackground,"additional-basic-styles":{
          width: '250px',
          height: '40px',
          background: '#27CAFE',
          fontSize: '14px',
        }}}),_c('ButtonGradientOutline',{attrs:{"text-content":"Add Image to Meme","click-handler":_vm.addImage,"gradient-outline-style":"thirdly","additional-basic-styles":{
          width: '255px',
          height: '48px',
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }