import stateMerge from 'vue-object-merge';
import { FRAME_SIDES } from '../../../common/constants';

export default {
  namespaced: true,

  state: {
    isDraggable: false,
    [FRAME_SIDES.TOP]: {
      size: 0,
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    },
    [FRAME_SIDES.BOTTOM]: {
      size: 0,
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    },
    [FRAME_SIDES.LEFT]: {
      size: 0,
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    },
    [FRAME_SIDES.RIGHT]: {
      size: 0,
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
    },
  },

  getters: {
    getIsDraggable: state => state.isDraggable,

    getFrameColor: state => state[FRAME_SIDES.TOP].color,

    getSideSize: state => side => state[side].size,

    getTopFrameSize: state => state[FRAME_SIDES.TOP].size,

    getFrames: state => state,
  },

  mutations: {
    setIsDraggable(state, payload) {
      state.isDraggable = payload;
    },
    resetSideSize(state) {
      Object.keys(FRAME_SIDES).forEach(side => {
        state[FRAME_SIDES[side]].size = 0;
      });
    },

    setFrameColor(state, color) {
      Object.keys(FRAME_SIDES).forEach(side => {
        state[FRAME_SIDES[side]].color = color;
      });
    },

    setSideSize(state, { side, size }) {
      state[side].size = size;
    },

    setFrameOptionsGroup(state, { options }) {
      stateMerge(state, options);
    },

    mergeFramesFromLayout(state, frames) {
      Object.keys(FRAME_SIDES).forEach(sideKey => {
        const side = FRAME_SIDES[sideKey];
        state[side] = {
          ...state[side],
          ...frames[side],
        };
      });
    },
  },

  actions: {
    setIsDraggable({ commit }, payload) {
      commit('setIsDraggable', payload);
    },
    resetSideSize({ commit }) {
      commit('resetSideSize');
    },
    setFrameColor({ commit }, color) {
      commit('setFrameColor', color);
    },
    setSideSize({ commit }, payload) {
      commit('setSideSize', payload);
    },

    setFrameOptionsGroup({ commit }, payload) {
      commit('setFrameOptionsGroup', payload);
    },

    mergeFramesFromLayout({ commit }, frames) {
      commit('mergeFramesFromLayout', frames);
    },
  },
};
