/* eslint-disable prefer-const */
<template>
  <div>
    <div class="mg-start-page__upload-drop-area">
      <div class="mg-start-page__upload-icon">
        <div class="mg-start-page__upload-icon--desktop">
          <StartPageUploadIconDesktop />
        </div>

        <div class="mg-start-page__upload-icon--mobile">
          <StartPageUploadIconMobile />
        </div>
      </div>

      <vue-dropzone
        id="startPageUploadArea"
        :options="dropzoneOptions"
        :includeStyling="false"
        @vdropzone-success="onUploadSuccess"
      ></vue-dropzone>
    </div>

    <div class="mg-start-page__button-area--desktop">
      <span class="mg-start-page__button-area--desktop-text">Drop image to upload or</span>

      <div class="mg-start-page__button-wrapper">
        <ButtonGradientOutline
          class="mg-start-page__button"
          text-content="Choose files..."
          gradient-outline-style="secondary"
          :additional-basic-styles="{
            fontSize: '12px',
          }"
        />
      </div>
    </div>
    <div class="mg-start-page__button-area--mobile">
      Upload Photo
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import vue2Dropzone from 'vue2-dropzone';
import ButtonGradientOutline from '../common/ButtonGradientOutline.vue';
import StartPageUploadIconDesktop from '../common/icons/StartPageUploadIconDesktop.vue';
import StartPageUploadIconMobile from '../common/icons/StartPageUploadIconMobile.vue';
import TemplatesSidebarVue from '../editor/sidebars/TemplatesSidebar.vue';

export default {
  name: 'StartPageUploadArea',

  components: {
    vueDropzone: vue2Dropzone,
    ButtonGradientOutline,
    StartPageUploadIconMobile,
    StartPageUploadIconDesktop,
  },

  mixins: [TemplatesSidebarVue],
  data() {
    return {
      dropzoneOptions: {
        url: '/',
        uploadMultiple: false,
        maxFiles: 1,
        createImageThumbnails: false,
        previewsContainer: false,
        accept: this.onUploadSuccess,
        clickable: [
          '.mg-start-page__upload-drop-area',
          '.mg-start-page__button-wrapper',
          '.mg-start-page__button-area--desktop-text',
        ],
      },
    };
  },
  computed: {
    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
  },

  methods: {
    onUploadSuccess(file) {
      const reader = new FileReader();
      reader.onload = async event => {
        const id = uuidv4();
        const imageData = event.target.result;
        const imageSizes = await this.getImageSizes(imageData);
        this.$store.dispatch('canvas/elements/addMainImage', { id, imageData });
        this.$store.dispatch('canvas/common/setInitialUploadedMainImageRatio', {
          width: imageSizes.width,
          height: imageSizes.height,
        });
        this.$store.dispatch('canvas/common/setAspectRatio', 'original');
        this.$router.push('/editor');
      };
      reader.readAsDataURL(file);
    },
  },
};
</script>
