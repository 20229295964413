import Vue from 'vue';
import _set from 'lodash/set';
import imagesApi from '../../api/imagesApi';
import memeApi from '../../api/memeApi';

export default {
  namespaced: true,

  state: {
    stickerPacks: {
      isPending: false,
      activeId: null,
      data: [],
      currentPage: null,
      lastPage: null,
    },
    stickers: {
      isPending: false,
      // id: { data: [] }
      currentPage: null,
      lastPage: null,
    },
    emojis: {
      isPending: false,
      data: [],
      currentPage: null,
      lastPage: null,
    },
    sidebarTemplates: {
      isPending: false,
      data: [],
      currentPage: null,
      lastPage: null,
    },
    templates: {
      isPending: false,
      data: [],
      currentPage: null,
      lastPage: null,
    },
    reddit: {
      isPending: false,
      data: [],
      currentPage: null,
      lastPage: null,
    },
    loadedTemplate: {
      isPending: false,
      data: [],
    },
  },

  getters: {
    isStickerPacksPending: state => state.stickerPacks.isPending,
    getStickerPacksData: state => state.stickerPacks.data,
    getStickerPacksCurrentPage: state => state.stickerPacks.currentPage,
    getStickerPacksLastPage: state => state.stickerPacks.lastPage,

    isActiveStickerPackPending: state => state.stickers.isPending,
    getActiveStickerPackId: state => state.stickerPacks.activeId,
    getActiveStickerPack: state => state.stickers[state.stickerPacks.activeId],
    getActiveStickerPackCurrentPage: state => state.stickers.currentPage,
    getActiveStickerPackLastPage: state => state.stickers.lastPage,

    isEmojisPending: state => state.emojis.isPending,
    getEmojisData: state => state.emojis.data,
    getEmojisCurrentPage: state => state.emojis.currentPage,
    getEmojisLastPage: state => state.emojis.lastPage,

    isRecordsPendingByType: state => type => state[type].isPending,
    getRecordsData: state => type => state[type].data,
    getRecordsCurrentPage: state => type => state[type].currentPage,
    getRecordsLastPage: state => type => state[type].lastPage,
    getLoadedTemplate: state => state.loadedTemplate.data,
  },

  mutations: {
    setIsPending(state, { path, value }) {
      _set(state, path, value);
    },

    setStickerPacks(state, response) {
      Vue.set(state.stickerPacks, 'currentPage', response.current_page);
      Vue.set(state.stickerPacks, 'lastPage', response.last_page);

      const data = state.stickerPacks.data.concat(response.data);
      Vue.set(state.stickerPacks, 'data', data);
    },

    resetStickerPacks(state) {
      Vue.set(state.stickerPacks, 'currentPage', null);
      Vue.set(state.stickerPacks, 'lastPage', null);
      Vue.set(state.stickerPacks, 'activeId', null);
      Vue.set(state.stickerPacks, 'data', []);
    },

    selectStickerPack(state, id) {
      Vue.set(state.stickerPacks, 'activeId', id);

      // set initial state for pack
      if (id !== null && !state.stickers[id]) {
        const activePack = state.stickerPacks.data.find(pack => pack.id === id);

        Vue.set(state.stickers, id, {});
        Vue.set(state.stickers, 'isPending', false);
        Vue.set(state.stickers[id], 'id', activePack.id);
        Vue.set(state.stickers[id], 'name', activePack.name);
        Vue.set(state.stickers[id], 'image_url', activePack.image_url);
        Vue.set(state.stickers[id], 'data', []);
      }
    },

    setStickerPack(state, { id, response }) {
      Vue.set(state.stickers, 'currentPage', response.current_page);
      Vue.set(state.stickers, 'lastPage', response.last_page);

      const data = state.stickers[id].data.concat(response.data);
      Vue.set(state.stickers[id], 'data', data);
    },

    resetActiveStickerPack(state) {
      Vue.set(state.stickers, 'currentPage', null);
      Vue.set(state.stickers, 'lastPage', null);

      if (state.stickers[state.stickerPacks.activeId]) {
        Vue.set(state.stickers[state.stickerPacks.activeId], 'data', []);
      }
    },

    setEmojis(state, response) {
      Vue.set(state.emojis, 'currentPage', response.current_page);
      Vue.set(state.emojis, 'lastPage', response.last_page);

      const data = state.emojis.data.concat(response.data);
      Vue.set(state.emojis, 'data', data);
    },

    resetEmojis(state) {
      Vue.set(state.emojis, 'currentPage', null);
      Vue.set(state.emojis, 'lastPage', null);
      Vue.set(state.emojis, 'data', []);
    },

    setDataByType(state, { type, response }) {
      Vue.set(state[type], 'currentPage', response.current_page);
      Vue.set(state[type], 'lastPage', response.last_page);

      const data = state[type].data.concat(response.data);
      Vue.set(state[type], 'data', data);
    },

    resetDataByType(state, type) {
      Vue.set(state[type], 'currentPage', null);
      Vue.set(state[type], 'lastPage', null);
      Vue.set(state[type], 'data', []);
    },

    setLoadedTemplate(state, data) {
      Vue.set(state.loadedTemplate, 'data', data);
    },
  },

  actions: {
    getStickerPacks({ commit }, page = 1) {
      commit('setIsPending', { path: 'stickerPacks.isPending', value: true });

      return imagesApi
        .getStickerPacks(page)
        .then(response => {
          commit('setStickerPacks', response.responseData);
        })
        .finally(() => {
          commit('setIsPending', { path: 'stickerPacks.isPending', value: false });
        });
    },

    resetStickerPacks({ commit }) {
      commit('resetStickerPacks');
    },

    selectStickerPack({ commit }, id) {
      commit('selectStickerPack', id);
    },

    getStickerPack({ commit }, { id, page }) {
      commit('setIsPending', { path: 'stickers.isPending', value: true });

      return imagesApi
        .getStickerPack(id, page)
        .then(response => {
          commit('setStickerPack', { id, response: response.responseData.data });
        })
        .finally(() => {
          commit('setIsPending', { path: 'stickers.isPending', value: false });
        });
    },

    resetActiveStickerPack({ commit }) {
      commit('resetActiveStickerPack');
    },

    getEmojis({ commit }, page = 1) {
      commit('setIsPending', { path: 'emojis.isPending', value: true });

      return imagesApi
        .getEmojis(page)
        .then(response => {
          commit('setEmojis', response.responseData);
        })
        .finally(() => {
          commit('setIsPending', { path: 'emojis.isPending', value: false });
        });
    },

    resetEmojis({ commit }) {
      commit('resetEmojis');
    },

    getRecordsByType({ commit }, { page, type, options = {} }) {
      commit('setIsPending', { path: `${type}.isPending`, value: true });

      return memeApi
        .getTemplates(page, type, options)
        .then(response => {
          commit('setDataByType', { type, response: response.responseData });
        })
        .finally(() => {
          commit('setIsPending', { path: `${type}.isPending`, value: false });
        });
    },

    resetRecordsByType({ commit }, type) {
      commit('resetDataByType', type);
    },

    getTemplateById({ commit }, id) {
      commit('setIsPending', { path: 'loadedTemplate.isPending', value: true });

      return memeApi
        .getTemplateById(id)
        .then(response => {
          commit('setLoadedTemplate', response.data);
        })
        .finally(() => {
          commit('setIsPending', { path: 'loadedTemplate.isPending', value: false });
        });
    },
  },
};
