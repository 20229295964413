<template>
  <div class="mg-brush-settings-sidebar-container">

    <div v-if="isBrushEnabled">
      <div class="mg-sidebar__settings-block">
        <div class="mg-sidebar-subheader">Brush Thickness</div>
        <CustomSlider
          :input-value="brushThickness"
          :min-value="1"
          :max-value="100"
          @valueChanged="changeBrushThickness"
        />
      </div>

      <div class="mg-sidebar__settings-block">
        <div class="mg-sidebar-subheader">Brush color</div>
        <CustomColorPicker
          color-picker-id="brushColor"
          :input-color="brushColor"
          :usePopover="false"
          @colorChanged="changeBrushColor"
        />
      </div>
    </div>

    <LayersControls
      v-if="!isBrushEnabled"
      :is-text-sidebar="false"
    />
  </div>
</template>

<script>
import CustomColorPicker from '../../common/CustomColorPicker.vue';
import CustomSlider from '../../common/CustomSlider.vue';
import LayersControls from './LayersControls.vue';

export default {
  name: 'BrushSettingsSidebar',

  components: {
    LayersControls,
    CustomColorPicker,
    CustomSlider,
  },

  computed: {
    isBrushEnabled () {
      return this.$store.getters['canvas/elements/isBrushEnabled'];
    },

    brushColor: {
      get () {
        return this.$store.getters['canvas/elements/getBrushColor'];
      },

      set (value) {
        const payload = {
          desktop: {
            actionName: 'canvas/elements/setBrushColor',
            value: value.rgba
          },
          mobile: {
            actionName: 'canvas/elements/setElementOptionsGroup',
            value: {
              path: 'brush.color',
              value: value.rgba
            },
          }
        };

        this.$emit('settingChanged', payload);
      }
    },

    brushThickness: {
      get () {
        return this.$store.getters['canvas/elements/getBrushThickness'];
      },

      set (value) {
        const payload = {
          desktop: {
            actionName: 'canvas/elements/setBrushThickness',
            value
          },
          mobile: {
            actionName: 'canvas/elements/setElementOptionsGroup',
            value: {
              path: 'brush.thickness',
              value
            },
          }
        };

        this.$emit('settingChanged', payload);
      }
    }
  },

  methods: {
    changeBrushColor (payload) {
      this.brushColor = payload;
    },

    changeBrushThickness (payload) {
      this.brushThickness = payload;
    }
  }
};
</script>
