<template>
    <b-modal
      v-model="isOpen"
      hide-footer
      hide-header
      hide-backdrop
      no-close-on-backdrop
      size="lg"
      static
      centered
      dialog-class="mg-mobile-sidebar-dialog"
      body-class="mg-mobile-sidebar-dialog__modal "
    >

      <SidebarContainer :close-mobile-sidebar-handler="closeMobileSidebar"/>

    </b-modal>
</template>

<script>
import SidebarContainer from './SidebarContainer.vue';

export default {
  name: 'MobileSidebarContainer',

  components: {
    SidebarContainer
  },

  computed: {
    isOpen: {
      get () {
        return this.$store.getters['ui/isOpenMobileSidebarModal'];
      },

      set () {
        this.$store.dispatch('ui/closeMobileSidebarModal');
      }
    },
  },

  methods: {
    closeMobileSidebar() {
      this.isOpen = false;
    }
  }
};
</script>
