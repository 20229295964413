<template>
  <svg width="80" height="54" viewBox="0 0 80 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.5 20.4666C62.2333 8.96665 52.1333 0.333313 40 0.333313C30.3667 0.333313 22 5.79998 17.8333 13.8C7.8 14.8666 0 23.3666 0 33.6666C0 44.7 8.96667 53.6666 20 53.6666H63.3333C72.5333 53.6666 80 46.2 80 37C80 28.2 73.1667 21.0666 64.5 20.4666ZM63.3333 47H20C12.6333 47 6.66667 41.0333 6.66667 33.6666C6.66667 26.8333 11.7667 21.1333 18.5333 20.4333L22.1 20.0666L23.7667 16.9C26.9333 10.8 33.1333 6.99998 40 6.99998C48.7333 6.99998 56.2667 13.2 57.9667 21.7666L58.9667 26.7666L64.0667 27.1333C69.2667 27.4666 73.3333 31.8333 73.3333 37C73.3333 42.5 68.8333 47 63.3333 47ZM26.6667 30.3333H35.1667V40.3333H44.8333V30.3333H53.3333L40 17L26.6667 30.3333Z"
          fill="url(#start-page-upload-icon-mobile-gradient)"
    />
    <defs>
      <linearGradient id="start-page-upload-icon-mobile-gradient" x1="0" y1="0.333313" x2="49.2308" y2="74.1795" gradientUnits="userSpaceOnUse">
        <stop stop-color="#9461FE"/>
        <stop offset="1" stop-color="#14E1DA"/>
      </linearGradient>
    </defs>
  </svg>

</template>

<script>
export default {
  name: 'StartPageUploadIconMobile'
};
</script>
