<template>
  <div>
    <svg v-if="contentType === 'left'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12H0V14H12V12ZM12 4H0V6H12V4ZM0 10H18V8H0V10ZM0 18H18V16H0V18ZM0 0V2H18V0H0Z" :fill="fillColor"/>
    </svg>

    <svg v-if="contentType === 'right'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 18H18V16H0V18ZM6 14H18V12H6V14ZM0 10H18V8H0V10ZM6 6H18V4H6V6ZM0 0V2H18V0H0Z" :fill="fillColor"/>
    </svg>

    <svg v-if="contentType === 'center'" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 12V14H14V12H4ZM0 18H18V16H0V18ZM0 10H18V8H0V10ZM4 4V6H14V4H4ZM0 0V2H18V0H0Z" :fill="fillColor"/>
    </svg>

    <svg v-if="contentType === 'none'" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" :fill="fillColor"/>
    </svg>

    <svg v-if="contentType === 'first_letter_caps'" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.64228 1.97561H6.01626V11.8374H3.57724V1.97561H0V0H9.64228V1.97561Z" :fill="fillColor"/>
      <path d="M13.6992 0.878049V3.04065H15.2032V4.76423H13.6992V9.15447C13.6992 9.47967 13.7615 9.71274 13.8862 9.85366C14.0108 9.99458 14.2493 10.065 14.6016 10.065C14.8618 10.065 15.0921 10.0461 15.2927 10.0081V11.7886C14.832 11.9295 14.3577 12 13.8699 12C12.2222 12 11.3821 11.168 11.3496 9.50406V4.76423H10.065V3.04065H11.3496V0.878049H13.6992Z" :fill="fillColor"/>
    </svg>

    <svg v-if="contentType === 'lower'" width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5943 0V2.13889H5.08187V3.84357H3.5943V8.18567C3.5943 8.50731 3.65595 8.73782 3.77924 8.87719C3.90253 9.01657 4.1384 9.08626 4.48684 9.08626C4.74415 9.08626 4.97198 9.0675 5.17032 9.02997V10.7909C4.71467 10.9303 4.24561 11 3.76316 11C2.13353 11 1.30263 10.1771 1.27047 8.53143V3.84357H0V2.13889H1.27047V0H3.5943Z" :fill="fillColor"/>
      <path d="M9.15863 0V2.13889H10.6462V3.84357H9.15863V8.18567C9.15863 8.50731 9.22027 8.73782 9.34357 8.87719C9.46686 9.01657 9.70273 9.08626 10.0512 9.08626C10.3085 9.08626 10.5363 9.0675 10.7346 9.02997V10.7909C10.279 10.9303 9.80994 11 9.32749 11C7.69786 11 6.86696 10.1771 6.83479 8.53143V3.84357H5.56433V2.13889H6.83479V0H9.15863Z" :fill="fillColor"/>
    </svg>

    <svg v-if="contentType === 'upper'" width="21" height="12" viewBox="0 0 21 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.77473 2.00275H6.0989V12H3.62637V2.00275H0V0H9.77473V2.00275Z" :fill="fillColor"/>
      <path d="M20.3571 2.00275H16.6813V12H14.2088V2.00275H10.5824V0H20.3571V2.00275Z" :fill="fillColor"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'TextSettingsSidebarButtonContent',

  props: {
    contentType: String,
    isActive: Boolean
  },

  computed: {
    fillColor() {
      return this.isActive ? '#27CAFE' : '#4F4F4F';
    }
  },
};
</script>
