/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { fabric } from 'fabric';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import _toUpper from 'lodash/toUpper';
import _findLast from 'lodash/findLast';
import { rgba } from '../../../../common/colors';
import { ELEMENT_TYPES, SIDEBARS } from '../../../../common/constants';

const TextElements = {
  computed: {
    isHistorying() {
      return this.$store.getters['canvas/history/getIsHistorying'];
    },
    historyRedo() {
      return this.$store.getters['canvas/history/getRedoArray'];
    },
    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement'];
    },
    currentDrawingAreaSizes() {
      return this.$store.getters['canvas/elements/getCurrentDrawingAreaSizes'];
    },
    currentLayout() {
      return this.$store.getters['canvas/layouts/getActiveLayout'];
    },
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },
    topFrameSize() {
      return this.$store.getters['canvas/frames/getTopFrameSize'];
    },
  },
  data() {
    return {
      text: null,
    };
  },
  methods: {
    getTextOptions(element) {
      let textContent = element.text;
      if (element.font.caps === 'first_letter_caps') {
        textContent = _startCase(_toLower(element.text));
      } else if (element.font.caps === 'lower') {
        textContent = _toLower(element.text);
      } else if (element.font.caps === 'upper') {
        textContent = _toUpper(element.text);
      }

      const charSpacing = (element.font.spacing.letter * 1000) / element.font.size;

      const fontColor = rgba(element.font.color);

      const outlineColor = rgba(element.font.outline.color);

      const options = {
        fill: fontColor,
        stroke: outlineColor,
        strokeWidth: element.font.outline.thickness,
        fontFamily: element.font.family,
        fontSize: element.font.size,
        textAlign: element.font.alignment,
        charSpacing,
        lineHeight: element.font.spacing.line,
      };
      return {
        textContent,
        options,
      };
    },

    renderTextElement(element) {
      return new Promise(resolve => {
        const { textContent, options } = this.getTextOptions(element);
        const positionDelta = 20;
        const topLeftOffset = 15;
        const centerTextByFrame =
          this.topFrameSize > 30 ? (this.topFrameSize - options.fontSize) / 2 : 0;

        const text = new fabric.Textbox(textContent, {
          id: element.id,
          ...options,
          elementType: element.type,
          noScaleCache: false,
          objectCaching: false,
        });

        // CONTROLS
        // text.setControlsVisibility({
        //   mt: false,
        //   mb: false,
        // });

        let transformOptions = {
          width: this.drawingArea.width - topLeftOffset * 2,
          left: this.drawingArea.left + topLeftOffset,
          top: this.drawingArea.top + centerTextByFrame,
          // left: (this.drawingArea.left * 2 + this.drawingArea.width) / 2 - 240,
        };
        if (!this.isDesktop) {
          transformOptions = {
            width: this.drawingArea.width - topLeftOffset * 2,
            left: this.drawingArea.left + topLeftOffset,
            top: this.drawingArea.top + centerTextByFrame,
          };
        }

        if (element.position.x !== null) {
          transformOptions = this.getTransformationsFromElement(element);
          text.set(transformOptions);
        } else {
          const lastTextObject = _findLast(
            this.canvas.getObjects(),
            obj => obj.elementType === ELEMENT_TYPES.TEXT,
          );
          if (lastTextObject) {
            transformOptions = {
              width: this.drawingArea.width - topLeftOffset * 2,
              left: lastTextObject.left + positionDelta,
              top: lastTextObject.top + positionDelta,
              scaleX: 1,
              scaleY: 1,
              angle: 0,
            };
          }
        }

        // if (element.position.x === 'center') {
        //   transformOptions = {
        //     width: element.size.width || this.drawingArea.width,
        //     height: element.size.height || text.height,
        //     angle: element.rotation || 0,
        //     scaleX: element.scaleX || 1,
        //     scaleY: element.scaleY || 1,
        //   };

        //   text.set({
        //     ...transformOptions,
        //     top: 0,
        //     left: 0,
        //   });
        //   const top =
        //     this.drawingArea.top +
        //     this.drawingArea.height / 2 -
        //     (text.height * transformOptions.scaleY) / 2 +
        //     positionDelta * index;
        //   const left =
        //     this.drawingArea.left +
        //     this.drawingArea.width / 2 -
        //     (text.width * transformOptions.scaleX) / 2 +
        //     positionDelta * index;
        //   text.set({ top, left });
        // } else if (element.position.x !== null) {
        //   transformOptions = this.getTransformationsFromElement(element);
        //   text.set(transformOptions);
        // } else {
        //   const lastTextObject = _findLast(
        //     this.canvas.getObjects(),
        //     obj => obj.elementType === ELEMENT_TYPES.TEXT,
        //   );
        //   if (lastTextObject) {
        //     transformOptions = {
        //       width: this.drawingArea.width,
        //       left: lastTextObject.left + positionDelta,
        //       top: lastTextObject.top + positionDelta,
        //       scaleX: 1,
        //       scaleY: 1,
        //       angle: 0,
        //     };
        //   }
        text.set(transformOptions);
        // }

        this.canvas.add(text);
        this.addClipPathToObject(text);
        this.addTextEvents(text);
        this.saveElementTransformations(text, element);
        resolve();
        if (!this.isHistorying) {
          this.$store.dispatch('canvas/history/saveHistory');
        }
      });
    },

    updateTextElement(element) {
      const text = this.findObjectById(element.id);
      if (!text) {
        this.renderTextElement(element);
        return false;
      }
      const { textContent, options } = this.getTextOptions(element);
      // check if textElement is editing on canvas
      if (text.hiddenTextarea) {
        if (textContent !== text.hiddenTextarea.value) {
          // if textContent was changed out of canvas
          text.hiddenTextarea.value = textContent;
        }
      }
      if (this.isHistorying) {
        const charSpacingHistory = (element.font.spacing.letter * 1000) / element.font.size;
        const fontColorHistory = rgba(element.font.color);
        const outlineColorHistory = rgba(element.font.outline.color);
        const historyOptions = {
          fill: fontColorHistory,
          stroke: outlineColorHistory,
          strokeWidth: element.font.outline.thickness,
          fontFamily: element.font.family,
          fontSize: element.font.size,
          textAlign: element.font.alignment,
          charSpacing: charSpacingHistory,
          lineHeight: element.font.spacing.line,
        };
        text.set({
          text: element.text,
          ...historyOptions,
        });
      } else {
        text.set({
          text: textContent,
          ...options,
        });
      }

      this.setObjectOption(text, 'left', element.position.x);
      this.setObjectOption(text, 'top', element.position.y);
      this.setObjectOption(text, 'angle', element.rotation);

      this.canvas.renderAll();
      return true;
    },

    updateTextElementHistory(element) {
      const text = this.findObjectById(element.id);

      if (!text) {
        this.renderTextElement(element);
        return false;
      }

      // check if textElement is editing on canvas
      if (text.hiddenTextarea) {
        if (element.text !== text.hiddenTextarea.value) {
          // if textContent was changed out of canvas
          text.hiddenTextarea.value = element.text;
        }
      }

      const charSpacingHistory = (element.font.spacing.letter * 1000) / element.font.size;
      const fontColorHistory = rgba(element.font.color);
      const outlineColorHistory = rgba(element.font.outline.color);
      const historyOptions = {
        fill: fontColorHistory,
        stroke: outlineColorHistory,
        strokeWidth: element.font.outline.thickness,
        fontFamily: element.font.family,
        fontSize: element.font.size,
        textAlign: element.font.alignment,
        charSpacing: charSpacingHistory,
        lineHeight: element.font.spacing.line,
      };

      text.set({
        text: element.text,
        ...historyOptions,
      });

      this.setObjectOption(text, 'left', element.position.x);
      this.setObjectOption(text, 'top', element.position.y);
      this.setObjectOption(text, 'angle', element.rotation);
      this.setObjectOption(text, 'scaleX', element.scaleX);
      this.setObjectOption(text, 'scaleY', element.scaleY);

      this.canvas.renderAll();
      return true;
    },

    addTextEvents(textElement) {
      this.canvas.on('object:scaling', event => {
        if (event.target && event.target.elementType === 'text') {
          this.$store.dispatch('canvas/elements/changeTextSize', {
            id: event.target.id,
            size: Number(event.target.fontSize),
          });
        }
      });

      this.canvas.on('object:modified', event => {
        if (event.target && event.target.elementType === 'text') {
          if (event.target.__corner === 'mb' || event.target.__corner === 'mt') {
            event.target.fontSize *= event.target.scaleY;
            event.target.scaleX = 1;
            event.target.scaleY = 1;
          } else if (
            // Did it to prevent scaling when moving the text(without corners)
            event.target.__corner === 'tl' ||
            event.target.__corner === 'tr' ||
            event.target.__corner === 'bl' ||
            event.target.__corner === 'br'
          ) {
            event.target.fontSize *= event.target.scaleX;
            event.target.scaleX = 1;
            event.target.scaleY = 1;
          }

          this.$store.dispatch('canvas/elements/changeTextSize', {
            id: event.target.id,
            size: Math.round(Number(event.target.fontSize)),
          });
        }
      });

      textElement.on('modified', event => {
        if (event && event.target) {
          this.saveElementTransformations(event.target, textElement);
        }
      });

      textElement.on('mouseup', () => {
        this.$store.dispatch('canvas/history/saveHistory');
      });

      textElement.on('changed', () => {
        this.$store.dispatch('canvas/elements/setElementOption', {
          elementId: textElement.id,
          path: 'text',
          value: textElement.text,
        });
      });

      textElement.on('editing:exited', () => {
        this.$store.dispatch('canvas/elements/setElementOption', {
          elementId: textElement.id,
          path: 'text',
          value: textElement.text,
        });
      });

      textElement.on('selected', () => {
        this.$store.dispatch('canvas/history/setIsDeselectedText', false);
        this.$store.dispatch('canvas/elements/selectElement', textElement.id);
        this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.TEXT);
      });

      textElement.on('mousedown', () => {
        if (this.$store.getters['ui/getActiveSidebar'] !== SIDEBARS.TEXT && !this.isBrushEnabled) {
          this.$store.dispatch('ui/setActiveSidebar', SIDEBARS.TEXT);
        }
      });

      textElement.on('deselected', () => {
        this.$store.dispatch('canvas/history/setIsDeselectedText', true);
        this.$store.dispatch('canvas/elements/selectElement', null);
        if (!this.isHistorying) {
          this.$store.dispatch('canvas/history/saveHistory');
        }
        if (this.isActiveSidebarResetRequired) {
          this.$store.dispatch(
            'ui/setActiveSidebar',
            this.$store.getters['ui/isDesktop'] ? SIDEBARS.LAYOUT : null,
          );
        }
      });
    },
  },
};

export default TextElements;
