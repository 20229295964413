var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mg-templates-sidebar-container"},[_c('CustomSearchInput',{ref:"searchInput",attrs:{"input-type":"search","placeholder":"Search Meme Templates","additional-input-styles":{
      width: '100%',
    }},on:{"inputValue":_vm.onSearch}}),_c('div',{ref:"listContainer",staticClass:"mg-templates-sidebar__items-container"},[_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mg-templates-list",class:_vm.flexList,style:({ height: this.listHeight ? ((this.listHeight) + "px") : '100%' }),attrs:{"infinite-scroll-disabled":"busy","infinite-scroll-distance":"20"}},[_vm._l((_vm.templatesData),function(template){return _c('div',{key:("template-" + _vm.type + "-" + (template.id)),staticClass:"mg-templates-list__item",on:{"click":function($event){return _vm.selectTemplate(template)}}},[_c('img',{staticClass:"mg-templates-list__item-img",attrs:{"src":template.example_image_url,"alt":"template"}})])}),(_vm.templatesData.length === 0 && !_vm.isTemplatesPending)?_c('div',{staticClass:"mg-templates-list__empty-message"},[_vm._v(" There are no templates. ")]):_vm._e(),(_vm.isTemplatesPending)?_c('div',{staticClass:"mg-custom-infinite-scroll__loader mg-custom-infinite-scroll__loader--templates"},[_c('b-spinner',{attrs:{"variant":"light"}})],1):_vm._e()],2)]),_c('b-modal',{attrs:{"hide-header":"","hide-footer":"","size":"sm","centered":"","dialog-class":"mg-image-dialog","body-class":"mg-image-dialog__confirm-modal"},model:{value:(_vm.isConfirmationOpen),callback:function ($$v) {_vm.isConfirmationOpen=$$v},expression:"isConfirmationOpen"}},[_c('div',{staticClass:"mg-image-dialog__confirm-modal__buttons-container"},[_c('CloseModalButton',{attrs:{"click-handler":_vm.closeConfirmationModal}}),_c('div',{staticClass:"mg-image-dialog__confirm-text"},[_c('p',[_vm._v(" Are you sure you'd like to use a Template? It will erase your changes and replace them. ")]),_c('p',[_vm._v("Proceed with Template / Cancel")])]),_c('ButtonGradientBackground',{attrs:{"text-content":"Apply","click-handler":_vm.applyTemplate,"additional-basic-styles":{
          width: '250px',
          height: '40px',
          background: '#27CAFE',
          fontSize: '14px',
        }}}),_c('ButtonGradientOutline',{attrs:{"text-content":"Cancel","click-handler":_vm.closeConfirmationModal,"gradient-outline-style":"thirdly","additional-basic-styles":{
          width: '255px',
          height: '48px',
        }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }