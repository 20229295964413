<template>
  <button type="button" class="mg-close-modal-button" @click="clickHandler">
    <CloseIcon/>
  </button>
</template>

<script>
import CloseIcon from './icons/CloseIcon.vue';

export default {
  name: 'CloseModalButton',

  components: {
    CloseIcon
  },

  props: {
    clickHandler: Function
  },
};
</script>
