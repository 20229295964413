<template>
  <div class="mg-image-tab__container">

    <div class="mg-image-dialog-loader" v-if="isStickerPacksPending && !stickerPacksCurrentPage">
      <b-spinner variant="light" />
    </div>

    <div class="mg-stickers-pack-list-container" v-if="!activeStickerPackId">

      <div class="mg-stickers-pack-list" ref="stickerPacksList">
        <div
          v-for="pack in stickerPacksData"
          :key="`pack-${pack.id}`"
          class="mg-stickers-pack-list__item"
          v-on:click="selectPack(pack.id)"
        >
          <img
            class="mg-stickers-pack-list__item-img"
            :src="pack.image_url"
            :alt="pack.name"
          >
          {{ pack.name }}

        </div>

        <CustomInfiniteScroll
          v-if="stickerPacksCurrentPage"
          :distance="1"
          no-more-text="No more sticker packs"
          @endScrolling="loadMoreStickerPacks"
        />

      </div>
    </div>

    <div class="mg-stickers-list-container" v-if="activeStickerPackId && !isStickerPacksPending">
      <div class="mg-stickers-list-header">
        <button type="button" v-on:click="backToPacks">
          <BackIcon/>
        </button>
        {{ activeStickerPack.name }}
      </div>

      <div class="mg-image-dialog-loader" v-if="isActiveStickerPackPending && !activeStickersPackCurrentPage">
        <b-spinner variant="light" />
      </div>

      <div class="mg-stickers-list" ref="stickersList">
        <div
          v-for="sticker in activeStickerPack.data"
          :key="`sticker-${activeStickerPack.id}-${sticker.id}`"
          class="mg-stickers-list__item"
          v-on:click="selectSticker(sticker.id)"
        >
          <img :src="sticker.image_url" class="mg-stickers-list__item-img">
        </div>

        <CustomInfiniteScroll
          v-if="activeStickersPackCurrentPage"
          :distance="1"
          no-more-text="No more stickers"
          @endScrolling="loadMoreStickers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BackIcon from '../../../../common/icons/BackIcon.vue';
import CustomInfiniteScroll from '../../../../common/CustomInfiniteScroll.vue';

export default {
  name: 'StickersTab',

  components: {
    BackIcon,
    CustomInfiniteScroll
  },

  computed: {
    isStickerPacksPending () {
      return this.$store.getters['images/isStickerPacksPending'];
    },

    stickerPacksData () {
      return this.$store.getters['images/getStickerPacksData'];
    },

    stickerPacksCurrentPage () {
      return this.$store.getters['images/getStickerPacksCurrentPage'];
    },

    stickerPacksLastPage () {
      return this.$store.getters['images/getStickerPacksLastPage'];
    },

    isActiveStickerPackPending () {
      return this.$store.getters['images/isActiveStickerPackPending'];
    },

    activeStickerPackId () {
      return this.$store.getters['images/getActiveStickerPackId'];
    },

    activeStickerPack () {
      return this.$store.getters['images/getActiveStickerPack'];
    },

    activeStickersPackCurrentPage () {
      return this.$store.getters['images/getActiveStickerPackCurrentPage'];
    },

    activeStickerPackLastPage () {
      return this.$store.getters['images/getActiveStickerPackLastPage'];
    },
  },

  mounted () {
    this.$store.dispatch('images/getStickerPacks', 1);
  },

  beforeDestroy () {
    this.$store.dispatch('images/resetActiveStickerPack');
    this.$store.dispatch('images/resetStickerPacks');
  },

  methods: {
    async loadMoreStickerPacks($state) {
      let currentScrollPosition = null;
      if (this.$refs.memesList) {
        currentScrollPosition = this.$refs.stickerPacksList.scrollTop;
      }

      if (this.stickerPacksCurrentPage >= this.stickerPacksLastPage) {
        $state.complete();
        return;
      }

      await this.$store.dispatch('images/getStickerPacks', this.stickerPacksCurrentPage + 1);

      if (this.$refs.memesList) {
        this.$refs.stickerPacksList.scrollTop = currentScrollPosition;
      }

      $state.loaded();
    },

    async loadMoreStickers($state) {
      let currentScrollPosition = null;
      if (this.$refs.stickersList) {
        currentScrollPosition = this.$refs.stickersList.scrollTop;
      }

      if (this.activeStickersPackCurrentPage >= this.activeStickerPackLastPage) {
        $state.complete();
        return;
      }

      const activeStickerPackId = this.$store.getters['images/getActiveStickerPackId'];
      await this.$store.dispatch(
        'images/getStickerPack',
        { id: activeStickerPackId, page: this.activeStickersPackCurrentPage + 1 }
      );

      if (this.$refs.stickersList) {
        this.$refs.stickersList.scrollTop = currentScrollPosition;
      }

      $state.loaded();
    },

    selectPack (id) {
      this.$store.dispatch('images/selectStickerPack', id);
      this.$store.dispatch('images/getStickerPack', { id, page: 1 });
    },

    backToPacks () {
      this.$store.dispatch('images/resetActiveStickerPack');
      this.$store.dispatch('images/selectStickerPack', null);
    },

    selectSticker(id) {
      const selectedSticker = this.activeStickerPack.data.find((sticker) => sticker.id === id);
      this.$emit('stickerSelected', selectedSticker);
    },
  }
};
</script>
