<template>
  <div class="mg-frame-sidebar-container">
    <div class="mg-sidebar__settings-block">
      <div class="mg-sidebar-subheader">Frame Color</div>
      <CustomColorPicker
        :color-picker-id="'frame-color'"
        :input-color="frameColor"
        :use-popover="false"
        @colorChanged="changeFrameColor"
      />
    </div>

    <div class="mg-sidebar__settings-block">
      <div class="mg-sidebar-subheader">Frame Size</div>
      <div v-for="(side, key) in FRAME_SIDES" :key="`frame-side-${key}`">
        <FrameSize :side="side" @sideSizeChanged="changeSideSize" />
      </div>
    </div>

    <div class="mg-sidebar__settings-block">
      <div class="mg-sidebar-subheader">Aspect Ratio</div>
      <div v-if="!isTemplateGenerationMode" class="mg-frame-sidebar__aspect-ratio-container">
        <FrameSidebarAspectRatioItem
          v-for="ratio in aspectRatioList"
          :key="`ratio-${ratio}`"
          :ratio="ratio"
          :active-ratio="activeAspectRatio"
          @itemClicked="changeAspectRatio"
        />
      </div>
      <div v-else style="color: #c04f4f;">
        original only
      </div>
    </div>
  </div>
</template>

<script>
import _startCase from 'lodash/startCase';
import { FRAME_SIDES } from '../../../common/constants';
import FrameSize from './FrameSize.vue';
import CustomColorPicker from '../../common/CustomColorPicker.vue';
import FrameSidebarAspectRatioItem from './FrameSidebarAspectRatioItem.vue';

export default {
  name: 'FrameSidebar',

  components: {
    CustomColorPicker,
    FrameSize,
    FrameSidebarAspectRatioItem,
  },

  props: {
    activeAspectRatio: String,
  },

  data() {
    return {
      FRAME_SIDES,
    };
  },

  computed: {
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    frameColor: {
      get() {
        return this.$store.getters['canvas/frames/getFrameColor'];
      },

      set({ value, side }) {
        const payload = {
          desktop: {
            actionName: 'canvas/frames/setFrameColor',
            value: value.rgba,
          },
          mobile: {
            actionName: 'canvas/frames/setFrameOptionsGroup',
            value: {
              path: `${side}.color`,
              value: value.rgba,
            },
          },
        };

        this.$emit('settingChanged', payload);
      },
    },

    aspectRatioList() {
      return this.$store.getters['canvas/common/getAspectRatioList'];
    },

    isTemplateGenerationMode() {
      return this.$store.getters['ui/isTemplateGenerationMode'];
    },
  },

  methods: {
    changeFrameColor(value) {
      if (this.isDesktop) {
        this.frameColor = { value, side: null };
      } else {
        Object.values(FRAME_SIDES).forEach(side => {
          this.frameColor = { value, side };
        });
      }
      this.$store.dispatch('canvas/history/saveHistory');
    },

    changeSideSize(value) {
      const payload = {
        desktop: {
          actionName: 'canvas/frames/setSideSize',
          value: {
            side: value.side,
            size: value.size,
          },
        },
        mobile: {
          actionName: 'canvas/frames/setFrameOptionsGroup',
          value: {
            path: `${value.side}.size`,
            value: value.size,
          },
        },
      };
      this.$emit('settingChanged', payload);
    },

    changeAspectRatio(value) {
      this.$store.dispatch('canvas/elements/setIsFirstMainImageRender', true);
      const payload = {
        desktop: {
          actionName: 'canvas/common/setAspectRatio',
          value,
        },
        mobile: {
          actionName: 'canvas/common/setCommonOptionsGroup',
          value: {
            path: 'aspectRatio.active',
            value,
          },
        },
      };
      // prevent resizing background
      setTimeout(() => {
        this.$store.dispatch('canvas/elements/setIsFirstMainImageRender', false);
      }, 1000);
      this.$emit('settingChanged', payload);
    },

    startCase(value) {
      return _startCase(value);
    },
  },
};
</script>
