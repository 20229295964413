<template>
  <div
    class="mg-sidebar-container"
    :class="{
      'mg-mobile-sidebar-container--active': !isDesktop && activeSidebar,
      'full-width': isDesktop && isFullWidthSidebar,
    }"
    :style="{ overflowY: activeSidebar === SIDEBARS.TEMPLATES ? 'hidden' : 'auto' }"
  >
    <div v-if="isTextFontModalClosed" class="mg-sidebar-content-container">
      <div class="mg-sidebar-header-container" ref="header">
        <span v-if="!isDesktop && activeSidebar" class="mg-sidebar-header__icon">
          <img
            :src="require(`../../../assets/svg-icons/${activeSidebar}-active-icon.svg`)"
            alt="Icon"
          />
        </span>
        <h1 class="mg-sidebar-header__title">{{ headerTitle }}</h1>
      </div>

      <CloseModalButton v-if="!isDesktop && activeSidebar" :click-handler="onCancelChanges" />

      <LayoutSidebar
        v-if="activeSidebar === SIDEBARS.LAYOUT"
        @settingChanged="changeSettingHandler"
        :activeLayout="activeLayout"
      />
      <FrameSidebar
        v-if="activeSidebar === SIDEBARS.FRAME"
        @settingChanged="changeSettingHandler"
        :activeAspectRatio="activeAspectRatio"
      />
      <WatermarkSidebar
        v-if="activeSidebar === SIDEBARS.WATERMARK"
        @settingChanged="changeSettingHandler"
      />
      <TemplatesSidebar
        v-if="activeSidebar === SIDEBARS.TEMPLATES"
        type="sidebarTemplates"
        @settingChanged="changeSettingHandler"
      />
      <SavedSidebar
        v-if="activeSidebar === SIDEBARS.SAVED"
        @settingChanged="changeSettingHandler"
      />
      <TextSettingsSidebar
        v-if="activeSidebar === SIDEBARS.TEXT"
        @settingChanged="changeSettingHandler"
        :activeAlignment="activeAlignment"
        :activeCaps="activeCaps"
        :activeFontFamily="activeFontFamily"
        :activeFontSize="activeFontSize"
      />
      <BrushSettingsSidebar
        v-if="activeSidebar === SIDEBARS.BRUSH"
        @settingChanged="changeSettingHandler"
      />
      <ImageSettingsSidebar
        v-if="activeSidebar === SIDEBARS.IMAGE"
        @settingChanged="changeSettingHandler"
      />
    </div>
    <div v-else class="mg-text-settings-sidebar-container">
      <div v-for="font in fonts" :key="font.name">
        <font-types :fontFamilyName="font.value">{{ font.text }}</font-types>
      </div>
    </div>

    <div
      class="mg-mobile-sidebar-container__buttons"
      v-if="!isDesktop && activeSidebar && activeSidebar !== SIDEBARS.TEMPLATES"
    >
      <ButtonGradientOutline
        text-content="Cancel"
        gradient-outline-style="primary-pink-bg-light"
        :additional-basic-styles="{
          width: '158px',
          height: '64px',
          fontSize: '22px',
        }"
        :click-handler="onCancelChanges"
      />

      <ButtonGradientBackground
        text-content="Apply"
        :additional-basic-styles="{
          width: '158px',
          height: '58px',
          background: '#27CAFE',
          fontSize: '22px',
        }"
        :click-handler="onApplyChanges"
      />
    </div>
  </div>
</template>

<script>
import _startCase from 'lodash/startCase';
import _set from 'lodash/set';
import stateMerge from 'vue-object-merge';
import { FONTS, SIDEBARS } from '../../../common/constants';
import LayoutSidebar from './LayoutSidebar.vue';
import FrameSidebar from './FrameSidebar.vue';
import WatermarkSidebar from './WatermarkSidebar.vue';
import TemplatesSidebar from './TemplatesSidebar.vue';
import TextSettingsSidebar from './TextSettingsSidebar.vue';
import BrushSettingsSidebar from './BrushSettingsSidebar.vue';
import ImageSettingsSidebar from './ImageSettingsSidebar.vue';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';
import ButtonGradientOutline from '../../common/ButtonGradientOutline.vue';
import SavedSidebar from './SavedSidebar.vue';
import CloseModalButton from '../../common/CloseModalButton.vue';
import FontTypes from './FontTypes.vue';

export default {
  name: 'SidebarContainer',

  components: {
    SavedSidebar,
    ImageSettingsSidebar,
    BrushSettingsSidebar,
    TextSettingsSidebar,
    TemplatesSidebar,
    WatermarkSidebar,
    FrameSidebar,
    LayoutSidebar,
    ButtonGradientBackground,
    ButtonGradientOutline,
    CloseModalButton,
    FontTypes,
  },

  props: {
    closeMobileSidebarHandler: {
      type: Function,
      required: false,
    },
  },

  data() {
    return {
      SIDEBARS,
      readyToDispatchActions: {},
      readyToDispatchActiveSettings: {},
      fonts: FONTS,
    };
  },

  computed: {
    isTextFontModalClosed() {
      return this.$store.getters['canvas/fonts/getIsTextFontModalClosed'];
    },
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    headerTitle() {
      const additionalText = {
        before: '',
        after: '',
      };

      if (this.activeSidebar === 'brush') {
        additionalText.after = ' Settings';
      } else if (this.activeSidebar === 'templates') {
        additionalText.before = 'Meme ';
      } else if (this.activeSidebar === 'saved') {
        additionalText.after = ' Templates';
      }

      return additionalText.before + _startCase(this.activeSidebar) + additionalText.after;
    },

    activeSidebar() {
      return this.$store.getters['ui/getActiveSidebar'];
    },

    isFullWidthSidebar() {
      return this.activeSidebar === 'saved';
    },

    activeLayout() {
      return this.readyToDispatchActiveSettings.activeLayout
        ? this.readyToDispatchActiveSettings.activeLayout
        : this.$store.getters['canvas/layouts/getActiveLayout'];
    },

    activeAspectRatio() {
      return this.readyToDispatchActiveSettings.activeAspectRatio
        ? this.readyToDispatchActiveSettings.activeAspectRatio
        : this.$store.getters['canvas/common/getAspectRatio'];
    },

    activeAlignment() {
      return this.readyToDispatchActiveSettings.activeAlignment
        ? this.readyToDispatchActiveSettings.activeAlignment
        : this.$store.getters['canvas/elements/getActiveElement']?.font.alignment;
    },

    activeCaps() {
      return this.readyToDispatchActiveSettings.activeCaps
        ? this.readyToDispatchActiveSettings.activeCaps
        : this.$store.getters['canvas/elements/getActiveElement']?.font.caps;
    },

    activeFontFamily() {
      return this.readyToDispatchActiveSettings.activeFontFamily
        ? this.readyToDispatchActiveSettings.activeFontFamily
        : this.$store.getters['canvas/elements/getActiveElement']?.font.family;
    },

    activeFontSize() {
      return this.readyToDispatchActiveSettings.activeFontSize
        ? this.readyToDispatchActiveSettings.activeFontSize
        : this.$store.getters['canvas/elements/getActiveElement']?.font.size;
    },
  },

  methods: {
    changeSettingHandler(payload) {
      const { mobile, desktop } = payload;

      if (this.isDesktop) {
        // in the desktop version, the settings are applied immediately after the change
        this.$store.dispatch(desktop.actionName, desktop.value);
      } else {
        // in the mobile version, the settings are applied after pressing the 'apply' button
        if (!this.readyToDispatchActions[mobile.actionName]) {
          this.readyToDispatchActions[mobile.actionName] = this.getReadyToDispatchActionTemplate();
        }

        _set(
          this.readyToDispatchActions[mobile.actionName],
          'elementId',
          mobile.value.elementId ? mobile.value.elementId : null,
        );
        const changedSettings = _set({}, mobile.value.path, mobile.value.value);
        stateMerge(this.readyToDispatchActions[mobile.actionName].payload, changedSettings);

        this.updateReadyToDispatchActiveSettings();
      }
    },

    getReadyToDispatchActionTemplate() {
      return {
        elementId: '',
        payload: {},
      };
    },

    updateReadyToDispatchActiveSettings() {
      this.$set(
        this.readyToDispatchActiveSettings,
        'activeLayout',
        this.readyToDispatchActions['canvas/layouts/setLayoutOptionsGroup']?.payload?.activeLayout,
      );
      this.$set(
        this.readyToDispatchActiveSettings,
        'activeAspectRatio',
        this.readyToDispatchActions['canvas/common/setCommonOptionsGroup']?.payload?.aspectRatio
          ?.active,
      );
      this.$set(
        this.readyToDispatchActiveSettings,
        'activeAlignment',
        this.readyToDispatchActions['canvas/elements/setElementOptionsGroup']?.payload?.font
          ?.alignment,
      );
      this.$set(
        this.readyToDispatchActiveSettings,
        'activeCaps',
        this.readyToDispatchActions['canvas/elements/setElementOptionsGroup']?.payload?.font?.caps,
      );
      this.$set(
        this.readyToDispatchActiveSettings,
        'activeFontFamily',
        this.readyToDispatchActions['canvas/elements/setElementOptionsGroup']?.payload?.font
          ?.family,
      );
      this.$set(
        this.readyToDispatchActiveSettings,
        'activeFontSize',
        this.readyToDispatchActions['canvas/elements/setElementOptionsGroup']?.payload?.font?.size,
      );
    },

    onCancelChanges() {
      this.closeMobileSidebarHandler();
    },

    onApplyChanges() {
      Object.keys(this.readyToDispatchActions).forEach(actionName => {
        const action = this.readyToDispatchActions[actionName];
        const payload = {
          elementId: action.elementId,
          options: action.payload,
        };
        this.$store.dispatch(actionName, payload);
      });

      this.closeMobileSidebarHandler();
    },
  },
};
</script>
