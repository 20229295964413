export default {
  namespaced: true,

  state: {
    deviceInfo: null,
    activeSidebar: null,
    loginModal: {
      isOpen: false,
      isSignIn: true,
    },
    mobileSidebarModal: {
      isOpen: false,
    },
    addImageModal: {
      isOpen: false,
    },
    shareModal: {
      isOpen: false,
    },
    cropModalOpen: false,
    isTemplateGenerationMode: false,
  },

  getters: {
    getCropModalOpen: state => state.cropModalOpen,
    getActiveSidebar: state => state.activeSidebar,
    isOpenLoginModal: state => state.loginModal.isOpen,
    isSignInModal: state => state.loginModal.isSignIn,
    isOpenMobileSidebarModal: state => state.mobileSidebarModal.isOpen,
    isOpenAddImageModal: state => state.addImageModal.isOpen,
    isOpenShareModal: state => state.shareModal.isOpen,
    isDesktop: state => state.deviceInfo.platform.type === 'desktop',
    isTemplateGenerationMode: state => state.isTemplateGenerationMode,
  },

  mutations: {
    setCropModalOpen(state, payload) {
      state.cropModalOpen = payload;
    },
    setDeviceInfo(state, deviceInfo) {
      state.deviceInfo = deviceInfo;
    },

    setActiveSidebar(state, sidebarName) {
      state.activeSidebar = sidebarName;
    },

    openLoginModal(state) {
      state.loginModal.isSignIn = true;
      state.loginModal.isOpen = true;
    },

    openRegisterModal(state) {
      state.loginModal.isSignIn = false;
      state.loginModal.isOpen = true;
    },

    closeLoginModal(state) {
      state.loginModal.isOpen = false;
    },

    openMobileSidebarModal(state) {
      state.mobileSidebarModal.isOpen = true;
    },

    closeMobileSidebarModal(state) {
      state.mobileSidebarModal.isOpen = false;
    },

    openImageModal(state) {
      state.addImageModal.isOpen = true;
    },

    closeImageModal(state) {
      state.addImageModal.isOpen = false;
    },

    openShareModal(state) {
      state.shareModal.isOpen = true;
    },

    closeShareModal(state) {
      state.shareModal.isOpen = false;
    },

    setIsTemplateGenerationMode(state, payload) {
      state.isTemplateGenerationMode = payload;
    },
  },

  actions: {
    setCropModalOpen({ commit }, payload) {
      commit('setCropModalOpen', payload);
    },
    setDeviceInfo({ commit }, deviceInfo) {
      commit('setDeviceInfo', deviceInfo);
    },

    setActiveSidebar({ commit }, sidebarName) {
      commit('setActiveSidebar', sidebarName);
    },

    openLoginModal({ commit }) {
      commit('openLoginModal');
    },

    openRegisterModal({ commit }) {
      commit('openRegisterModal');
    },

    closeLoginModal({ commit }) {
      commit('closeLoginModal');
    },

    openMobileSidebarModal({ commit }) {
      commit('openMobileSidebarModal');
    },

    closeMobileSidebarModal({ commit }) {
      commit('closeMobileSidebarModal');
    },

    openImageModal({ commit }) {
      commit('openImageModal');
    },

    closeImageModal({ commit }) {
      commit('closeImageModal');
    },

    openShareModal({ commit }) {
      commit('openShareModal');
    },

    closeShareModal({ commit }) {
      commit('closeShareModal');
    },

    setIsTemplateGenerationMode({ commit }, payload) {
      commit('setIsTemplateGenerationMode', payload);
    },
  },
};
