<template>
  <div class="mg-header__profile" v-if="user">
    <img :src="user.avatar" class="mg-header__avatar" />
  </div>
</template>

<script>
export default {
  name: 'ProfileLogo',

  computed: {
    user () {
      return this.$store.getters['auth/getUser'];
    }
  }
};
</script>
