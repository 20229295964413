<template>
  <div>
    <backdrop :clicked="closeModal" />
    <div class="json">
      <h1 class="json-text">JSON</h1>
      <div class="json-container">
        <ul id="v-for-object" class="table">
          <li class="table" v-for="(value, name, index) in data" :key="name + index">
            {{ name }}:{{ value }}
          </li>
          <li>elements: {</li>
          <li v-for="(value, name, index) in elements" :key="name + index">
            {{ value.id }}
            <ul>
              <li v-for="(value, name, index) in value" :key="name + index">
                <span v-if="value.length > 200" class="base64"
                  >{{ name }} :
                  {{ value.slice(0, 100) + '...' + '  Total length:' + value.length }}</span
                >
                <span v-else>{{ name }}: {{ value }}</span>
              </li>
            </ul>
          </li>
          }
        </ul>
      </div>

      <div class="button-container">
        <button-gradient-background
          text-content="Cancel"
          :click-handler="closeModal"
          gradient-outline-style="thirdly"
          :additional-basic-styles="{
            width: '255px',
            height: '48px',
          }"
        />
        <button-gradient-background
          :isDisabled="isSaved"
          :text-content="isSaved ? 'Saved' : 'Save JSON'"
          :click-handler="saveJson"
          gradient-outline-style="firstly"
          :additional-basic-styles="{
            width: '255px',
            height: '48px',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';
import Backdrop from './Backdrop.vue';

export default {
  name: 'image-data',
  components: { Backdrop, ButtonGradientBackground },
  data() {
    return {
      data: null,
      elements: null,
      isSaved: false,
    };
  },
  computed: {
    lastHistoryElement() {
      const historyElements = this.$store.getters['canvas/history/getHistoryArray'];
      const lastElement = { ...historyElements[historyElements.length - 1] };
      delete lastElement.ids;
      return lastElement;
    },
    lastHistoryElementWithoutElements() {
      const historyElements = this.$store.getters['canvas/history/getHistoryArray'];
      const lastElement = { ...historyElements[historyElements.length - 1] };
      // delete elements because it freezes an computer, because of url(base64 - too long string)
      delete lastElement.elements;
      delete lastElement.ids;
      delete lastElement.textElementsObjects;
      return lastElement;
    },
    getElements() {
      const historyElement = this.$store.getters['canvas/history/getHistoryArray'];
      const lastElements = { ...historyElement[historyElement.length - 1].elements };
      return lastElements;
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('canvas/history/setJsonModal', false);
      this.isSaved = false;
    },
    saveJson() {
      const copyText = JSON.stringify(this.lastHistoryElement);
      const blob = new Blob([copyText], { type: 'application/json' });
      const href = URL.createObjectURL(blob);
      const a = Object.assign(document.createElement('a'), {
        href,
        style: 'display: none',
        download: 'JSON-Data.json',
      });
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(href);
      a.remove();
      this.isSaved = true;
    },
  },
  mounted() {
    this.data = this.lastHistoryElementWithoutElements;
    this.elements = this.getElements;
  },
};
</script>

<style scoped>
.json {
  position: fixed;
  z-index: 500;
  background-color: #ffffff;
  width: 80%;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 10%;
  right: 10%;
  top: 10%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  height: 80%;
  border-radius: 10px;
}
.table {
  text-overflow: ellipsis;
}
.json-container {
  height: 85%;
  overflow: auto;
}
.json-text {
  text-align: center;
  margin: 0 auto;
}
.base64 {
  display: inline;
}
.button-container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
  position: absolute;
  bottom: 2%;
  vertical-align: bottom;
  width: 100%;
  margin-left: -10px;
}
</style>
