<template>
  <svg width="80" height="64" viewBox="0 0 80 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M72 8H40L32 0H8C3.56 0 0.04 3.56 0.04 8L0 56C0 60.44 3.56 64 8 64H72C76.44 64 80 60.44 80 56V16C80 11.56 76.44 8 72 8ZM72 56H8V8H28.68L36.68 16H72V56ZM40 40H48V48H56V40H64V32H56V24H48V32H40V40Z"
          fill="url(#start-page-upload-icon-desktop-gradient)"
    />
    <defs>
      <linearGradient id="start-page-upload-icon-desktop-gradient" x1="0" y1="0" x2="91.0774" y2="7.54598" gradientUnits="userSpaceOnUse">
        <stop offset="0.347529" stop-color="#28CBFF"/>
        <stop offset="0.785055" stop-color="#3AD192"/>
        <stop offset="1" stop-color="#129F64"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'StartPageUploadIconDesktop'
};
</script>
