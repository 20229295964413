<template>
  <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 2L0 16C0 17.1 0.9 18 2 18H10C11.1 18 12 17.1 12 16L12 2C12 0.9 11.1 0 10 0H2C0.9 0 0 0.9 0 2ZM10 2L10 16H2L2 2H10Z" fill="#BDBDBD"/>
  </svg>
</template>

<script>
export default {
  name: 'Icon9To16'
};
</script>
