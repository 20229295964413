<template>
  <div class="backdrop" @click="clicked"></div>
</template>

<script>
export default {
  name: 'Backdrop',
  props: {
    clicked: Function,
  },
};
</script>

<style>
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
