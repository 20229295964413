var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mg-start-page"},[_vm._m(0),_c('section',{staticClass:"mg-start-page__controls-section"},[_c('div',{staticClass:"mg-start-page__controls-section-left-block"},[_c('ButtonGradientBackground',{attrs:{"text-content":"Browse Meme Templates","additional-basic-styles":{
          background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
          width: '306px',
          height: '64px',
          fontSize: '16px',
        },"additional-hover-styles":{
          background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)',
        },"clickHandler":_vm.onBrowseMemeTemplatesClick}}),_c('ButtonGradientBackground',{attrs:{"text-content":"Start With Empty Canvas","additional-basic-styles":{
          background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
          width: '306px',
          height: '64px',
          fontSize: '16px',
        },"additional-hover-styles":{
          background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)',
        },"clickHandler":_vm.onStartWithEmptyClick}})],1),_vm._m(1),_c('div',{staticClass:"mg-start-page__controls-section-right-block"},[_c('UploadImages')],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"mg-start-page__logo-section"},[_c('div',{staticClass:"mg-start-page__logo-wrapper"},[_c('img',{attrs:{"src":require("../assets/svg-icons/start-page/memehead-logo-big.svg"),"alt":"Memehead logo"}}),_c('div',{staticClass:"mg-start-page__logo-text"},[_vm._v("Create New Meme")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mg-start-page__controls-section-center-block"},[_c('img',{attrs:{"src":require("../assets/svg-icons/start-page/buttons/or-button.svg"),"alt":"Or"}})])}]

export { render, staticRenderFns }