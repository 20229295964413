import Vue from 'vue';
import Router from 'vue-router';
import AppLayout from './pages/AppLayout.vue';
import StartPage from './pages/StartPage.vue';
import EditorPage from './pages/EditorPage.vue';
import PostMemeTools from './pages/PostMemeTools.vue';
import PostMemeTitle from './pages/PostMemeTitle.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: '/',

  routes: [
    {
      path: '/',
      name: 'AppLayout',
      component: Vue.component('AppLayout', AppLayout),
      redirect: '/start',
      children: [
        {
          path: '/start',
          name: 'StartPage',
          component: Vue.component('StartPage', StartPage),
        },
        {
          path: '/editor/:memeId?',
          name: 'EditorPage',
          component: Vue.component('EditorPage', EditorPage),
        },
        {
          path: '/post-meme-tools/:memeId',
          name: 'PostMemeTools',
          component: Vue.component('PostMemeTools', PostMemeTools),
        },
        {
          path: '/create-post/:memeId',
          name: 'PostMemeTitle',
          component: Vue.component('PostMemeTitle', PostMemeTitle),
        },
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});
