<template>
  <div class="mg-frame-sidebar__frame-size-container">
    <div class="mg-frame-sidebar__frame-size-item-title">{{ capitalize(side) }}</div>
    <div class="mg-frame-sidebar__frame-size-item">
      <CustomSlider
        :input-value="sideSize"
        :units-type="unitsType"
        :unit-variability="true"
        :side-name="side"
        @valueChanged="changeSideSize"
      />
    </div>
  </div>
</template>

<script>
import _capitalize from 'lodash/capitalize';
import { FRAME_SIDES } from '../../../common/constants';
import CustomSlider from '../../common/CustomSlider.vue';

export default {
  name: 'FrameSize',

  props: {
    side: String,
  },

  components: {
    CustomSlider,
  },

  data() {
    return {
      FRAME_SIDES,
    };
  },

  computed: {
    elementsCount() {
      return this.$store.getters['canvas/elements/getElementsCount'];
    },
    sideSize: {
      get() {
        const sideSize = this.$store.getters['canvas/frames/getSideSize'](this.side);

        return typeof sideSize === 'number' ? sideSize : parseInt(sideSize, 10);
      },

      set(value) {
        this.$emit('sideSizeChanged', {
          side: this.side,
          size: this.unitsType === '%' ? `${value}%` : value,
        });
      },
    },

    unitsType() {
      return typeof this.$store.getters['canvas/frames/getSideSize'](this.side) === 'number'
        ? 'px'
        : '%';
    },
  },

  mounted() {
    this.$el.addEventListener('mousedown', () => {
      this.$store.dispatch('canvas/frames/setIsDraggable', true);
    });
    this.$el.addEventListener('mouseup', () => {
      if (this.elementsCount > 0) {
        setTimeout(() => {
          this.$store.dispatch('canvas/frames/setIsDraggable', false);
          this.$store.dispatch('canvas/history/saveHistory');
        }, 200);
      }
    });
  },
  methods: {
    changeSideSize(payload) {
      this.sideSize = payload;
    },

    capitalize(value) {
      return _capitalize(value);
    },
  },
};
</script>
