<template>
  <header class="mg-header">
    <div class="mg-header__block-left">
      <a href="https://memehead.com/" class="mg-header__memehead-logo">
        <img
          src="../../../assets/svg-icons/layout/header/memehead-logo-small.svg"
          alt="Memehead logo"
        />
      </a>

      <div class="mg-header__breadcrumbs">
        <Breadcrumbs />
      </div>
    </div>

    <div class="mg-header__block-right">
      <ButtonGradientBackground
        v-if="isSaveBtnVisible"
        text-content="NEXT"
        icon-name="save-icon"
        icon-position="inline-end"
        :additional-basic-styles="{
          width: '90px',
          padding: '0 12px',
          height: '32px',
          fontSize: '14px',
        }"
        :click-handler="onFinishClick"
      />

      <div class="mg-header__save-spinner" v-if="isSaving">
        <b-spinner variant="light" />
      </div>

      <div v-if="isLoggedIn" class="mg-header__profile-container">
        <b-dropdown right variant="link" toggle-class="text-decoration-none" no-caret>
          <template #button-content>
            <ProfileLogo />
          </template>

          <b-dropdown-item>
            <div v-on:click="openMyProfile">My Profile</div>
          </b-dropdown-item>
          <b-dropdown-item>
            <div v-on:click="onLogoutClick">Log out</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div v-else class="mg-header__login-container">
        <div class="watermark-bottom-text">
          <span class="mg-saved-link-modal mg-saved-link-header" v-on:click="onLoginClick"
            >Login</span
          >
        </div>
        <!-- <ButtonGradientBackground
          text-content="LOG IN"
          :additional-basic-styles="{
            width: '90px',
            padding: '0 12px',
            height: '32px',
            fontSize: '14px',
          }"
          :click-handler="onLoginClick"
        /> -->
      </div>
    </div>
  </header>
</template>

<script>
import Breadcrumbs from './Breadcrumbs.vue';
import ProfileLogo from './ProfileLogo.vue';
import ButtonGradientBackground from '../../common/ButtonGradientBackground.vue';

export default {
  name: 'Header',

  components: {
    Breadcrumbs,
    ProfileLogo,
    ButtonGradientBackground,
  },

  computed: {
    isSaving() {
      return this.$store.getters['meme/getIsSaving'];
    },

    isSaveBtnVisible() {
      return this.$route.name === 'EditorPage' && !this.isSaving;
    },

    isLoggedIn() {
      return this.$store.getters['auth/getIsLoggedIn'];
    },

    isPending() {
      return this.$store.getters['auth/getIsPending'];
    },
    user() {
      return this.$store.getters['auth/getUser'];
    },
  },

  methods: {
    openMyProfile() {
      window.open(`${process.env.VUE_APP_API_URL}/u/${this.user.username}`, '_blank');
    },
    onFinishClick() {
      this.$events.emit('Global_onSave');
    },

    onLoginClick() {
      this.$store.dispatch('ui/openLoginModal');
    },

    onLogoutClick() {
      this.$store.dispatch('auth/logout');
      localStorage.removeItem('token');
    },
  },
};
</script>
