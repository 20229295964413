<template>
   <div class="mg-color-picker-container">
    <div v-if="usePopover" class="color-picker-reduced-size">
      <color-picker-chrome
        :id="colorPickerId"
        v-model="outputColor"
        :palette="colorPickerPalette"
        @input="onInput">
      </color-picker-chrome>

      <b-popover
        :target="colorPickerId"
        triggers="hover"
        placement="right"
        custom-class="color-picker-popover-class"
      >

        <color-picker-chrome
          v-model="outputColor"
          @input="onInput">
        </color-picker-chrome>

        <color-picker-compact
          v-if="showPalette"
          v-model="outputColor"
          :palette="colorPickerPalette"
          @input="onInput">
        </color-picker-compact>

      </b-popover>
    </div>

    <div v-else class="color-picker-full-size">
      <color-picker-chrome
        v-model="outputColor"
        @input="onInput">
      </color-picker-chrome>

      <color-picker-compact
        v-if="showPalette"
        v-model="outputColor"
        :palette="colorPickerPalette"
        @input="onInput">
      </color-picker-compact>
    </div>

  </div>
</template>

<script>
import { Chrome, Compact } from 'vue-color';

export default {
  name: 'CustomColorPicker',

  components: {
    'color-picker-chrome': Chrome,
    'color-picker-compact': Compact,
  },

  props: {
    colorPickerId: {
      type: String,
      required: true,
      default: ''
    },
    inputColor: {
      type: Object,
      required: true,
    },
    showPalette: {
      type: Boolean,
      required: false,
      default: true
    },
    usePopover: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      outputColor: this.inputColor,
      colorPickerPalette: [
        '#000000', '#FFFFFF', '#DA615C', '#E69D59', '#ECCA62', '#499359', '#55AB68', '#87CC9B',
        '#8F56D6', '#955FB3', '#4480E5', '#4F9BD6', '#76CAEE', '#333333', '#4F4F4F', '#828282',
        '#BBBBBB', '#D1F2DF', '#DCD7D3', '#E3C8CA', '#EBBCC4', '#625D78', '#A196B6', '#9AA4B4'
      ]
    };
  },

  watch: {
    inputColor(newValue) {
      this.outputColor = newValue;
    }
  },

  methods: {
    onInput(newValue) {
      this.$emit('colorChanged', newValue);
    }
  }
};
</script>
