export const calculateRatio = (ratioString) => {
  const [width, height] = ratioString.split(':');
  return ratioString === 'original' ? ratioString : width / height;
};

export const moveElementInArray = function (array, value, startIndex, delta) {
  const index = array.indexOf(value);
  const newIndex = index + delta;
  if (newIndex < startIndex || newIndex === array.length) return false;

  const indexes = [index, newIndex].sort((a, b) => a - b);
  array.splice(indexes[0], 2, array[indexes[1]], array[indexes[0]]);

  return true;
};

export default {
  calculateRatio,
  moveElementInArray
};
