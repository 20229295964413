<template>
  <div>
    <div class="mg-text-settings-sidebar-container">
      <div class="mg-sidebar__settings-block">
        <div class="mg-sidebar-subheader">Text</div>

        <textarea-autosize
          class="mg-text-settings-sidebar__input-item mg-text-settings-sidebar__input-item__text"
          :value="activeElement.text"
          v-on:change="onChange($event.target.value, 'text')"
          v-on:input="onInput"
        />

        <div class="mg-sidebar-subheader" style="{fontFamily: 'Anton-Regular'}">Font</div>

        <div class="mg-text-settings-sidebar__font-container">
          <span v-on:click="openTextFontModal">
            <VueSelect
              :value="fontFamily"
              label="text"
              :options="fonts"
              :clearable="false"
              :searchable="false"
              transition="fade"
              @input="onChange($event.value, 'font.family')"
              class="mg-text-settings-sidebar__input-item mg-text-settings-sidebar__input-item__select"
            >
            </VueSelect>
          </span>

          <VueSelect
            :value="activeFontSize"
            :options="fontSizes"
            :clearable="false"
            :searchable="true"
            :taggable="true"
            :createOption="validateUserFontSizeInput"
            transition="fade"
            @input="onChange($event, 'font.size')"
            class="mg-text-settings-sidebar__input-item mg-text-settings-sidebar__input-item__select"
          />
        </div>

        <div class="mg-sidebar-inline-subheader-container">
          <div class="mg-sidebar-subheader">Alignment</div>
          <button
            type="button"
            v-on:click="onChange('left', 'font.alignment')"
            :class="
              `mg-text-settings-sidebar__alignment-btn${
                activeAlignment === 'left' ? '--active' : ''
              }`
            "
          >
            <TextSettingsSidebarButtonContent
              content-type="left"
              :is-active="activeAlignment === 'left'"
            />
          </button>

          <button
            type="button"
            v-on:click="onChange('center', 'font.alignment')"
            :class="
              `mg-text-settings-sidebar__alignment-btn${
                activeAlignment === 'center' ? '--active' : ''
              }`
            "
          >
            <TextSettingsSidebarButtonContent
              content-type="center"
              :is-active="activeAlignment === 'center'"
            />
          </button>
          <button
            type="button"
            v-on:click="onChange('right', 'font.alignment')"
            :class="
              `mg-text-settings-sidebar__alignment-btn${
                activeAlignment === 'right' ? '--active' : ''
              }`
            "
          >
            <TextSettingsSidebarButtonContent
              content-type="right"
              :is-active="activeAlignment === 'right'"
            />
          </button>
        </div>

        <div class="mg-sidebar-inline-subheader-container">
          <div class="mg-sidebar-subheader">Caps</div>

          <button
            type="button"
            v-on:click="onChange('none', 'font.caps')"
            :class="`mg-text-settings-sidebar__caps-btn${activeCaps === 'none' ? '--active' : ''}`"
          >
            <TextSettingsSidebarButtonContent
              content-type="none"
              :is-active="activeCaps === 'none'"
            />
          </button>

          <button
            type="button"
            v-on:click="onChange('upper', 'font.caps')"
            :class="`mg-text-settings-sidebar__caps-btn${activeCaps === 'upper' ? '--active' : ''}`"
          >
            <TextSettingsSidebarButtonContent
              content-type="upper"
              :is-active="activeCaps === 'upper'"
            />
          </button>

          <button
            type="button"
            v-on:click="onChange('first_letter_caps', 'font.caps')"
            :class="
              `mg-text-settings-sidebar__caps-btn${
                activeCaps === 'first_letter_caps' ? '--active' : ''
              }`
            "
          >
            <TextSettingsSidebarButtonContent
              content-type="first_letter_caps"
              :is-active="activeCaps === 'first_letter_caps'"
            />
          </button>

          <button
            type="button"
            v-on:click="onChange('lower', 'font.caps')"
            :class="`mg-text-settings-sidebar__caps-btn${activeCaps === 'lower' ? '--active' : ''}`"
          >
            <TextSettingsSidebarButtonContent
              content-type="lower"
              :is-active="activeCaps === 'lower'"
            />
          </button>
        </div>

        <div class="mg-sidebar-subheader">Line Spacing</div>

        <CustomSlider
          :show-units="false"
          :input-value="activeElement.font.spacing.line"
          :interval="0.1"
          :min-value="0.1"
          :max-value="3"
          @valueChanged="onChangeLineHeight"
        />

        <div class="mg-sidebar-subheader">Letter Spacing</div>

        <CustomSlider
          :input-value="activeElement.font.spacing.letter"
          @valueChanged="onChangeLetterSpacing"
        />
      </div>

      <div class="mg-sidebar__settings-block">
        <div class="mg-sidebar-subheader">Text color</div>

        <div class="mg-text-settings-sidebar__input-item__color">
          <CustomColorPicker
            :color-picker-id="'font-color'"
            :input-color="activeElement.font.color"
            :use-popover="true"
            @colorChanged="onChangeFontColor"
          />
        </div>
      </div>

      <div class="mg-sidebar__settings-block">
        <div class="mg-sidebar-subheader">Outline Thickness</div>

        <CustomSlider
          :show-units="false"
          :input-value="activeElement.font.outline.thickness"
          :min-value="0"
          :max-value="10"
          @valueChanged="onChangeOutlineThickness"
        />
      </div>

      <div class="mg-sidebar__settings-block">
        <div class="mg-sidebar-subheader">Outline color</div>

        <div class="mg-text-settings-sidebar__input-item__color">
          <CustomColorPicker
            :color-picker-id="'outline-color'"
            :input-color="activeElement.font.outline.color"
            :use-popover="true"
            @colorChanged="onChangeOutlineColor"
          />
        </div>
      </div>

      <LayersControls :is-text-sidebar="true" />
    </div>
  </div>
</template>

<script>
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { FONT_SIZES, FONTS } from '../../../common/constants';
import CustomSlider from '../../common/CustomSlider.vue';
import CustomColorPicker from '../../common/CustomColorPicker.vue';
import TextSettingsSidebarButtonContent from './TextSettingsSidebarButtonContent.vue';
import LayersControls from './LayersControls.vue';

export default {
  name: 'TextSettingsSidebar',

  components: {
    LayersControls,
    TextSettingsSidebarButtonContent,
    CustomSlider,
    CustomColorPicker,
    VueSelect,
  },

  props: {
    activeAlignment: String,
    activeCaps: String,
    activeFontFamily: String,
    activeFontSize: Number,
  },

  data() {
    return {
      fonts: FONTS,
      fontSizes: FONT_SIZES,
    };
  },

  computed: {
    isDesktop() {
      return this.$store.getters['ui/isDesktop'];
    },

    activeElement() {
      return this.$store.getters['canvas/elements/getActiveElement'];
    },

    fontFamily() {
      return this.fonts.find(font => font.value === this.activeFontFamily);
    },
  },

  methods: {
    openTextFontModal() {
      this.$store.dispatch('canvas/fonts/setTextFontModal', false);
    },
    onInput(value) {
      if (this.isDesktop) {
        const payload = {
          desktop: {
            actionName: 'canvas/elements/setElementOption',
            value: {
              elementId: this.activeElement?.id,
              path: 'text',
              value,
            },
          },
        };
        this.$emit('settingChanged', payload);
      }
    },

    onChange(value, path) {
      const payload = {
        desktop: {
          actionName: 'canvas/elements/setElementOption',
          value: {
            elementId: this.activeElement?.id,
            path,
            value,
          },
        },
        mobile: {
          actionName: 'canvas/elements/setElementOptionsGroup',
          value: {
            elementId: this.activeElement?.id,
            path,
            value,
          },
        },
      };
      this.$emit('settingChanged', payload);
    },

    onChangeLetterSpacing(value) {
      this.onChange(value, 'font.spacing.letter');
    },

    onChangeLineHeight(value) {
      this.onChange(value, 'font.spacing.line');
    },

    onChangeOutlineThickness(value) {
      this.onChange(value, 'font.outline.thickness');
    },

    onChangeFontColor(value) {
      this.onChange(value.rgba, 'font.color');
    },

    onChangeOutlineColor(value) {
      this.onChange(value.rgba, 'font.outline.color');
    },

    validateUserFontSizeInput(value) {
      const convertedValue = Number(value);

      if (
        !Number.isNaN(convertedValue) &&
        convertedValue >= this.fontSizes[0] &&
        convertedValue <= this.fontSizes[this.fontSizes.length - 1]
      ) {
        return convertedValue;
      }
      return '';
    },
  },
};
</script>
