var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mg-sidebar__settings-block"},[_c('div',{staticClass:"mg-sidebar-subheader"},[_vm._v("Layer")]),_c('div',{staticClass:"mg-sidebar__layers-controls",class:{ 'mg-sidebar__layers-controls--text': _vm.isTextSidebar }},[_c('ButtonGradientBackground',{attrs:{"icon-name":"backward-icon","text-content":_vm.textContentBackward,"click-handler":_vm.moveBackward,"additional-basic-styles":{
        background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
        width: ("" + _vm.buttonWidth),
        height: '40px',
        fontSize: '14px',
        marginRight: '10px',
      },"additional-hover-styles":{
        background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)'
      },"additional-icon-styles":{
        marginRight: '7px'
      }}}),_c('ButtonGradientBackground',{attrs:{"icon-name":"forward-icon","text-content":_vm.textContentForward,"click-handler":_vm.moveForward,"additional-basic-styles":{
        background: 'linear-gradient(93.79deg, #28CBFF 37.36%, #3AD192 84.4%, #129F64 107.5%)',
        width: ("" + _vm.buttonWidth),
        height: '40px',
        fontSize: '14px',
      },"additional-hover-styles":{
        background: 'linear-gradient(93.79deg,#3AD192  37.36%, #28CBFF 84.4%, #129F64 107.5%)'
      },"additional-icon-styles":{
        marginRight: '7px'
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }