<template>
  <CustomButtonTemplate
    :text-content="textContent"
    :icon-name="iconName"
    :gradient-outline-style="gradientOutlineStyle"
    :basic-styles="{...basicStyles, ...additionalBasicStyles}"
    :hover-styles="{...hoverStyles, ...additionalHoverStyles}"
    :is-disabled="isDisabled"
    @buttonClicked="clickHandler"
  />
</template>

<script>
import CustomButtonTemplate from './CustomButtonTemplate.vue';

export default {
  name: 'ButtonGradientOutline',

  components: { CustomButtonTemplate },

  props: {
    textContent: String,
    clickHandler: {
      type: Function,
      required: false,
      default: () => null
    },
    gradientOutlineStyle: String,
    iconName: String,
    additionalBasicStyles: Object,
    additionalHoverStyles: Object,
    isDisabled: Boolean,
  },

  data () {
    return {
      basicStyles: {
        width: '100px',
        height: '42px',
        fontSize: '14px',
        fontWeight: '500',
      },
      hoverStyles: {}
    };
  }
};
</script>
